<template lang="pug">
LegendTable(
  :data="data"
)
</template>

<script lang="ts">
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MonitoringIndexColorType } from '@/constants/types/monitoring/MonitoringIndexColorType';
import { rgbaToHex } from '@/lib/convertors/rgbToHex';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import LegendTable from '@/modules/map/container/MapLegend/LegendTable.vue';
import {
  computed, defineComponent, PropType, reactive,
} from 'vue';
import { useMonitoring } from '@/composables/useMonitoring';

export default defineComponent({
  name: 'MonitoringLegend',
  components: { LegendTable },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    layer: {
      type: Object as PropType<MapLayerIndexModel>,
      required: true,
    },
  },
  setup(props) {
    const { indexesKinds } = useMonitoring();
    const layer = reactive(props.layer);

    const calculateContrasData = (data: number, min: number, max: number) => {
      const cttMin = (layer.data as FieldIndexMonitoringModel).statistics.ctt_min;
      const cttMax = (layer.data as FieldIndexMonitoringModel).statistics?.ctt_max;

      return (data - min) * ((cttMax - cttMin) / (max - min)) + cttMin;
    };

    const colors = computed(() => {
      if (!layer.showLegend.value) return [];
      if (layer.contrast) {
        const kind = indexesKinds.value.getKind(layer.data.kind);

        const arr = (kind.contrastColors[(layer.data as FieldIndexMonitoringModel).colormap || 0]).map((a) => ({
          value: calculateContrasData(a.value, kind.min, kind.max),
          rgb: a.rgb,
          label: a.label,
        }));
        return arr;
      }
      return indexesKinds.value.getKind(layer.data.kind).colors[(layer.data as FieldIndexMonitoringModel).colormap || 0] || [];
    });

    const labelCalculate = (value: MonitoringIndexColorType, index: number) => {
      if (index === 0) {
        return `> ${value.value.toFixed(2)}`;
      }
      if (index === colors.value.length - 1) {
        return `< ${colors.value[index - 1].value.toFixed(2)}`;
      }
      return `${value.value.toFixed(2)} &mdash; ${colors.value[index - 1].value.toFixed(2)}`;
    };

    const data = computed(() => colors.value.map((v, i) => ({
      color: rgbaToHex(v.rgb),
      label: labelCalculate(v, i),
    })));

    return {
      data,
      colors,
    };
  },
});
</script>
