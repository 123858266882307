import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "NavigationSubmenu-names" }
const _hoisted_2 = ["onClick", "onMouseenter"]
const _hoisted_3 = { class: "NavigationSubmenu-itemName" }
const _hoisted_4 = { class: "NavigationSubmenu-items" }
const _hoisted_5 = ["onClick", "onMouseenter"]
const _hoisted_6 = ["src"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["NavigationSubmenu", { hovered: (_ctx.submenuHovered && _ctx.menu.length > 0) || _ctx.submenuActive }]),
    onMouseenter: _cache[2] || (_cache[2] = $event => (_ctx.submenuHovered = true)),
    onMouseleave: _cache[3] || (_cache[3] = $event => (_ctx.submenuHovered = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["NavigationSubmenu-name", { hover: _ctx.hovered === item.name, active: _ctx.isActiveBlock(item.block) }]),
          onClick: $event => (_ctx.open(item)),
          onMouseenter: $event => (_ctx.over(item.name)),
          onMouseleave: _cache[0] || (_cache[0] = (...args) => (_ctx.mouseOut && _ctx.mouseOut(...args)))
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(`${item.name}`, item.name, { ns: 'navigation' })), 1 /* TEXT */)
        ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_2)), [
          [_vShow, _ctx.menuItemPermission(item)]
        ])
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["NavigationSubmenu-item", { hover: _ctx.hovered === item.name, active: _ctx.isActiveBlock(item.block) }]),
          onClick: $event => (_ctx.open(item)),
          onMouseenter: $event => (_ctx.over(item.name)),
          onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.mouseOut && _ctx.mouseOut(...args)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["NavigationSubmenu-itemIcon", { hover: _ctx.hovered === item.name, active: _ctx.isActiveBlock(item.block) }])
          }, [
            (item?.icon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: `/images/icons/submenu/${item.icon}.svg`
                }, null, 8 /* PROPS */, _hoisted_6))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)
        ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_5)), [
          [_vShow, _ctx.menuItemPermission(item)]
        ])
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ], 34 /* CLASS, NEED_HYDRATION */))
}