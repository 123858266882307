<template lang="pug" src="./BaseMapEditForm.pug"/>
<style lang="scss" src="./BaseMapEditForm.scss"/>

<script lang="ts">
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { RasterValue } from '@/assets/data/RasterValue';
import SkeletonLoader from '@/components/features/SkeletonLoader/SkeletonLoader.vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import ProgressCard from '@/components/ui/ProgressCard/ProgressCard.vue';
import { useBaseMapEditor } from '@/composables/baseMap/useBaseMapEditor';
import { useCreateBaseMap } from '@/composables/useCreateBaseMap';
import { useForceUpdate } from '@/composables/useForceUpdate';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUser } from '@/composables/useUser';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import EventBus from '@/services/eventBus/EventBus';
import { formatRuDate } from '@/utils/formatRuDate';
import {
  CaretRight, Lock, Minus, Plus, Unlock,
} from '@element-plus/icons-vue';
import { mdiLock, mdiLockOpenVariant } from '@mdi/js';
import { ElMessage, ElNotification } from 'element-plus';
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useUnload } from '@/composables/useUnload';
import worker from '@/workers/default';
import { SliderMarks0to100 } from '@/assets/settings/SliderMarks0to100';

export default defineComponent({
  name: 'BaseMapEditForm',
  components: {
    SkeletonLoader,
    UiDialog,
    UiIcon,
    ProgressCard,
    RightPanel,
    Plus,
    Minus,
    Lock,
    Unlock,
    CaretRight,
  },

  setup() {
    const { user } = useUser();
    const {
      candidates,
      activeCandidate,
      generalizationSize,
      step,
      isAutoGeneralization,
      settingsCopy,
      opacity,
      previewLayer,
      editorLayer,
    } = useCreateBaseMap();

    const {
      activeField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const zonesData = ref();

    const { focused } = useBaseMapEditor();

    const { updateKey, forceUpdate } = useForceUpdate();

    const getZoneByIndex = (value: number) => activeCandidate.value?.taskImage?.getZoneByValue(value);

    const getZoneColor = (value: number | undefined) => (value ? BaseMapColors[value] : BaseMapColors[0]);
    const getGroupZoneColor = (value: number | undefined) => (value ? BaseMapColors[getZoneByIndex(value)?.indexColor || 0] : BaseMapColors[0]);

    // eslint-disable-next-line camelcase
    const form = ref<{value: number, zone: string}[]>([]);

    const disableForm = (index: number) => {
      let check = true;
      (activeCandidate.value?.taskImage?.zones || []).forEach((z) => {
        if (index === z.value) {
          check = false;
        }
      });
      return check;
    };

    /**
     * Является ли индекс последним в зоне
     * @param index
     */
    const isZoneLast = (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return zone?.value === index;
    };

    /**
     * Является ли индекс первым в зоне
     * @param index
     */
    const isZoneFirst = (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return zone?.min === index;
    };

    /**
     * Является ли зона из одного индекса
     * @param index
     */
    const isZoneAlone = (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return zone?.min === zone?.value;
    };

    const isLocked = computed(() => (index: number): boolean => {
      const zone = getZoneByIndex(index);
      return !!zone && zone.value !== index;
    });

    const hoverElement = ref({ position: '', index: 0 });

    /**
     * Обработка события наведения мыши на строку в форме редактирования зон.
     * @param v
     */
    const onMouseEnterElement = (v: number) => {
      (activeCandidate.value?.taskImage?.zones || []).forEach((z, index) => {
        if (v <= z.value && v >= z.min) {
          if (v === z.min && v === z.value) {
            hoverElement.value = { position: 'solo', index };
          } else if (v === z.min) {
            hoverElement.value = { position: 'min', index };
          } else if (v === z.value) {
            hoverElement.value = { position: 'max', index };
          } else {
            hoverElement.value = { position: 'ok', index };
          }
        }
      });
    };

    const computedZoneProc = computed(() => (index: number) => getZoneByIndex(index)?.proc || 0);

    /**
     * Проверяет на какой цвет активен и делает выделение
     * @param color
     */
    const isColorActive = (index: number): boolean => (activeCandidate.value?.taskImage?.zones || []).some((zone) => zone.indexColor === index);

    /**
     * Установить активный цвет зоны.
     * @param color - hex
     * @param index
     */
    const setZoneColor = (index: number): void => {
      if (step.value !== 'zones') return;
      if (!activeCandidate.value?.isEdited) {
        const zone = getZoneByIndex(index);
        if (zone) zone.indexColor = index;
          activeCandidate.value?.taskImage?.computeImageDataZoned();
      } else {
        ElNotification({
          message: 'Настройка зон недоступна после редактирования.',
          type: 'error',
          position: 'bottom-right',
        });
      }
    };

    const isEvenRow = (index: number) => {
      let check = false;
      (activeCandidate.value?.taskImage?.zones || []).forEach((z, i) => {
        if (index <= z.value && index >= z.min && i % 2 === 0) {
          check = true;
        }
      });
      return !check;
    };

    const syncZoneProc = (zoneValue: number) => {
      const asfZone = activeCandidate.value?.taskImage?.sourceZones.find((v) => v.value === zoneValue);
      const zone = getZoneByIndex(zoneValue);
      if (zone && asfZone) {
        activeCandidate.value?.taskImage?.sourceZones.forEach((v) => {
          if (v.value >= zone.min && v.value < zone.value) {
            v.proc = asfZone.proc;
            v.proc_seed = asfZone.proc_seed;
          }
        });
        zone.proc = asfZone.proc;
        zone.procSeed = asfZone.proc_seed;
      }
      forceUpdate();
    };

    const regroup = (zone: number) => {
      if (activeCandidate.value?.taskImage?.zones) {
        if (isLocked.value(zone)) {
          const z = getZoneByIndex(zone);
          if (z) {
            const nz = [
              {
                min: z.min,
                value: zone,
                proc: z.proc,
                procSeed: z.procSeed,
                indexColor: z.indexColor <= zone ? z.indexColor : zone,
              },
              {
                min: zone + 1,
                value: z.value,
                proc: z.proc,
                procSeed: z.procSeed,
                indexColor: z.indexColor > zone ? z.indexColor : z.value,
              },
            ];
            activeCandidate.value.taskImage.zones = [
              ...activeCandidate.value.taskImage.zones.filter((v) => v.value !== z.value),
              ...nz,
            ].sort((a, b) => a.value - b.value);
          }
        } else {
          const z1 = getZoneByIndex(zone);
          const z2 = getZoneByIndex(zone + 1);
          if (z1 && z2) {
            const nz = [
              {
                min: z1.min,
                value: z2.value,
                proc: z2.proc,
                procSeed: z2.procSeed,
                indexColor: z2.indexColor,
              },
            ];
            activeCandidate.value.taskImage.zones = [
              ...activeCandidate.value.taskImage.zones.filter((v) => ![z1.value, z2.value].includes(v.value)),
              ...nz,
            ].sort((a, b) => a.value - b.value);
            syncZoneProc(z2.value);
          }
        }
      }
    };

    const changeCandidate = () => {
      activeCandidate.value?.reset();
      activeCandidate.value = undefined;
      step.value = 'selector';
    };

    const copySettings = () => {
      if (!focused.value && activeCandidate.value?.taskImage?.zones && activeCandidate.value?.taskImage?.sourceZones) {
        settingsCopy.value.zones = JSON.stringify(activeCandidate.value.taskImage.zones);
        settingsCopy.value.sourceZones = JSON.stringify(activeCandidate.value.taskImage.sourceZones);
        ElMessage({
          message: 'Настройки формы успешно скопированы в буффер.',
          type: 'success',
        });
      }
    };

    const isPasteSettingsAvailable = computed(() => !activeCandidate.value?.isEdited
      && activeCandidate.value?.taskImage
      && settingsCopy.value.zones
      && settingsCopy.value.sourceZones);

    const pasteSettings = () => {
      if (!focused.value && isPasteSettingsAvailable.value && activeCandidate.value?.taskImage) {
        activeCandidate.value.taskImage.pasteZones(settingsCopy.value.zones);
        activeCandidate.value.taskImage.pasteSourceZones(settingsCopy.value.sourceZones);

        ElMessage({
          message: 'Настройки формы успешно обновлены.',
          type: 'info',
        });
      }
    };

    const generalization = () => {
      if (step.value === 'zones') {
        activeCandidate.value?.taskImage?.generalization(true, generalizationSize.value);
      } else {
        activeCandidate.value?.taskImage?.generalization(false, generalizationSize.value);
        EventBus.$emit('generalization');
      }
    };

    watch(() => activeCandidate.value?.taskImage.zones, () => {
      if (step.value === 'zones' && isAutoGeneralization.value && generalizationSize.value > 0) {
        activeCandidate.value?.taskImage?.generalization(true, generalizationSize.value);
      }
    }, { deep: true });

    watch(isAutoGeneralization, (v) => {
      if (v) {
        activeCandidate.value?.taskImage?.generalization(step.value === 'zones', generalizationSize.value);
      }
    });

    const calculateZonesStats = () => {
      worker.calculateZonesStats({
        imageDataZoned: Array.from(activeCandidate.value.taskImage.imageDataZoned),
        zones: JSON.parse(JSON.stringify(activeCandidate.value.taskImage.zones)),
        area: (activeField.value?.sq || 0),
      }).then((reply: Record<number, { indexColor: number, pixels: number, progress: number, area: number }>) => {
        zonesData.value = reply;
      });
    };

    onMounted(() => {
      activeCandidate.value?.taskImage?.generalization(step.value === 'zones', generalizationSize.value);
      calculateZonesStats();
    });

    watch(() => activeCandidate.value?.taskImage.imageDataZoned, calculateZonesStats);

    const generalizationSizeChange = () => {
      if (isAutoGeneralization.value && step.value === 'zones') {
        generalization();
      }
    };
    const onFocus = (zone: number) => {
      focused.value = getZoneByIndex(zone)?.indexColor || 0;
    };

    const onBlur = (zone: number) => {
      focused.value = 0;
      syncZoneProc(zone);
    };

    useUnload(() => {
      changeCandidate();
    });

    return {
      RasterValue,
      BaseMapColors,
      user,
      activeCandidate,
      changeCandidate,
      isZoneFirst,
      isZoneLast,
      isZoneAlone,
      onMouseEnterElement,
      hoverElement,
      isColorActive,
      setZoneColor,
      isEvenRow,
      form,
      disableForm,
      getZoneColor,
      getGroupZoneColor,
      candidates,
      syncZoneProc,
      zonesData,
      formatRuDate,
      isLocked,
      mdiLock,
      mdiLockOpenVariant,
      regroup,
      updateKey,
      computedZoneProc,
      copySettings,
      pasteSettings,
      focused,
      isPasteSettingsAvailable,
      generalizationSize,
      generalization,
      isAutoGeneralization,
      generalizationSizeChange,
      step,
      onFocus,
      onBlur,
      SliderMarks0to100,
      opacity,
    };
  },
});
</script>
