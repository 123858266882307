import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "FactMapAnalyzeContent" }
const _hoisted_2 = {
  key: 0,
  class: "ph-15 pv-15"
}
const _hoisted_3 = {
  key: 1,
  class: "ph-10 pv-15"
}
const _hoisted_4 = {
  key: 2,
  class: "p-15 ta-center"
}
const _hoisted_5 = { class: "pt-15" }
const _hoisted_6 = { style: {"color":"#999"} }
const _hoisted_7 = { class: "ph-10" }
const _hoisted_8 = { class: "form-header" }
const _hoisted_9 = { class: "ph-10" }
const _hoisted_10 = { class: "mt-15 ph-10" }
const _hoisted_11 = { class: "form-header" }
const _hoisted_12 = { class: "mt-15 ph-10" }
const _hoisted_13 = { class: "form-header" }
const _hoisted_14 = { class: "ta-center" }
const _hoisted_15 = { class: "fw flex-row flex-row-justify-end" }

export function render(_ctx, _cache) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")
  const _component_NoActiveField = _resolveComponent("NoActiveField")
  const _component_TaskMapCard = _resolveComponent("TaskMapCard")
  const _component_NoData = _resolveComponent("NoData")
  const _component_FactMapMaterials = _resolveComponent("FactMapMaterials")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_apexchart = _resolveComponent("apexchart")
  const _component_FactMapStopsWidget = _resolveComponent("FactMapStopsWidget")
  const _component_el_button = _resolveComponent("el-button")
  const _component_Content = _resolveComponent("Content")
  const _component_FactMapStatsWidget = _resolveComponent("FactMapStatsWidget")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Content, {
      title: _ctx.$t(_ctx.route.name, { ns: 'route' })
    }, _createSlots({
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_SkeletonLoader, {
            active: !!_ctx.activeField && _ctx.loading
          }, null, 8 /* PROPS */, ["active"]),
          (!_ctx.activeField)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_NoActiveField)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.activeTaskMapLayer)
                  ? (_openBlock(), _createBlock(_component_TaskMapCard, {
                      key: 0,
                      "task-map": _ctx.activeTaskMapLayer.data,
                      actions: ['opacity', 'select-map'],
                      render: ""
                    }, null, 8 /* PROPS */, ["task-map"]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.activeField.factTaskMaps, (taskMap) => {
                      return (_openBlock(), _createBlock(_component_TaskMapCard, {
                        "task-map": taskMap,
                        actions: ['opacity'],
                        render: ""
                      }, null, 8 /* PROPS */, ["task-map"]))
                    }), 256 /* UNKEYED_FRAGMENT */))
              ])),
          (_ctx.activeTaskMapLayer?.data?.availableMaterials?.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_NoData, {
                  message: _ctx.$t('fact-map-analyze.no-materials-available', {'ns': 'taskMap'})
                }, null, 8 /* PROPS */, ["message"]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('fact-map-analyze.notice.no-materials-available', {'ns': 'taskMap'})), 1 /* TEXT */)
                ])
              ]))
            : (_ctx.activeTaskMapLayer?.data)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('fact-map-analyze.material.select-label', {'ns': 'taskMap'})), 1 /* TEXT */),
                    _createVNode(_component_FactMapMaterials, { layer: _ctx.activeTaskMapLayer }, null, 8 /* PROPS */, ["layer"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_el_radio_group, {
                      modelValue: _ctx.paintMode,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.paintMode) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio, { label: "default" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Абсолютные значения")
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_el_radio, {
                          label: "deflection",
                          disabled: !_ctx.activeTaskMapLayer?.data?.activeMaterial?.targetMaterial
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Отклонения от нормы")
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["disabled"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('fact-map-analyze.histogram.label', {'ns': 'taskMap'})), 1 /* TEXT */),
                    _createVNode(_component_el_checkbox, {
                      modelValue: _ctx.hideChartDrops,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.hideChartDrops) = $event)),
                      label: "Не отображать пропуски и сбросы"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    (_ctx.activeTaskMapLayer?.data?.geojson?.features && _ctx.activeTaskMapLayer.data?.activeMaterial?.targetMaterial)
                      ? (_openBlock(), _createBlock(_component_apexchart, {
                          key: 0,
                          options: _ctx.chartOptions,
                          ref: "chart",
                          series: _ctx.series
                        }, null, 8 /* PROPS */, ["options", "series"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('fact-map-analyze.groups.header', {'ns': 'taskMap'})), 1 /* TEXT */),
                    (_ctx.activeTaskMapLayer)
                      ? (_openBlock(), _createBlock(_component_FactMapStopsWidget, {
                          key: 0,
                          layer: _ctx.activeTaskMapLayer
                        }, null, 8 /* PROPS */, ["layer"]))
                      : _createCommentVNode("v-if", true)
                  ])
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (_ctx.activeTaskMapLayer?.data.statistics)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_el_button, {
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.showStats = true)),
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('fact-map-analyze.stats-btn.label', {'ns': 'taskMap'})), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.showStats,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.showStats) = $event)),
        width: 800,
        header: _ctx.$t('fact-map-analyze.stats.header', {'ns': 'taskMap'})
      }, {
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_el_button, {
              onClick: _cache[4] || (_cache[4] = $event => (_ctx.showStats = false)),
              type: "default"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('fact-map-analyze.stats.tab.general', {'ns': 'taskMap'})
              }, {
                default: _withCtx(() => [
                  (_ctx.activeTaskMapLayer)
                    ? (_openBlock(), _createBlock(_component_FactMapStatsWidget, {
                        key: 0,
                        layer: _ctx.activeTaskMapLayer
                      }, null, 8 /* PROPS */, ["layer"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('fact-map-analyze.stats.tab.chart', {'ns': 'taskMap'})
              }, {
                default: _withCtx(() => [
                  (_ctx.activeTaskMapLayer?.data?.geojson?.features && _ctx.activeTaskMapLayer.data?.activeMaterial?.targetMaterial)
                    ? (_openBlock(), _createBlock(_component_apexchart, {
                        key: 0,
                        options: _ctx.chartOptions,
                        ref: "chart",
                        series: _ctx.series
                      }, null, 8 /* PROPS */, ["options", "series"]))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.hideChartDrops,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.hideChartDrops) = $event)),
                    label: "Не отображать пропуски и сбросы"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "header"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}