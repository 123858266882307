import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "WorkMapContent" }
const _hoisted_2 = { class: "WorkMapContent-body" }
const _hoisted_3 = {
  key: 0,
  class: "WorkMapContent-body-noSelectFields no-data-text"
}
const _hoisted_4 = { class: "mb-8" }
const _hoisted_5 = { class: "flex-row flex-row-justify-between" }
const _hoisted_6 = { class: "flex-col" }
const _hoisted_7 = { class: "WorkMapContent-title" }
const _hoisted_8 = { class: "WorkMapContent-title mt-20" }

export function render(_ctx, _cache) {
  const _component_TaskMapSelector = _resolveComponent("TaskMapSelector")
  const _component_el_button = _resolveComponent("el-button")
  const _component_Content = _resolveComponent("Content")
  const _component_TaskMapNormaFormBlock = _resolveComponent("TaskMapNormaFormBlock")
  const _component_TaskMapZoneFormBlock = _resolveComponent("TaskMapZoneFormBlock")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.activeModeCreateTask === 0)
      ? (_openBlock(), _createBlock(_component_Content, {
          key: 0,
          title: _ctx.$t(_ctx.route.name, { ns: 'route' })
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_button, {
                onClick: _cache[0] || (_cache[0] = $event => {_ctx.activeModeCreateTask = 1 ;}),
                disabled: _ctx.selectedFields.length === 0,
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('to-norms', { ns:'taskMap' })), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"]),
              _createVNode(_component_el_button, {
                onClick: _cache[1] || (_cache[1] = $event => {_ctx.activeModeCreateTask = 2 ;}),
                disabled: _ctx.selectedFields.length === 0,
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('to-zones', { ns: 'taskMap' })), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"]),
              _createVNode(_component_el_button, {
                type: "info",
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.activeInfo = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("?")
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.selectedFields.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('fields-no-select', { ns: 'taskMap' })), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFields, (field, idx) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_TaskMapSelector, {
                      modelValue: _ctx.selectBaseTaskMaps[idx],
                      "onUpdate:modelValue": $event => ((_ctx.selectBaseTaskMaps[idx]) = $event),
                      map: _ctx.MapContainerEnum.MAIN_MAP,
                      "task-maps": field.baseTaskMaps,
                      actions: ['select-map'],
                      "un-select": false
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "map", "task-maps"])
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true),
    (_ctx.activeModeCreateTask  === 1)
      ? (_openBlock(), _createBlock(_component_TaskMapNormaFormBlock, { key: 1 }))
      : _createCommentVNode("v-if", true),
    (_ctx.activeModeCreateTask  === 2)
      ? (_openBlock(), _createBlock(_component_TaskMapZoneFormBlock, { key: 2 }))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.activeInfo,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.activeInfo) = $event)),
        header: _ctx.$t('info-to-task-title', { ns: 'taskMap' })
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('to-norma', { ns: 'taskMap' })) + ":", 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('to-norma-info', { ns: 'taskMap' })), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('to-zones', { ns: 'taskMap' })) + ":", 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('to-zones-info', { ns: 'taskMap' })), 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "header"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}