<template src="./SatelliteEstimationBlock.pug" lang="pug"/>
<style src="./SatelliteEstimationBlock.scss" lang="scss"/>

<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import {
  computed, defineComponent, onMounted, onUnmounted, ref, UnwrapRef,
} from 'vue';
import { StarFilled } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import { useSatellites } from '@/composables/useSatellites';
import CustomCheckbox from '@/components/shared/CustomCheckbox/CustomCheckbox.vue';
import SatellitesService from '@/modules/satellites/SatellitesService';
import FieldsList from '@/modules/fields/FieldsList';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import SatelliteFilesList from '@/modules/satellites/SatelliteFilesList';
import { SatelliteFileModel } from '@/models/satellite/SatelliteFileModel';
import round from '@/lib/tools/round';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';
import { useTranslate } from '@tolgee/vue';

export default defineComponent({
  name: 'SatelliteEstimationBlock',
  components: {
    RightPanel,
    StarFilled,
    CustomCheckbox,
  },
  setup() {
    const {
      activeIndexes,
    } = useSatellites();
    const {
      mapModel,
      activeField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const { t } = useTranslate('satellite');

    // ↓
    const sortItems = [
      { label: 'high-grade', value: 0 },
      { label: 'low-grade', value: 1 },
      { label: 'newer', value: 2 },
      { label: 'older', value: 3 },
    ];
    const sort = ref(0);

    const hideBad = ref(false);

    const showPreview = ref(false);

    const activeName = ref('msf');

    const getData = async (fieldId: number | undefined) => {
      if (fieldId) {
        LoadingStatus.loading(LoadingNamesEnum.SATELLITE);
        try {
          const field = FieldsList.getFieldModel(fieldId);
          if (field) {
            await SatelliteFilesList.fetchFieldData(field);
          }
          LoadingStatus.success(LoadingNamesEnum.SATELLITE);
        } catch (e) {
          LoadingStatus.error(LoadingNamesEnum.SATELLITE, e);
        }
      }
    };

    onMounted(async () => {
      await getData(activeField.value?.id || 0);
    });

    onUnmounted(() => {
      SatelliteFilesList.setActiveFile();
      if (activeField.value) {
        activeField.value.animateLoading = false;
      }
    });

    const preview = () => {
      SatellitesService.generateMsfFile(activeField.value?.id || 0);
      showPreview.value = true;
    };

    const setActiveFile = (file: SatelliteFileModel) => {
      SatelliteFilesList.setActiveFile(file);
      mapModel.value?.render(file.nir);
    };

    const computedFiles = computed(() => SatelliteFilesList.files.filter((f) => f.field.id === activeField.value?.id || 0)
      .filter((f) => (hideBad.value ? f.estimation.isAcceptable : true))
      .sort((a: UnwrapRef<SatelliteFileModel>, b: UnwrapRef<SatelliteFileModel>) => {
        if (sort.value === 0 || sort.value === 1) {
          if (a.estimation.estimationAuto && b.estimation.estimationAuto) {
            const k = sort.value === 0 ? 1 : -1;
            return (b.estimation.estimationAuto - a.estimation.estimationAuto) * k;
          }
          return -1;
        }
        if (sort.value === 2 || sort.value === 3) {
          if (a.nir.scene.datetime && b.nir.scene.datetime) {
            const k = sort.value === 3 ? 1 : -1;
            return a.nir.scene.datetime > b.nir.scene.datetime ? k : -1 * k;
          }
          return -1;
        }
        return 0;
      }) as SatelliteFileModel[]);

    const isMsfPreviewAvailable = computed(() => computedFiles.value.filter((f: SatelliteFileModel) => f.estimation.isChecked).length > 0);

    const toggleIndex = async (index: FileIndexKindEnums) => {
      const indexFile = await SatelliteFilesList.active.value?.callIndex(index);
      if (!indexFile?.file) {
        ElNotification({
          message: t.value('error-message-while-generate-index-file'),
          type: 'error',
          position: 'bottom-right',
        });
      } else if (activeField.value?.id === indexFile.fieldId && SatelliteFilesList.active.value?.nir.scene.id === indexFile.scene) {
        SatelliteFilesList.active.value?.setActiveIndexFile(indexFile);
      }
    };

    const calculateIndexes = () => {
      if (activeField.value) {
        SatellitesService.calculateIndexes([activeField.value.id]);
        ElNotification(
          {
            message: t.value('msf-create-request-is-sent'),
            type: 'success',
            position: 'bottom-right',
          },
        );
        showPreview.value = false;
      }
    };

    FieldsEvents.onChangeActiveField(async (fieldId) => {
      SatelliteFilesList.setActiveFile();
      if (activeField.value) {
        activeField.value.animateLoading = false;
      }
      showPreview.value = false;
      await getData(fieldId);
    });

    const loading = ref(LoadingStatus.isLoading.value(LoadingNamesEnum.SATELLITE));

    const estimationLoading = ref([] as number[]);
    const toggleEstimation = async (file: SatelliteFileModel) => {
      if (!estimationLoading.value.includes(file.estimation.id)) {
        estimationLoading.value.push(file.estimation.id);
        await file.estimation.toggleEstimation();
        estimationLoading.value.splice(estimationLoading.value.indexOf(file.estimation.id), 1);
      }
    };

    return {
      activeField,
      SatelliteFilesList,
      toggleEstimation,
      estimationLoading,
      loading,
      activeIndexes,
      sortItems,
      sort,
      hideBad,
      showPreview,
      preview,
      setActiveFile,
      computedFiles,
      isMsfPreviewAvailable,
      calculateIndexes,
      activeName,
      round,
      toggleIndex,
      FileIndexKindEnums,
    };
  },
});
</script>
