import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { getEnumKeyByValue } from '@/lib/tools/enum';
import type { MapModel } from '@/models/map/MapModel';
import { Model } from '@/models/Model';
import LoggerService from '@/services/logger/LoggerService';
import { LayerSpecification, Source } from 'mapbox-gl';
import { reactive } from 'vue';

export class MapLayerModel extends Model {
  public layerId = ''

  public layerType: MapLayerTypeEnum;

  public sourceId = '';

  public layerIds: string[] = [];

  public sourceIds: string[] = [];

  public layerName: string;

  // range 0 - 100 %
  public opacity = 100;

  public showLegend = reactive({ value: false });

  protected _mapModel: MapModel | undefined;

  constructor(mapModel: MapModel, type: MapLayerTypeEnum, layerName: string, uuid: string) {
    super();
    LoggerService.from(LoggerGroupsEnum.MAP_MODEL).group(`Create map model ${getEnumKeyByValue(MapLayerTypeEnum, type)} by model: `, mapModel);
    this._mapModel = mapModel;
    this.layerName = layerName;
    this.layerId = `layer-${layerName}-${uuid}`;
    this.sourceId = `source-${layerName}-${uuid}`;
    this.layerType = type;
    mapModel.map?.on('mousedown', this.layerId, () => {
      this._mapModel?.mapEvents?.pushClickStack({ type: this.layerType, layer: this.layerId, source: this.sourceId });
    });
  }

  protected get layer(): LayerSpecification | undefined {
    return this._mapModel?.map?.getLayer(this.layerId);
  }

  protected get source(): Source | undefined {
    return this._mapModel?.map?.getSource(this.sourceId);
  }

  public mouseDownHandler(): void {
    this._mapModel?.mapEvents?.pushClickStack({ type: this.layerType, layer: this.layerId, source: this.sourceId });
  }

  moveTo(to: string) {
    this.layerIds.forEach((l) => {
      this._mapModel?.map?.moveLayer(l, to as string);
    });
  }

  /*
  * params 0 - 100 %
  * */
  setOpacity(opacity: number): void {
    this.opacity = opacity;
    if ([MapLayerTypeEnum.NIR_FILE, MapLayerTypeEnum.RASTER_FILE, MapLayerTypeEnum.MONITORING_INDEX, MapLayerTypeEnum.AVERAGE_INDEX, MapLayerTypeEnum.CANVAS].includes(this.layerType)) {
      this._mapModel?.map?.setPaintProperty(this.layerId, 'raster-opacity', Math.round(opacity * 100) / 10000);
    } else {
      this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-opacity', Math.round(opacity * 100) / 10000);
    }
  }
}
