<template lang="pug">
.ph-20
  el-image(
    :src="legend.image"
    style="max-width: 300px; max-height: 300px"
    :preview-src-list="[legend.image]"
    preview-teleported
  )
</template>

<script  lang="ts">
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import LegendTable from '@/modules/map/container/MapLegend/LegendTable.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerRasterModel } from '@/models/map/Layers/MapLayerRasterModel';

export default defineComponent({
  name: 'RasterLegend',
  components: { LegendTable },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    layer: {
      type: Object as PropType<MapLayerRasterModel>,
      required: true,
    },
  },
  setup(props) {
    const a = ref(1);

    const legend = computed(() => props.layer.data.legend);

    return {
      a,
      legend,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
