<script  lang="ts">
import { defineComponent, ref, watch } from 'vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';

export default defineComponent({
  name: 'UiDrawer',
  components: { UiIcon },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
    },
  },
  emits: ['update:show', 'close'],
  setup(props, { emit }) {
    const titleIcon = ref('#bdbdbd');
    const closeDrawer = () => {
      emit('close');
      emit('update:show', false);
    };
    const hoverIcon = () => {
      titleIcon.value = '#787878';
    };
    const leftIcon = () => {
      titleIcon.value = '#bdbdbd';
    };

    const activated = ref(props.show);

    watch(() => props.show, () => {
      activated.value = props.show;
    });

    return {
      closeDrawer,
      leftIcon,
      hoverIcon,
      titleIcon,
      activated,
    };
  },
});
</script>

<template lang="pug">
.UiDrawer(
  :class="{ [`--position-${position}`]: true, active: activated }"
)
  .UiDrawer-header
    .UiDrawer-header-title {{title}}
    .UiDrawer-header-close(
      @click="closeDrawer"
    )
  .UiDrawer-content
    slot
</template>

<style lang="scss">
.UiDrawer{
  position: absolute;
  background: #FFF;
  z-index: 3;
  box-shadow: 0 15px 20px rgba(0, 0, 0, .15);
  overflow: auto;
  top: 50px;
  bottom: 30px;
  width: 400px;
  height: calc(100vh - 80px);
  transition: var(--transition-default);
  visibility: hidden;
  opacity: 0;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  &.--position-left{
    left: 0;
    transform: scale(0, 1);
    transform-origin: 0 50%;
  }
  &.--position-left.active {
    transform: scale(1, 1);
  }
  &.--position-right{
    right: 0;
    transform: scale(0, 1);
    transform-origin: 100% 50%;
  }
  &.--position-right.active {
    transform: scale(1, 1);
  }
  &-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 28px;
    padding: 6px 10px;
    font-weight: 500;
    color: var(--color-black-300);
    &-close {
      @include cross(28px, #666, 2px);
    }
  }
}
</style>
