import { polygonToPoints } from '@/lib/map/polygonToPoints';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { GeoJSONSource, MapMouseEvent } from 'mapbox-gl';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapAreaModel } from '@/models/map/data/MapAreaModel';
import { Feature, FeatureCollection, Polygon } from 'geojson';
import polylabel from 'polylabel';
import { area } from '@turf/turf';
import { formatArea } from '@/utils/formatArea';

export class MapLayerDrawerModel extends MapLayerModel implements IMapLayerModel {
  get activeMode(): 'none' | 'create' | 'edit' {
    return this._activeMode;
  }

  get cordsDrawer(): [number, number][] {
    return this._cordsDrawer;
  }

  get data(): MapAreaModel {
    return this._data;
  }

  private _cordsDrawer: [number, number][] = []

  private _data: MapAreaModel

  private _activeMode: 'none' | 'create' | 'edit' = 'none'

  private movePointIndex = -1

  private bindMoveMouse = this.handlerMoveMouse.bind(this);

  private bindClick = this.handlerClick.bind(this);

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'drawer', input.uuid);
    this._data = input as MapAreaModel;
    if ('coordinates' in this._data.polygon.geometry) {
      this._cordsDrawer = (this._data.polygon.geometry.coordinates[0] as [number, number][]).filter((a, i, arr) => {
        if ('coordinates' in this._data.polygon.geometry) {
          return i !== (this._data.polygon.geometry?.coordinates[0] as [number, number][]).length - 1;
        }
        return false;
      }) as [number, number][];
    }
    this.createSourceLayer();
    this.handlerPresEnter();
    this.handlerPresEsc();
    this.handlerHoverPoint();
     this._mapModel?.map && (this._mapModel.map.getCanvas().style.cursor = 'pointer');
     this.layerIds.push(...[this.layerId, `${this.layerId}-contour`, `${this.layerId}-points`, `${this.layerId}-label-area`]);
     this.sourceIds.push(...[this.sourceId, `${this.sourceId}-points`, `${this.sourceId}-label-area`]);
     this.handlerClickPoint();

       this._mapModel?.map?.on('mousemove', this.bindMoveMouse);
       this._mapModel.map.on('click', this.bindClick);
  }

  offEventListener() {
    this._mapModel?.map?.off('mousemove', this.bindMoveMouse);
    this._mapModel?.map?.off('click', this.bindClick);
  }

  createSourceLayer() {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this._data.polygon,
    });
    this._mapModel?.map?.addSource(`${this.sourceId}-points`, {
      type: 'geojson',
      // @ts-ignore
      data: polygonToPoints(this._data.polygon.geometry as Polygon),
    });
     this._mapModel?.map?.addSource(`${this.sourceId}-label-area`, {
       type: 'geojson',
       data: {
         type: 'FeatureCollection',
         features: [],
       } as FeatureCollection,
     });

    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'fill',
      source: this.sourceId,
      metadata: { type: 'drawer-fill' },
      layout: {},
      paint: {
        'fill-color': '#000000',
        'fill-opacity': 0.4,
      },
    });
    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-contour`,
      type: 'line',
      source: this.sourceId,
      metadata: { type: 'drawer-line' },
      paint: {
        'line-color': '#000000',
        'line-width': 2,
      },
    });
     this._mapModel?.map?.addLayer({
       id: `${this.layerId}-points`,
       type: 'circle',
       source: `${this.sourceId}-points`,
       metadata: { type: 'drawer-point' },
       paint: {
         'circle-radius': 5,
         'circle-color': '#000000',
       },
     });

     this._mapModel?.map?.addLayer({
       id: `${this.layerId}-label-area`,
       type: 'symbol',
       source: `${this.sourceId}-label-area`,
       metadata: { type: 'drawer-label' },
       layout: {
         'text-field': ['get', 'label'],
         'text-radial-offset': 0.5,
         'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
         'text-size': [
           'interpolate', ['linear'], ['zoom'],
           9, 0,
           10, 5,
           11, 10,
           12, 13,
           20, 14,
         ],
         'text-justify': 'center',
         // @ts-ignore
         'text-font': [
           'literal',
           ['Inter Regular'],
         ],
       },
       paint: {
         'text-color': '#25282B',
         'text-halo-color': 'rgba(255,255,255,0.6)',
         'text-halo-width': 2,
       },
     });

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.DRAWER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-contour`, MapAnchorEnum.DRAWER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-points`, MapAnchorEnum.DRAWER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-label-area`, MapAnchorEnum.DRAWER);
  }

  updatePolygon(cords: [number, number][]) {
    if ('coordinates' in this._data.polygon.geometry) {
      this._data.polygon.geometry.coordinates[0] = [...cords, cords[0]].filter(Boolean);

      this.updateLabel();
    }
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(this._data.polygon);
     // @ts-ignore
     (this._mapModel?.map?.getSource(`${this.sourceId}-points`) as GeoJSONSource)?.setData(polygonToPoints(this._data.polygon.geometry));
  }

  updateLabel() {
    if ('coordinates' in this._data.polygon.geometry && (this._data.polygon.geometry?.coordinates[0] as Array<any>).length > 2) {
      // @ts-ignore
      const labelCoords = polylabel(this._data.polygon.geometry?.coordinates, 10000, false);

      const fc = area(this._data.polygon);
      (this._mapModel?.map?.getSource(`${this.sourceId}-label-area`) as GeoJSONSource).setData({
        type: 'Feature',
        geometry: {
          type: 'Point',
          // @ts-ignore
          coordinates: labelCoords,
        },
        properties: {
          label: formatArea(fc),
        },
      } as Feature);
    } else {
      (this._mapModel?.map?.getSource(`${this.sourceId}-label-area`) as GeoJSONSource).setData({
        type: 'Feature',
        geometry: {
          type: 'Point',
          // @ts-ignore
          coordinates: [0, 0],
        },
        properties: {
          label: '',
        },
      } as Feature);
    }
  }

  setActiveMode(mode: 'edit'| 'create'|'none') {
    this._activeMode = mode;
    this._mapModel.events.emitUpdateDrawerMode(mode);
  }

  clearDraw() {
    this._cordsDrawer = [];
    this.updatePolygon([]);
  }

  stopDraw() {
    this.updatePolygon(this._cordsDrawer);
     this._mapModel?.map && (this._mapModel.map.getCanvas().style.cursor = '');
     this.setActiveMode('edit');
  }

  handlerClick(e: MapMouseEvent): void {
    if (this.activeMode === 'create') {
      const a = this._mapModel?.map?.queryRenderedFeatures(e.point, {
        layers: [`${this.layerId}-points`],
      });
      if (this._cordsDrawer.length === 1 && a.length > 1) {
        //
      } else if (a && this._cordsDrawer.length > 1 && a?.length > 1) {
        this.stopDraw();
      } else {
        this._cordsDrawer.push([e.lngLat.lng, e.lngLat.lat]);
        this.updatePolygon(this._cordsDrawer);
        if (this._cordsDrawer.length > 0 && this.activeMode === 'create') {
          const updatedCoords = [...this._cordsDrawer];
          updatedCoords.push([e.lngLat.lng, e.lngLat.lat]);
          this.updatePolygon(updatedCoords);
        }
      }
    }
  }

  handlerMoveMouse(e: MapMouseEvent) {
    if (this._cordsDrawer.length > 0 && this.activeMode === 'create') {
      const updatedCoords = [...this._cordsDrawer];
      updatedCoords.push([e.lngLat.lng, e.lngLat.lat]);
      this.updatePolygon(updatedCoords);
    }
  }

  handlerPresEnter() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.stopDraw();
      }
    });
  }

  handlerPresEsc() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'esc' || e.key === 'Escape') {
        this.data.clearCoordinates();
        this.clearDraw();
        this.setActiveMode('create');
      }
    });
  }

  handlerHoverPoint() {
     this._mapModel?.map?.on('mouseover', `${this.layerId}-points`, () => {
       if (this._mapModel?.map) {
         this._mapModel.map.getCanvas().style.cursor = 'pointer';
       }
     });
     this._mapModel?.map?.on('mouseleave', `${this.layerId}-points`, () => {
       if (this._mapModel?.map) {
         this._mapModel.map.getCanvas().style.cursor = '';
       }
     });
  }

  handlerClickPoint(): void {
    let startLngLat: [number, number] | null = null;

    const handleMouseMove = (e1: any) => {
      if (this.activeMode === 'edit' && startLngLat) {
        const updatedCords = [...this._cordsDrawer];
        updatedCords[this.movePointIndex] = [e1.lngLat.lng, e1.lngLat.lat];
        this.updatePolygon(updatedCords);
      }
    };

    this._mapModel?.map?.on('mousedown', `${this.layerId}-points`, (e) => {
      if (this.activeMode !== 'edit') return;
      e.preventDefault();
      const features = this._mapModel?.map?.queryRenderedFeatures(e.point, {
        layers: [`${this.layerId}-points`],
      });

      if (!(features && features.length > 0 && features[0].properties?.index !== undefined)) return;

      const [, indexB] = features[0].properties.index.split('_').map(Number);
      this.movePointIndex = indexB;

      startLngLat = [e.lngLat.lng, e.lngLat.lat];

      this._mapModel?.map?.on('mousemove', handleMouseMove);
    });

    this._mapModel?.map?.on('mouseup', (e1) => {
      if (this.activeMode !== 'edit' || startLngLat === null) return;

      const endLngLat: [number, number] = [e1.lngLat.lng, e1.lngLat.lat];

      // Проверяем что старт и начало одинаковые
      const hasMoved = startLngLat[0] !== endLngLat[0] || startLngLat[1] !== endLngLat[1];

      if (!hasMoved) {
        if (e1.originalEvent.ctrlKey) {
          const features = this._mapModel?.map?.queryRenderedFeatures(e1.point, {
            layers: [`${this.layerId}-points`],
          });

          if (features && features.length > 0 && features[0].properties?.index !== undefined) {
            const { 0: indexA, 1: indexB } = features[0].properties.index.split('_').map((a) => Number(a));

            this.cordsDrawer.splice(indexB, 1);

            this.updatePolygon(this.cordsDrawer);
            if (this.cordsDrawer.length === 1) {
              this.clearDraw();
              setTimeout(() => {
                this.setActiveMode('create');
              });
            }
          }
        } else {
          // Если координаты одинаковые, добавляем новые точки

          const features = this._mapModel?.map?.queryRenderedFeatures(e1.point, {
            layers: [`${this.layerId}-points`],
          });

          if (features && features.length > 0 && features[0].properties?.index !== undefined) {
            const [, indexB] = features[0].properties.index.split('_').map(Number);

            const calcMidpoint = (a: [number, number], b: [number, number]): [number, number] => [
              (a[0] + b[0]) / 2,
              (a[1] + b[1]) / 2,
            ];

            const newCordsDrawer: [number, number][] = this._cordsDrawer.flatMap((item, i, a) => {
              if (i !== indexB) return [item];

              if (indexB === 0) {
                const lastPoint = a[a.length - 1];
                return [
                  calcMidpoint(item, lastPoint),
                  item,
                  calcMidpoint(item, a[1]),
                ];
              }

              if (indexB === a.length - 1) {
                const firstPoint = a[0];
                return [
                  calcMidpoint(a[i - 1], item),
                  item,
                  calcMidpoint(item, firstPoint),
                ];
              }

              return [
                calcMidpoint(a[i - 1], item),
                item,
                calcMidpoint(item, a[i + 1]),
              ];
            });

            this._cordsDrawer = newCordsDrawer;
            this.updatePolygon(this._cordsDrawer);
          }
        }
      } else {
        // Если координаты не совпадают, обновляем её положение
        this._cordsDrawer[this.movePointIndex] = endLngLat;
        this.updatePolygon(this._cordsDrawer);
      }

      startLngLat = null;
      this.movePointIndex = -1;
      this._mapModel?.map?.off('mousemove', handleMouseMove);
    });
  }
}
