import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ImagesTree" }
const _hoisted_2 = {
  key: 0,
  class: "ta-center"
}
const _hoisted_3 = ["data-uuid"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "ImagesTree-cog" }
const _hoisted_8 = { style: {"padding":"5px 15px 10px 15px"} }
const _hoisted_9 = {
  key: 0,
  class: "ImagesTree-cog"
}

export function render(_ctx, _cache) {
  const _component_UiLoading = _resolveComponent("UiLoading")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_slider = _resolveComponent("el-slider")
  const _component_el_popover = _resolveComponent("el-popover")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_el_tree = _resolveComponent("el-tree")
  const _component_GroupPalettePanel = _resolveComponent("GroupPalettePanel")
  const _component_ui_drawer = _resolveComponent("ui-drawer")
  const _component_VectorPalettePanel = _resolveComponent("VectorPalettePanel")
  const _component_RasterPalettePanel = _resolveComponent("RasterPalettePanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_UiLoading)
          ]))
        : (_openBlock(), _createBlock(_component_el_tree, {
            key: 1,
            ref: "treeRef",
            style: {"max-width":"100%"},
            "allow-drop": _ctx.allowDrop,
            data: _ctx.data,
            draggable: "",
            "expand-on-click-node": true,
            "node-key": "key",
            onNodeDrop: _ctx.handleDrop
          }, {
            default: _withCtx(({ node, data }) => [
              _createVNode(_component_el_tooltip, {
                trigger: "hover",
                content: data.label,
                disabled: !(data.type !== 'group' && !['rating-fields', 'rosreestr'].includes(data.type)),
                placement: "top-end",
                "show-after": 50,
                "hide-after": 0
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["ImagesTree-row", { inactive: data.type !== 'group' & (!data.active || !_ctx.isVisible(data)) }]),
                    key: data.key,
                    "data-uuid": data.model?.uuid
                  }, [
                    _createElementVNode("div", {
                      class: "ImagesTree-checkbox",
                      onClick: $event => (_ctx.handleCheckbox($event, data))
                    }, [
                      _createVNode(_component_el_checkbox, {
                        "model-value": data.active,
                        disabled: data.type !== 'group' && !['rating-fields', 'rosreestr'].includes(data.type) && !data.model
                      }, null, 8 /* PROPS */, ["model-value", "disabled"])
                    ], 8 /* PROPS */, _hoisted_4),
                    _createElementVNode("div", {
                      class: "ImagesTree-icon",
                      onClick: $event => (_ctx.handleClick(data))
                    }, [
                      _createVNode(_component_ui_icon, {
                        name: _ctx.icon(data),
                        color: _ctx.iconColor(data)
                      }, null, 8 /* PROPS */, ["name", "color"])
                    ], 8 /* PROPS */, _hoisted_5),
                    _createElementVNode("div", {
                      class: "ImagesTree-label",
                      onClick: $event => (_ctx.handleClick(data))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(data.label), 1 /* TEXT */)
                    ], 8 /* PROPS */, _hoisted_6),
                    _createElementVNode("div", _hoisted_7, [
                      (data.type !== 'group')
                        ? (_openBlock(), _createBlock(_component_el_popover, {
                            key: 0,
                            width: 300,
                            trigger: "click"
                          }, {
                            reference: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                size: "small",
                                circle: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ui_icon, {
                                    name: "mdiOpacity",
                                    color: "currentColor"
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", null, _toDisplayString(_ctx.$t('opacity')), 1 /* TEXT */),
                                _createVNode(_component_el_slider, {
                                  modelValue: data.opacity,
                                  "onUpdate:modelValue": $event => ((data.opacity) = $event),
                                  step: 5,
                                  min: 0,
                                  max: 100,
                                  onInput: $event => (_ctx.setOpacity(data)),
                                  marks: _ctx.SliderMarks0to100
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onInput", "marks"])
                              ])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    (!['rating-fields', 'rosreestr'].includes(data.type))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_el_button, {
                            circle: "",
                            size: "small",
                            onClick: $event => (_ctx.openSettings($event, data))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ui_icon, {
                                name: "mdiCogOutline",
                                color: "currentColor"
                              })
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ], 10 /* CLASS, PROPS */, _hoisted_3))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["allow-drop", "data", "onNodeDrop"]))
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_drawer, {
        position: "right",
        show: _ctx.settings?.type === 'group' || false,
        title: _ctx.$t('group-palette-title', 'Настройка группы'),
        onClose: _cache[3] || (_cache[3] = $event => {_ctx.settings = undefined;})
      }, {
        default: _withCtx(() => [
          (_ctx.settings?.type === 'group')
            ? (_openBlock(), _createBlock(_component_GroupPalettePanel, {
                key: 0,
                node: _ctx.settings,
                "map-container": _ctx.mapContainer,
                onClose: _cache[0] || (_cache[0] = $event => {_ctx.settings = undefined;}),
                onSaveName: _cache[1] || (_cache[1] = $event => (_ctx.saveName($event))),
                onDeletePalette: _cache[2] || (_cache[2] = $event => (_ctx.deletePalette($event)))
              }, null, 8 /* PROPS */, ["node", "map-container"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["show", "title"]),
      (_ctx.settings?.type === 'vector')
        ? (_openBlock(), _createBlock(_component_VectorPalettePanel, {
            key: 0,
            node: _ctx.settings,
            "map-container": _ctx.mapContainer,
            onClose: _cache[4] || (_cache[4] = $event => {_ctx.settings = undefined;}),
            onSaveName: _cache[5] || (_cache[5] = $event => (_ctx.saveName($event))),
            onDeletePalette: _cache[6] || (_cache[6] = $event => (_ctx.deletePalette($event)))
          }, null, 8 /* PROPS */, ["node", "map-container"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_ui_drawer, {
        position: "right",
        show: _ctx.settings?.type === 'raster' || false,
        title: _ctx.$t('raster-palette-title', 'Настройка растра'),
        onClose: _cache[10] || (_cache[10] = $event => {_ctx.settings = undefined;})
      }, {
        default: _withCtx(() => [
          (_ctx.settings?.type === 'raster')
            ? (_openBlock(), _createBlock(_component_RasterPalettePanel, {
                key: 0,
                node: _ctx.settings,
                "map-container": _ctx.mapContainer,
                onClose: _cache[7] || (_cache[7] = $event => {_ctx.settings = undefined;}),
                onSaveName: _cache[8] || (_cache[8] = $event => (_ctx.saveName($event))),
                onDeletePalette: _cache[9] || (_cache[9] = $event => (_ctx.deletePalette($event)))
              }, null, 8 /* PROPS */, ["node", "map-container"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["show", "title"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}