<template lang="pug">
.IndexesInfo(
  :class="{ [`--effect-${effect}`]: true }"
)
  .IndexesInfo-header
    .IndexesInfo-short {{ $t(`index-info-short-${index}`, { ns:'monitoring' }) }}
    .IndexesInfo-full ({{ $t(`index-info-full-${index}`, { ns:'monitoring' }) }})
  .IndexesInfo-description {{ $t(`index-info-description-${index}`, { ns:'monitoring' }) }}
  .IndexesInfo-legend(
    v-if="legend && ['msf', 'rsf', 'rvi'].includes(legend.key)"
  )
    .IndexesInfo-legend-from {{ legend.colors[0].value }}
    .IndexesInfo-legend-color(
      v-for="c in legend.colors"
      :style="{ background: c.rgbHex }"
    )
    .IndexesInfo-legend-to {{ legend.colors[legend.colors.length - 1].value }}
</template>

<script>
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import DictionaryColorsKitList from '@/modules/dictionary/DictionaryColorsKitList';

export default defineComponent({
  name: 'IndexesInfo',
  props: {
    index: {
      type: String,
      required: true,
    },
    effect: {
      type: String,
      default: 'dark',
    },
  },
  setup(props) {
    onMounted(() => {
      // DictionaryColorsKitList.fetchColors();
    });
    const colorKits = ref(DictionaryColorsKitList.colorKits.value);

    const legend = computed(() => colorKits.value.find((c) => c.key === props.index));

    return {
      colorKits,
      legend,
    };
  },
});
</script>

<style lang="scss">
.IndexesInfo {
  $this: &;
  max-width: 340px;
  &.--effect-light {
    #{$this} {
      &-short {
        color: var(--color-black-300);
      }
      &-full {
        color: var(--color-black-300);
      }
      &-description {
        color: var(--color-black-400);
      }
      &-legend {
        border-top: 1px solid var(--color-dark-border);
        &-from {
          color: var(--color-black-500);
        }
        &-to {
          color: var(--color-black-500);
        }
      }
    }
  }
  &-header {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid var(--color-gray-200);
  }
  &-short {
    font-weight: 600;
    display: inline;
    font-size: 0.95rem;
    padding-right: 0.4rem;
    color: var(--color-white-500);
  }
  &-full {
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--color-gray-200);
    display: inline;
  }
  &-description {
    margin-top: 0.25rem;
    font-size: 0.85rem;
    color: var(--color-white-600);
    text-align: left;
  }
  &-legend {
    width: 100%;
    display: flex;
    margin-top: 6px;
    padding-top: 8px;
    line-height: 1rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1px;
    &-from {
      text-align: left;
      padding: 0 0.5rem;
      color: var(--color-white-500);
    }
    &-to {
      padding: 0 0.5rem;
      text-align: center;
      color: var(--color-white-500);
    }
    &-color {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
