import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_Loading = _resolveComponent("Loading")
  const _component_Select = _resolveComponent("Select")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["CustomCheckbox", { checked: _ctx.props.value, disabled: _ctx.props.disabled}]),
    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_el_icon, { size: 15 }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
          : _createCommentVNode("v-if", true),
        (!_ctx.loading && _ctx.props.value)
          ? (_openBlock(), _createBlock(_component_Select, { key: 1 }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}