<template lang="pug">
ui-dialog(
  v-model="open"
  :header="$t('edit-params', 'edit params', { ns: 'poi' })"
  width="600"
  top=""
)
  .POIEditBlock
    el-form(
      :model="poiFormInfo"
      label-width="250px"
      label-position="left"
      :rules="rules"
      ref='POIFormRef'
    )
      el-tabs(v-model="activeTabsEdit" )
        el-tab-pane(
          :label="$t('main', 'main', { ns: 'poi' })"
          name="Info"
        )
          el-form-item(
            :label="$t('name', 'name', { ns: 'poi' })"
            prop="name"
          )
            el-input(
              :placeholder="$t('input-name', 'input-name', { ns: 'poi' })"
              v-model="poiFormInfo.name"
              show-word-limit
              maxlength="80"
            )

          el-form-item(
            :label="$t('note', 'note', { ns: 'poi' })"
          )
            el-input(
              rows="3"
              type="textarea"
              :placeholder="$t('input-params-note', 'input-params-note', { ns: 'poi' })"
              v-model="poiFormInfo.descr"
              :maxlength="POIParams.description.maxLength"
              show-word-limit
              maxlength="127"
            )
          el-form-item(
            :label=" $t('point-visited', 'point-visited', { ns: 'poi' })"
          )
            el-switch(
              v-model="poiFormInfo.visited"
            )
        el-tab-pane(
          v-for="tab in availableTabs"
          :label=" $t(`${tab}`, tab, { ns: 'poi' })"
          :name="tab"
          :key='tab'
        )
          template(v-if=" tab === 'no-standard' " )

          template(v-for="(item,index) in poiFormInfo.params" :key='index' )
            template(v-if="item.category === tab" )
              el-form-item()
                template(#label)
                  div {{$t(`${item.key}`, item.key, { ns: 'poi' })}}
                    span(
                      v-if="item.unit"
                      style='font-weight: 400; color: #999; margin-left: 0.5rem; white-space: nowrap;'
                    ) ({{item.unit || ''}})
                el-input(
                  :placeholder=" $t('input-params', 'input-params', { ns: 'poi' })"
                  v-if="item.type === 'string'"
                  v-model="item.value"
                  :maxlength="item.maxLength || 0"
                  :show-word-limit="!!item.maxLength"
                )
                el-rate(
                  v-if="item.type === 'stars'"
                  v-model="item.value"
                  :max="3"
                  clearable
                )

                el-select(
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  placeholder="--"
                )
                  el-option(
                    v-for="item in item.select"
                    :key="item.value"
                    :label="item.title || item.label"
                    :value="item.value"
                  )
                .flex-row.fw.flex-align-center(
                  v-if="item.type === 'soilIndex'"
                )
                  el-input(
                    :placeholder="$t('input-params', 'input-params', { ns: 'poi' })"
                    v-model="item.value"
                    :show-word-limit="!!item.maxLength"
                    :maxlength="item.maxLength || 0"
                  )
                  .ml-6
                    el-button(
                      @click="addArrow(item,'↑')"
                    ) ↑

                  .ml-4
                    el-button(
                      @click="addArrow(item,'↓')"
                    ) ↓

                el-input-number(
                  v-if="item.type === 'number'"
                  :placeholder="$t('input-params', 'input-params', { ns: 'poi' })"
                  :model-value="item.value"
                  :value-on-clear="null"
                  @change="changeInputNumber($event,item)"
                  :min="item.min"
                  :max="item.max"
                )
                el-input-number(
                  v-if="item.type === 'int'"
                  :placeholder="$t('input-params', 'input-params', { ns: 'poi' })"
                  @change="changeInputNumber($event,item)"
                  :min="item.min"
                  :max="item.max"
                  :model-value="item.value"
                  :value-on-clear="null"
                )

                el-switch(
                  v-if="item.type === 'boolean'"
                  v-model="item.value"
                )
  .POIEditBlock_Footer
    el-button(type="primary" @click="onSubmit(POIFormRef)") {{ $t('save', 'save')}}
    el-button(@click="open = false") {{ $t('close', 'close') }}

</template>

<script lang="ts">

import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { POIParams } from '@/assets/data/POIParams';
import { PoiModel } from '@/models/poi/PoiModel';
import { OrderedPoiKeys } from '@/assets/data/OrderedPoiKeys';
import { POICategoryEnum } from '@/constants/enums/POICategoryEnum';
import PoiEvents from '@/modules/poi/PoiEvents';
import PoisList from '@/modules/poi/PoisList';
import { FormInstance } from 'element-plus';
import { cleanObject } from '@/utils/cleanObject';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { pythonRound } from '@/utils/pythonRound';

const POIFormRef = ref<FormInstance | null>(null);

export default defineComponent({
  components: { UiDialog },
  name: 'PoiEditDialog',
  emits: ['close'],
  setup(props, { emit }) {
    const open = ref(true);

    const { activePoi } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const poiFormInfo = ref <{
      name: string,
      descr: string,
      visited: boolean,
      params: Record<string, any>,
    }>({
      name: '',
      descr: '',
      visited: false,
      params: {},
    });

    const availableTabs = ref<string[]>([]);
    const activeTabsEdit = ref('Info');
    const excludeKeys = ['x', 'y', 'n_prob', 'comments', 'is_visited'];

    const calculateLabelWidth = (category: string) => {
      if (category === POICategoryEnum.LIMIT_FACTOR) {
        return 450;
      }
      if (category === POICategoryEnum.CUT) {
        return 360;
      }
      return 200;
    };

    const initForm = () => {
      if (activePoi.value) {
        poiFormInfo.value.descr = activePoi.value.description;
        poiFormInfo.value.name = activePoi.value.name;
        poiFormInfo.value.visited = activePoi.value.visited || false;

        poiFormInfo.value.params = Object.keys(POIParams).map((key) => ({
          key,
          ...POIParams[key],
          value: PoiModel.formatPoiValue(activePoi.value.params[key], POIParams[key].type),
          labelWidth: calculateLabelWidth(POIParams[key].category),
        }));

        Object.keys(activePoi.value.params).forEach((key) => {
          if (!(poiFormInfo.value.params as Array<Record<any, any>>).some((a) => a.key === key) && !excludeKeys.some((a) => a === key)) {
            (poiFormInfo.value.params as Array<Record<any, any>>).push({
              key,
              type: 'string',
              maxLength: 127,
              category: 'no-standard',
              value: activePoi.value.params[key],
              labelWidth: 400,
            });
          }
        });
        (poiFormInfo.value.params as Array<Record<any, any>>).sort((a, b) => (OrderedPoiKeys.indexOf(a.key) > OrderedPoiKeys.indexOf(b.key) ? 1 : -1));
      }
    };

    const tabsCheck = () => {
      let arr: string[] = [];
      const poiGroupType = PoisList.getGroupModel(activePoi.value?.group)?.type;

      if (poiGroupType === 1) {
        arr = ['soil', 'limit-factor', 'agro-chemistry'];

        if (
          Object.keys(poiFormInfo.value.params).some((a) => poiFormInfo.value.params.hasOwnProperty(a)
            && (poiFormInfo.value.params[a] as Record<any, any>).category === 'no-standard')
        ) {
          arr.push('no-standard');
        }
      } if (poiGroupType === 2) {
        arr = ['agro-chemistry'];
        if (
          Object.keys(poiFormInfo.value.params).some((a) => poiFormInfo.value.params.hasOwnProperty(a)
            && (poiFormInfo.value.params[a] as Record<any, any>).category === 'no-standard')
        ) {
          arr.push('no-standard');
        }
      }
      return arr;
    };

    const save = async () => {
      const formatedParams: Record<string, any> = {};
      if (activePoi.value) {
        // @ts-ignore
        poiFormInfo.value.params.forEach((param) => {
          if (![undefined].some((s) => s === param.value)) {
            if (param.type === 'stars') {
              formatedParams[param.key] = Number(param.value);
            } else {
              formatedParams[param.key] = param.value;
            }
          }
        });
        if (activePoi.value.params) {
          Object.keys(activePoi.value.params).forEach((key) => {
            const value = activePoi.value.params[key] || null;
            if ([undefined].some((s) => s === formatedParams[key])) {
              formatedParams[key] = value;
            }
          });
        }

        activePoi.value.name = poiFormInfo.value.name;
        activePoi.value.description = poiFormInfo.value.descr;
        activePoi.value.visited = poiFormInfo.value.visited;
        activePoi.value.params = {
          ...cleanObject(formatedParams),
          is_visited: poiFormInfo.value.visited,
        };

        open.value = false;
        await activePoi.value.saveModel();
        activeTabsEdit.value = 'Info';
        EventBus.$emit(EventsEnum.MapRedrawPois);
      }
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          save();
        }
      });
    };
    const changeInputNumber = (value: number | null, item: Record<string, any>) => {
      if (item.precision !== undefined && value !== null) {
        item.value = pythonRound(value, item.precision);
      } else {
        item.value = value;
      }
    };

    const addArrow = (item: any, arrow: string) => {
      item.value.length < item.maxLength && (item.value += arrow);
    };

    onMounted(() => {
      initForm();
    });

    PoiEvents.onUpdatePoiGroup(() => {
      initForm();
      availableTabs.value = tabsCheck();
    });

    onMounted(() => {
      availableTabs.value = tabsCheck();
    });

    watch(open, (a) => {
      if (!a) {
        emit('close');
      }
    });
    return {
      open,
      poiFormInfo,
      activeTabsEdit,
      POIParams,
      availableTabs,
      onSubmit,
      changeInputNumber,
      addArrow,
      POIFormRef,
    };
  },
});
</script>

<style lang="scss">
.POIEditBlock {
  .el-form-item {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-background-shaded);
    padding-bottom: 8px;
    margin-bottom: 8px;
    .el-form-item__label {
      align-items: center;
      line-height: 16px;
    }
    .el-form-item__content {
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
    }
  }
}
</style>
