<template lang="pug">
  el-tooltip(
    placement="left-start"
    :content="$t('move-to-home',{ns:'mapbox'})"
  )
    .MapContainer-Button(
      @click='fitStruct'
    )
      ui-icon(
        name="/images/icons/home.svg"
        :size="26"
      )
  .MapContainer-ButtonsGroup
    el-tooltip(
      placement="left-start"
      content="Увеличить зум"
    )
      .MapContainer-Button(
        @click='zoomIn'
      )
        ui-icon(
          name="CloseBold"
          :size="19"
          style="transform: rotate(45deg)"
        )
    el-tooltip(
      placement="left-start"
      content="Уменьшить зум"
    )
      .MapContainer-Button(
        @click='zoomOut'
      )
        ui-icon(
          name="SemiSelect"
          :size="19"
        )
    el-tooltip(
      placement="left-start"
      content="Повернуться на север"
    )
      .MapContainer-Button(
        @click='rotateZero'
      )

        ui-icon(
          :style="compassStyleRotate"
          name="/images/icons/compass.svg"
          :size="26"
        )
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { useMapContainers } from '@/composables/useMapContainers';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';

export default defineComponent({
  name: 'ViewMapButtons',
  setup() {
    const { fitStruct, mapModel } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const rotate = ref(0);

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      mapModel.value?.map.on('rotate', (e) => {
        rotate.value = mapModel.value.map.getBearing();
      });
    });

    const rotateZero = () => {
      mapModel.value.map.easeTo({
        pitch: 0,
        bearing: 0,
        duration: 500,
      });
    };
    const zoomIn = () => {
      mapModel.value.map.easeTo({
        zoom: (mapModel.value.map.getZoom() as number) + 1,
        duration: 500,
        essential: true,
      });
    };

    const zoomOut = () => {
      mapModel.value.map.easeTo({
        zoom: (mapModel.value.map.getZoom() as number) - 1,
        duration: 500,
        essential: true,
      });
    };

    const compassStyleRotate = computed(() => ({
      transform: `rotate(${(rotate.value)}deg)`,
    }));

    return {
      zoomIn,
      zoomOut,
      compassStyleRotate,
      rotateZero,
      fitStruct,
    };
  },
});

</script>

<style lang="scss" scoped>

</style>
