<template lang="pug">
  el-button(
    @click="show = true"
  ) {{ title }}
  ui-dialog(
    v-model="show"
    :header="title"
    :width="600"
  )
    vue-json-pretty(
      :data="json"
    )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import VueJsonPretty from 'vue-json-pretty';

export default defineComponent({
  name: 'PrintJson',
  components: {
    UiDialog,
    VueJsonPretty,
  },
  props: {
    json: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Print JSON',
    },
  },
  setup() {
    const show = ref(false);
    return {
      show,
    };
  },
});
</script>

<style lang="scss">
.SkeletonLoader {
  text-align: left;
  transition: var(--transition-default);
  height: auto;
  margin: 20px auto;
  padding: 0 15px;
  &-message {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-black-300);
    margin-bottom: 15px;
  }
}
</style>
