import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ph-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_image, {
      src: _ctx.legend.image,
      style: {"max-width":"300px","max-height":"300px"},
      "preview-src-list": [_ctx.legend.image],
      "preview-teleported": ""
    }, null, 8 /* PROPS */, ["src", "preview-src-list"])
  ]))
}