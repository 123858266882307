import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MapContainer-Button" }
const _hoisted_2 = { class: "MapUnderlaysMenu" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "right-end",
    width: _ctx.useUser().user.value?.isStaff ? 320 : 230,
    trigger: "hover",
    teleported: true,
    "hide-after": 0
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_icon, {
          name: "/images/icons/background.svg",
          size: 24
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.map?.underLayers, (layer) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.map?.underLayers)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "MapUnderlaysMenu_underlay",
                  onClick: ($event: any) => (_ctx.map.activeUnderLayer = layer)
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["MapUnderlaysMenu_underlay-image", { active: layer.value === _ctx.map.activeUnderLayer?.value }])
                  }, [
                    _createElementVNode("img", {
                      src: layer.img
                    }, null, 8 /* PROPS */, _hoisted_4)
                  ], 2 /* CLASS */),
                  _createElementVNode("div", {
                    class: _normalizeClass(["MapUnderlaysMenu_underlay-title", { active: layer.value === _ctx.map.activeUnderLayer?.value }])
                  }, _toDisplayString(layer.name), 3 /* TEXT, CLASS */)
                ], 8 /* PROPS */, _hoisted_3))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["width"]))
}