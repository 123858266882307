<template lang='pug'>
.ExperimentCard.theme-dark
  .ExperimentCard-Form
    .ExperimentCard-Body
      el-tabs(
        v-model="activeTab"
      )
        el-tab-pane(
          :label="$t('experiment-card-form', 'Свойства опыта', { ns: 'experiment' })"
          name="form"
        )
          el-form(
            label-position="top"
          )
            .ExperimentCard-form
              div
                el-form-item(
                  label="Наименование"
                  size="small"
                )
                  el-input(
                    v-model="experiment.title"
                  )

                el-form-item(
                  label="Цвет опыта"
                  size="small"
                )
                  .fw
                    ColorGradientPicker(
                      v-model="experiment.color"
                      :palettes="palettes"
                      :active-palette="activePalette"
                      @update:active-palette="setPalette"
                      :prefix-colors="['#FBFC02']"
                      :count="15"
                    )
              div
                el-form-item(
                  size="small"
                  label="Удобрения"
                )
                  el-input-number(
                    v-model="experiment.proc"
                    :max="100000"
                    :min="0"
                    :precision="0"
                  )
                el-form-item(
                  label="Семена"
                  size="small"
                )
                  el-input-number(
                    v-model="experiment.procSeed"
                    :max="100000"
                    :min="0"
                    :precision="0"
                  )

            .ta-left
              el-popconfirm(
                :title="$t('delete-confirmation')"
                :confirm-button-text="$t('delete')"
                :cancel-button-text="$t('no')"
                @confirm="$emit('delete')"
              )
                template(#reference)
                  el-button(
                    type="danger"
                    :icon="Delete"
                    size="small"
                  ) {{ $t('delete-experiment', 'Удалить опыт', { ns: 'experiments' }) }}

        //el-tab-pane(
        //  :label="$t('experiment-card-intersect', 'Пересечения', { ns: 'experiments' })"
        //  name="intersect"
        //)
        //  .ta-left.pb-10 {{ $t('experiment-card-intersect-description', 'Процентное соотношение площадей зон шаблона карты покрытых выбранным опытом.', { ns: 'experiments' }) }}
        //  .ExperimentCard-intersect(
        //    v-for="list in computedIntersect"
        //  )
        //    ProgressCard(
        //      :color="getColorZone(list.zone)"
        //      :progress="(list.areaHa / (area(experiment.feature) * 0.0001)) * 100"
        //      :summary="`${round(list.areaHa)} ${$t('ha',{ns:'common'})}`"
        //      )
</template>

<script lang='ts'>
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { GradientModel } from '@/models/assets/GradientModel';
import { defineComponent, PropType, ref } from 'vue';
import { ExperimentModel } from '@/models/ExperimentModel';
import ColorGradientPicker from '@/components/shared/ColorGradientPicker/ColorGradientPicker.vue';
import { area, polygon } from '@turf/turf';
import { VectorColors } from '@/assets/data/VectorColors';
import hexToRgba from '@/lib/convertors/hexToRgba';
import round from '@/lib/tools/round';
import ProgressCard from '@/components/ui/ProgressCard/ProgressCard.vue';
import { Delete } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'ExperimentCard',
  components: {
    ColorGradientPicker,
    ProgressCard,
  },
  props: {
    experiment: {
      type: Object as PropType<ExperimentModel>,
      required: true,
    },
    gradient: {
      type: Array as PropType<GradientModel[]>,
      required: true,
    },
    activeGradient: {
      type: Object as PropType<GradientModel>,
      required: true,
    },
  },
  emits: ['update:activeGradient', 'delete'],
  setup(props, { emit }) {
    const {
      activeField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const setGradient = ($event: GradientModel) => {
      emit('update:activeGradient', $event);
    };

    const activeTab = ref('form');

    const getColorZone = (zone: string) => {
      const colors = VectorColors.find((palette) => palette.name === 'zone');
      const color = colors?.palette.find((item) => item.value === zone)?.color;
      if (color) {
        return hexToRgba(color);
      }
      return [0, 0, 0, 0];
    };

    // const computedIntersect = computed(() => {
    //   const experimentPolygon = props.experiment.feature;
    //   const intersectList: { zone: string, areaHa: number }[] = [];
    //
    //   const taskMap = activeField.value?.baseTaskMaps.find((v) => v.selected);
    //   if (taskMap?.geojson) {
    //     taskMap.geojson.features.forEach((polygon2) => {
    //       const findZone = intersectList.find((s) => s.zone === polygon2.properties?.zone);
    //       // @ts-ignore
    //       const inter = intersect(experimentPolygon, polygon2 as Feature);
    //       if (inter && findZone === undefined) {
    //         intersectList.push({ zone: polygon2.properties?.zone as string, areaHa: area(inter) / 10000 });
    //       } else if (inter && findZone) {
    //         findZone.areaHa += area(inter) / 10000;
    //       }
    //     });
    //   }
    //   return intersectList;
    // });

    return {
      Delete,
      activeTab,
      area,
      polygon,
      round,
      setGradient,
      getColorZone,
      // computedIntersect,
    };
  },
});
</script>
<style lang='scss'>
.ExperimentCard {
  pointer-events: none;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  &-Form {
    position: relative;
    pointer-events: all;
    width: 100%;
    border-radius: 15px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, .35);
    background: var(--color-black-500);
  }
  &-form {
    display: grid;
    grid-template-columns: 1fr 180px;
    column-gap: 15px;
    width: 100%;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-Body {
    width: calc(100% - 20px);
    padding: 0 10px 10px;
  }
  &-block {
    padding: 10px;
    border-radius: 10px;
    width: calc(100% - 20px);
  }
  .el-tabs{
    &__content {
      min-height: 150px;
      max-height: 350px;
      overflow-y: auto;
    }
  }
}
</style>
