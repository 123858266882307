import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "NavigationAccount" }
const _hoisted_2 = { class: "NavigationAccount_Button" }
const _hoisted_3 = { class: "NavigationAccount_Button-email" }
const _hoisted_4 = { class: "NavigationAccount_Button-icon" }

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_popover = _resolveComponent("el-popover")
  const _component_VectorStandardsBlock = _resolveComponent("VectorStandardsBlock")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_popover, {
        width: "300px",
        "show-after": 300
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.user.email), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ui_icon, {
                name: "mdiAccountCircleOutline",
                size: 30
              })
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "NavigationAccount_menu-item",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.profile && _ctx.profile(...args)))
          }, "Профиль"),
          (_ctx.isDevMode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "NavigationAccount_menu-item",
                onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.consoleLogger && _ctx.consoleLogger(...args)))
              }, "Настройка консоли"))
            : _createCommentVNode("v-if", true),
          (_ctx.isDevMode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "NavigationAccount_menu-item",
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.standardsActive = true))
              }, "Стандарты"))
            : _createCommentVNode("v-if", true),
          (_ctx.isDevMode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "NavigationAccount_menu-item",
                onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.apiKeys && _ctx.apiKeys(...args)))
              }, "API Keys"))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: "NavigationAccount_menu-item",
            onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.logout && _ctx.logout(...args)))
          }, "Завершить сеанс")
        ]),
        _: 1 /* STABLE */
      })
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.standardsActive,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.standardsActive) = $event)),
        header: _ctx.$t('vector-standard-block-header', { ns: 'navigation' }),
        fullscreen: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VectorStandardsBlock)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "header"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}