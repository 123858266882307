<script  lang="ts">
import { defineComponent, ref } from 'vue';
import { techType } from '@/constants/constants/techTupe';
import { Download, Files } from '@element-plus/icons-vue';
import { TaskTypeEnum } from '@/constants/enums/TaskTypeEnum';
import StructList from '@/modules/struct/StructList';
import LoggerService from '@/services/logger/LoggerService';
import { useAuth } from '@/composables/useAuth';

export default defineComponent({
  name: 'downloadTaskForm',
  props: {
    taskId: {
      type: Number,
    },
    taskType: {
      type: Number,
    },
  },
  setup(props) {
    const isModal = ref(false);
    const activeTypeTech = ref(0);
    const { accessToken } = useAuth();

    const download = () => {
      let curl;
      switch (props.taskType) {
      case TaskTypeEnum.WORK:
        curl = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/work/${props.taskId}/download/${activeTypeTech.value}/?access_token=${accessToken.value}`;
        break;
      case TaskTypeEnum.BASE:
        curl = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/base/${props.taskId}/download/?access_token=${accessToken.value}`;
        break;
      case TaskTypeEnum.FACT:
        curl = `${process.env.VUE_APP_API_URL}/task-map/${StructList.activeStruct.value?.id}/fact/${props.taskId}/download/?access_token=${accessToken.value}`;
        break;
      default:
        LoggerService.error(`Download curl is not implemented yet for tab: ${props.taskType}`);
        break;
      }
      if (curl) {
        window.location.href = curl;
      }
    };

    return {
      techType,
      isModal,
      activeTypeTech,
      Download,
      download,
    };
  },
});

</script>

<template lang="pug">
el-button(
    size="small"
    type="primary"
    :icon="Download"
    @click="isModal = true"
  )
teleport(
    to="body"

  )
    ui-dialog(
      v-model="isModal"
      header="Скачать карту задания в выбранном формате"
    )
      el-form-item(
        label="Тип техники"
      )
        el-select(
          v-model="activeTypeTech"
        )
          el-option(
            v-for="item in techType"
            :value="item.value"
            :key="item.value"
            :label="item.name"
          )
              .flex-row.flex-row-justify-between
                  span(
                      :style="{float: 'left'}"
                  ) {{item.name}}
                  span(
                      :style="{fontSize: 10+'px',color:'var(--color-foreground-light)'}"
                  ) {{item.type}}
      template(#footer)
        el-button(
          @click="download()"
            type="primary"
        ) Скачать
</template>

<style scoped lang="scss">

</style>
