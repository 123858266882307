import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "FieldDrawTool" }
const _hoisted_2 = { class: "FieldDrawTool-tools" }

export function render(_ctx, _cache) {
  const _component_WikiContent = _resolveComponent("WikiContent")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('deletePolygonOrLinearRing', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            id: "62",
            input: "delete polygon and hole",
            width: "300"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button bg-button-light", { active:  _ctx.activeAction === 'delete' }]),
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.setAction('delete')))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiEraser",
              size: 24,
              color:  _ctx.activeAction === 'delete' ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('deletePolygonOrLinearRing', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            id: "62",
            input: "delete polygon and hole",
            width: "300"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button bg-button-light", { active:  _ctx.activeAction === 'addPolygon' }]),
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.setAction('addPolygon')))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiVectorPolygonVariant",
              size: 24,
              color:  _ctx.activeAction === 'addPolygon' ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('deletePolygonOrLinearRing', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            id: "62",
            input: "delete polygon and hole",
            width: "300"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["BaseMapEditor-button bg-button-light", { active:  _ctx.activeAction === 'addRing' }]),
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.setAction('addRing')))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiCircleDouble",
              size: 24,
              color:  _ctx.activeAction === 'addRing' ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('unionPolygons', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "union"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", { active:  _ctx.activeAction === 'union' }]),
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.union()))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiVectorUnion",
              size: 24,
              color:  _ctx.activeAction === 'union' ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('cutDifference', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "cut difference"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "FieldDrawTool-button bg-button-light",
            onClick: _cache[4] || (_cache[4] = $event => (_ctx.cutDifference()))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiVectorDifferenceAb",
              size: 24,
              color: '#5a5f65'
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('scissors', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "scissors"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", { active:  _ctx.activeAction === 'cut' }]),
            onClick: _cache[5] || (_cache[5] = $event => (_ctx.setAction('cut')))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiContentCut",
              size: 24,
              color:  _ctx.activeAction === 'cut' ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('reshape', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "reshape"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", { active:  _ctx.activeAction === 'outsideInside' }]),
            onClick: _cache[6] || (_cache[6] = $event => (_ctx.setAction('outsideInside')))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiBorderInside",
              size: 24,
              color:  _ctx.activeAction === 'outsideInside' ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('magnet', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "magnet"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", { active:  _ctx.magnetActive }]),
            onClick: _cache[7] || (_cache[7] = $event => (_ctx.magnetActive = !_ctx.magnetActive))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiMagnet",
              size: 24,
              color:  _ctx.magnetActive ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('lockDragMap', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "block drag"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", { active:  _ctx.isBlockDrag }]),
            onClick: _cache[8] || (_cache[8] = $event => (_ctx.toggleBlockDrag()))
          }, [
            _createVNode(_component_ui_icon, {
              name: "mdiLockOutline",
              size: 24,
              color:  _ctx.isBlockDrag ? '#FFF' : '#5a5f65'
            }, null, 8 /* PROPS */, ["color"])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"]),
      _createVNode(_component_el_tooltip, {
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "undo"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", {disabled: _ctx.activeHistoryIndex <= 0}]),
            onClick: _cache[9] || (_cache[9] = $event => (_ctx.activeHistoryIndex--))
          }, [
            _createVNode(_component_ui_icon, {
              name: "/images/icons/undo.svg",
              color: "#5a5f65"
            })
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_tooltip, {
        content: _ctx.$t('lockDragMap', { ns : 'fields' }),
        "raw-content": "",
        placement: "bottom",
        effect: "dark",
        "show-after": 300
      }, {
        content: _withCtx(() => [
          _createVNode(_component_WikiContent, {
            width: "300",
            id: "62",
            input: "redo"
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["FieldDrawTool-button bg-button-light", {disabled: _ctx.activeHistoryIndex  === _ctx.historyCoords.length - 1 || _ctx.historyCoords.length === 0}]),
            onClick: _cache[10] || (_cache[10] = $event => (_ctx.activeHistoryIndex++))
          }, [
            _createVNode(_component_ui_icon, {
              name: "/images/icons/redo.svg",
              color: "#5a5f65"
            })
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["content"])
    ])
  ]))
}