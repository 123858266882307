<template lang="pug">
.SkeletonLoader(
  :class="{ active: active }"
)
  .SkeletonLoader-message(
    v-html="message"
  )
  el-skeleton(
    :rows="5"
    animated
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SkeletonLoader',
  components: {},
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: 'Загрузка данных, пожалуйста подождите..',
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
.SkeletonLoader {
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition-default);
  height: 0;
  &.active {
    height: auto;
    visibility: visible;
    opacity: 1;
    margin: 20px auto;
    padding: 0 15px;
  }
  &-message {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-black-300);
    margin-bottom: 15px;
  }
}
</style>
