import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ProgressCard" }
const _hoisted_2 = { class: "ProgressCard-info" }
const _hoisted_3 = { class: "ProgressCard-row" }
const _hoisted_4 = { class: "ProgressCard-info-left" }
const _hoisted_5 = { class: "ProgressCard-info-label" }
const _hoisted_6 = { class: "ProgressCard-info-prgoress" }
const _hoisted_7 = { class: "ProgressCard-info-right" }
const _hoisted_8 = {
  key: 0,
  class: "ProgressCard-info-value"
}
const _hoisted_9 = {
  key: 1,
  class: "ProgressCard-info-summary"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "ProgressCard-border",
      style: _normalizeStyle({ backgroundColor: `rgba(${_ctx.color.join(',')})`})
    }, null, 4 /* STYLE */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "ProgressCard-background",
        style: _normalizeStyle({ background: `rgba(${[_ctx.color[0], _ctx.color[1], _ctx.color[2], 100].join(',')})`, width: `${_ctx.progress}%`})
      }, null, 4 /* STYLE */),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.label), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.progress.toFixed(2)) + "%", 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.value), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          (_ctx.summary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.summary), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}