<template lang="pug">
el-popover(
  placement="right-end"
  :width="useUser().user.value?.isStaff ? 320 : 230"
  trigger="hover"
  :teleported="true"
  :hide-after="0"
)
  template(#reference)
    .MapContainer-Button
      ui-icon(
        name="/images/icons/background.svg"
        :size="24"
      )

  template(#default)
    .MapUnderlaysMenu
      template(
        v-for="layer in map?.underLayers"
      )
        .MapUnderlaysMenu_underlay(
          v-if="map?.underLayers"
          @click="map.activeUnderLayer = layer"
        )
          .MapUnderlaysMenu_underlay-image(
            :class="{ active: layer.value === map.activeUnderLayer?.value }"
          )
            img(:src="layer.img")
          .MapUnderlaysMenu_underlay-title(
            :class="{ active: layer.value === map.activeUnderLayer?.value }"
          ) {{ layer.name }}
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, UnwrapRef,
} from 'vue';
import { MapModel } from '@/models/map/MapModel';
import EventBus from '@/services/eventBus/EventBus';
import { useUser } from '@/composables/useUser';

export default defineComponent({
  name: 'MapUnderlaysMenu',
  props: {
    map: {
      type: Object as PropType<UnwrapRef<MapModel>>,
      required: true,
    },
  },
  setup(props) {
    const activeLayer = ref(props.map?.activeUnderLayer);
    EventBus.$on('close-compare-block', () => {
      const defaultLayer = props.map?.underLayers.find((l) => l.value === 'openstreetmap1');
      if (defaultLayer && props.map) {
        (props.map as MapModel).activeUnderLayer = defaultLayer;
      }
    });
    return {
      activeLayer,
      useUser,
    };
  },
});
</script>

<style lang="scss">
.MapUnderlaysMenu {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  &_underlay {
    position: relative;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &-image {
      img {
        width: 83px;
        height: 83px;
        border-radius: 5px;
        border: 4px solid var(--color-white-600);
      }

      &.active {
        img {
          border-color: var(--color-primary-500);
        }
      }
    }

    &-title{
      width: 76px;
      font-size: 0.8rem;
      text-align: center;
      &.active {
        color: var(--color-primary-500);
      }
    }
  }
}
</style>
