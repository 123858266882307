import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "List",
  ref: "listRef"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "List-title" }
const _hoisted_4 = {
  key: 0,
  class: "List-actions"
}
const _hoisted_5 = ["draggable", "data-idx", "onDragstart"]
const _hoisted_6 = {
  width: "25",
  height: "25",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
}
const _hoisted_7 = ["d"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerItems, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["List-item", { active: item === _ctx.value, dragging: _ctx.draggingItemOrder === idx }]),
        key: `list-row-${idx + 1}`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["List-row", { draggable: _ctx.dragAble }]),
          onClick: $event => (_ctx.value = item)
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(item[_ctx.titleKey]), 1 /* TEXT */),
          (_ctx.hasActions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "actions", { item: item })
              ]))
            : _createCommentVNode("v-if", true)
        ], 10 /* CLASS, PROPS */, _hoisted_2),
        _withDirectives(_createElementVNode("div", {
          class: "List-drag",
          draggable: _ctx.dragAble,
          "data-idx": idx,
          onDragstart: $event => (_ctx.dragStart(idx)),
          onDragend: _cache[0] || (_cache[0] = (...args) => (_ctx.dragEnd && _ctx.dragEnd(...args))),
          onDragover: _cache[1] || (_cache[1] = (...args) => (_ctx.dragOver && _ctx.dragOver(...args)))
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_6, [
            _createElementVNode("path", { d: _ctx.mdiDragVertical }, null, 8 /* PROPS */, _hoisted_7)
          ]))
        ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_5), [
          [_vShow, _ctx.dragAble]
        ])
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 512 /* NEED_PATCH */))
}