<template lang='pug'>
.Hotkeys
  template(v-for="key in keys" )
    .Hotkeys-key(
      v-if="key === 'LMB' || key === 'LMB-click' || key === 'LMB-hold'"
    )
      svg(
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="1.5"
      )
        path(
          d="M20 10V14C20 18.4183 16.4183 22 12 22C7.58172 22 4 18.4183 4 14V9C4 5.13401 7.13401 2 11 2H12C16.4183 2 20 5.58172 20 10Z" stroke="currentColor" stroke-linecap="round"
        )
        path(
          d="M12 2V8.4C12 8.73137 11.7314 9 11.4 9H4" stroke="currentColor" stroke-linecap="round"
        )
      span(v-if="key === 'LMB' || key === 'LMB-click'") click
      span(v-if="key === 'LMB-hold'") hold
    .Hotkeys-key(
      v-else-if="key === 'RMB' || key === 'RMB-click' || key === 'RMB-hold'"
    )
      svg(
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="1.5"
      )

        path(
          d="M4 10V14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14V9C20 5.13401 16.866 2 13 2H12C7.58172 2 4 5.58172 4 10Z" stroke="currentColor" stroke-linecap="round"
        )
        path(
          d="M12 2V8.4C12 8.73137 12.2686 9 12.6 9H20" stroke="currentColor" stroke-linecap="round"
        )
      span(v-if="key === 'RMB' || key === 'RMB-click'") click
      span(v-if="key === 'RMB-hold'") hold

    .Hotkeys-key(
      v-else-if="key === 'Wheel' || key === 'Wheel-up' || key === 'Wheel-down'"
    )
      svg(
        fill="none" height="24" stroke-width="1.5" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"
      )
        path(
          d="M12 5L12.5303 4.46967C12.2374 4.17678 11.7626 4.17678 11.4697 4.46967L12 5ZM12 13L11.4697 13.5303C11.7626 13.8232 12.2374 13.8232 12.5303 13.5303L12 13ZM9.46967 6.46967C9.17678 6.76256 9.17678 7.23744 9.46967 7.53033C9.76256 7.82322 10.2374 7.82322 10.5303 7.53033L9.46967 6.46967ZM13.4697 7.53033C13.7626 7.82322 14.2374 7.82322 14.5303 7.53033C14.8232 7.23744 14.8232 6.76256 14.5303 6.46967L13.4697 7.53033ZM10.5303 10.4697C10.2374 10.1768 9.76256 10.1768 9.46967 10.4697C9.17678 10.7626 9.17678 11.2374 9.46967 11.5303L10.5303 10.4697ZM14.5303 11.5303C14.8232 11.2374 14.8232 10.7626 14.5303 10.4697C14.2374 10.1768 13.7626 10.1768 13.4697 10.4697L14.5303 11.5303ZM3.25 10V14H4.75V10H3.25ZM20.75 14V10H19.25V14H20.75ZM11.25 5V13H12.75V5H11.25ZM11.4697 4.46967L9.46967 6.46967L10.5303 7.53033L12.5303 5.53033L11.4697 4.46967ZM11.4697 5.53033L13.4697 7.53033L14.5303 6.46967L12.5303 4.46967L11.4697 5.53033ZM12.5303 12.4697L10.5303 10.4697L9.46967 11.5303L11.4697 13.5303L12.5303 12.4697ZM12.5303 13.5303L14.5303 11.5303L13.4697 10.4697L11.4697 12.4697L12.5303 13.5303ZM20.75 10C20.75 5.16751 16.8325 1.25 12 1.25V2.75C16.0041 2.75 19.25 5.99594 19.25 10H20.75ZM12 22.75C16.8325 22.75 20.75 18.8325 20.75 14H19.25C19.25 18.0041 16.0041 21.25 12 21.25V22.75ZM3.25 14C3.25 18.8325 7.16751 22.75 12 22.75V21.25C7.99594 21.25 4.75 18.0041 4.75 14H3.25ZM4.75 10C4.75 5.99594 7.99594 2.75 12 2.75V1.25C7.16751 1.25 3.25 5.16751 3.25 10H4.75Z" fill="currentColor"
        )
      span(v-if="key === 'Wheel-up'") up
      span(v-if="key === 'RMB-down'") down
    .Hotkeys-key(
      v-else
    ) {{ key }}
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Hotkey',
  props: {
    hotkey: {
      type: String,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const keys = props.hotkey.split(' + ');

    return {
      keys,
    };
  },
});
</script>
<style lang='scss'>
.Hotkeys {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  &-key {
    border: 1px solid #CCC;
    width: fit-content;
    font-size: 13px;
    line-height: 23px;
    height: 23px;
    padding: 0 6px;
    border-radius: 6px;
    box-shadow: 2px 3px 5px #DDD, inset 2px 3px 6px #ECECEC;
    background-color: #F6F6F6;
    font-weight: 500;
    color: #464646;
    letter-spacing: -0.5px;
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
