import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "FieldsSelector" }
const _hoisted_2 = {
  key: 0,
  class: "FieldsSelector_search"
}
const _hoisted_3 = ["onClick", "onMouseenter", "onMouseleave", "data-pk"]
const _hoisted_4 = { class: "FieldsSelector_image" }
const _hoisted_5 = { class: "FieldsSelector_info" }
const _hoisted_6 = { class: "FieldsSelector_info-name" }
const _hoisted_7 = {
  key: 0,
  class: "FieldsSelector_info-area"
}
const _hoisted_8 = {
  key: 1,
  class: "FieldsSelector_info-notice"
}
const _hoisted_9 = {
  key: 1,
  class: "FieldsSelector_icon"
}
const _hoisted_10 = {
  key: 1,
  class: "FieldsSelector_counter"
}
const _hoisted_11 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_Select = _resolveComponent("Select")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_FieldView = _resolveComponent("FieldView")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.internalSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_input, {
            placeholder: _ctx.$t('search-field', { ns: 'placeholder' }),
            "prefix-icon": _ctx.Search,
            modelValue: _ctx.internalSearchValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.internalSearchValue) = $event))
          }, null, 8 /* PROPS */, ["placeholder", "prefix-icon", "modelValue"])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["FieldsSelector_fields", { ['--has-counter']: _ctx.showTotalRow }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedFields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["FieldsSelector_feature", _ctx.featureClassList(field)]),
          onClick: $event => (_ctx.selectFieldFeature(field)),
          onMouseenter: $event => (_ctx.onMouseEnter(field)),
          onMouseleave: $event => (_ctx.onMouseLeave(field)),
          "data-pk": field?.id
        }, [
          (_ctx.selectAble)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["FieldsSelector_feature-checkbox", { selected: (_ctx.activeField?.id === field?.id) || _ctx.hasSelectedField(field?.id), disabled: _ctx.isDisabledFeature(field) ,  }])
              }, [
                (_ctx.hasSelectedField(field?.id))
                  ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Select)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_4, [
            field
              ? (_openBlock(), _createBlock(_component_FieldView, {
                  key: 0,
                  id: field.id,
                  size: _ctx.size,
                  active: (_ctx.selectAble && _ctx.hasSelectedField(field?.id)) || (_ctx.activeAble && _ctx.activeField?.id === field?.id)
                }, null, 8 /* PROPS */, ["id", "size", "active"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(field?.name), 1 /* TEXT */),
            (_ctx.showArea)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(Math.round(field?.sq * 100) / 100) + " га", 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            (_ctx.hasNotice)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  field
                    ? _renderSlot(_ctx.$slots, "notice", {
                        key: 0,
                        feature: field?.dto,
                        field: field,
                        isSelected: _ctx.hasSelectedField(field?.id),
                        selectedFeatures: _ctx.selectedFields
                      })
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          (_ctx.hasIcon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                field
                  ? _renderSlot(_ctx.$slots, "icon", {
                      key: 0,
                      feature: field?.dto,
                      field: field,
                      isSelected: _ctx.hasSelectedField(field?.id),
                      selectedFeatures: _ctx.selectedFields
                    })
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 2 /* CLASS */),
    (_ctx.showTotalRow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('total', { ns: 'common' })) + " : " + _toDisplayString(_ctx.fields.length), 1 /* TEXT */),
          (_ctx.maxSelectCount >= 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.selectedFields.length) + " / " + _toDisplayString(_ctx.maxSelectCount), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}