<script lang="ts" setup>
import {
  computed, defineProps, ref, watch,
} from 'vue';
import { MapLayerTaskMapFactModel } from '@/models/map/Layers/MapLayerTaskMapFactModel';
import { ElColorPicker } from 'element-plus';

interface Props {
  layer: MapLayerTaskMapFactModel;
}

const props = defineProps<Props>();

const opacity = ref(80);

// Получаем доступ к _stops через модель
const stops = computed(() => {
  if (!props.layer || !props.layer.data) return [];
  return props.layer.data.stops || [];
});

// Форматирование числа для отображения
const formatNumber = (num: number): string => num.toFixed(0);

// Форматирование диапазона
const formatRange = (from: number, to: number): string => `${formatNumber(from)} - ${formatNumber(to)}`;

// Определяем тип диапазона
const getStopType = (index: number): string => {
  if (stops.value.length <= 2) return stops.value[index]?.norm ? `${formatNumber(stops.value[index].norm)}` : 'Норма';

  if (index === 0) return 'Пропуск';
  if (index === stops.value.length - 1) return 'Сброс';
  return stops.value[index]?.norm ? `${formatNumber(stops.value[index].norm)}` : 'Норма';
};

// Обработчик изменения цвета
const handleColorChange = (color: string, index: number) => {
  if (props.layer && props.layer.data && props.layer.data.stops) {
    // Обновляем цвет в модели
    // eslint-disable-next-line vue/no-mutating-props
    props.layer.data.stops[index].color = color;
    // Обновляем слой на карте
    props.layer.update();
  }
};

const opacityMarks = {
  0: {
    style: {
      color: '#666',
    },
    label: '0%',
  },
  25: {
    style: {
      color: '#666',
    },
    label: '25%',
  },
  50: {
    style: {
      color: '#666',
    },
    label: '50%',
  },
  75: {
    style: {
      color: '#666',
    },
    label: '75%',
  },
  100: {
    style: {
      color: '#666',
    },
    label: '100%',
  },
};

watch(opacity, (v) => {
  props.layer.setOpacity(v);
});
</script>

<template lang="pug">
  .fact-map-stops-widget
    .no-data(v-if="stops.length === 0")
      | Нет данных о диапазонах внесения

    table.stops-table(v-else)
      thead
        tr
          th
          th Тип
          th Диапазон
      tbody
        tr(v-for="(stop, index) in stops" :key="index")
          td
            //- Для норм добавляем выбор цвета, для пропусков и сбросов оставляем просто цветной блок
            el-color-picker(
              v-model="stop.color"
              size="small"
              @change="(color) => handleColorChange(color, index)"
            )
          td {{ getStopType(index) }}
          td {{ formatRange(stop.from, stop.to) }}
  .form-header {{ $t('opacity') }}
  .ph-15
    el-slider(
       v-model="opacity"
      :min="0"
      :max="100"
      :step="0.1"
      :show-input="false"
      :marks="opacityMarks"
    )
</template>

<style lang="scss" scoped>
.fact-map-stops-widget {

  .widget-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  .no-data {
    padding: 16px;
    text-align: center;
    color: #666;
    font-style: italic;
  }

  .stops-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;

    th, td {
      padding: 8px 12px;
      text-align: left;
      border-bottom: 1px solid #eee;
    }

    th {
      font-weight: 600;
      color: #555;
      background-color: #f5f5f5;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }

  .color-box {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }

  // Стили для компонента выбора цвета
  :deep(.el-color-picker) {
    margin: 0;

    .el-color-picker__trigger {
      width: 24px;
      height: 24px;
      padding: 0;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
}
</style>
