import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { StructAttributeType } from '@/constants/types/struct/StructAttributeType';
import { YieldUnitNameType } from '@/constants/types/YieldUnitNameType';
import { processingType } from '@/lib/convertors/processingType';
import { fetcher } from '@/lib/tools/fetcher';
import { IntegrationModel } from '@/models/integration/IntegrationModel';
import ApiService from '@/services/api/ApiService';
import { StructUnitDto } from '@/services/api/dto/struct/StructUnitDto';
import { Point } from 'geojson';
import { reactive, UnwrapRef } from 'vue';
import StructEvents from '@/modules/struct/StructEvents';

export class StructModel {
  get tags(): { id: number; name: string }[] {
    return this._tags;
  }

  get logo(): string | undefined {
    return this._logo;
  }

  get statAttributes(): Array<UnwrapRef<StructAttributeType>> {
    return this._statAttributes;
  }

  get fullName(): string {
    return this._fullName;
  }

  get isSelectable(): boolean {
    return this._isSelectable;
  }

  get yieldUnitName(): YieldUnitNameType {
    return this._yieldUnitName;
  }

  set yieldUnitName(value: YieldUnitNameType) {
    this._yieldUnitName = value;
    ApiService.struct.updateYieldUnit(value).then(() => {
      StructEvents.emitChangeStructYieldUnit();
    });
  }

  /**
   * Список интеграций с другими сервисами
   */
  get integrations(): IntegrationModel[] {
    return this._integrations;
  }

  /**
   * Среднегодовые осадки в мм
   */
  get averageAnnualPrecipitation(): number {
    return this._averageAnnualPrecipitation;
  }

  /**
   * Пользователи подразделения.
   */
  get users(): { id: number; email: string; firstName: string; lastName: string }[] {
    return this._users;
  }

  /**
   * Учетная площадь полей подразделения, включая дочерние подразделения.
   */
  get hierarchyAreaAcc(): number {
    return this._hierarchyAreaAcc;
  }

  /**
   * Фактическая площадь полей подразделения, включая дочерние подразделения.
   */
  get hierarchyArea(): number {
    return this._hierarchyArea;
  }

  /**
   * Учетная площадь полей подразделения, НЕ включая дочерние подразделения.
   */
  get areaAcc(): number {
    return this._areaAcc;
  }

  /**
   * Фактическая площадь полей подразделения, НЕ включая дочерние подразделения.
   */
  get area(): number {
    return this._area;
  }

  /**
   * Число полей текущего подразделения. Не включая дочерние подразделения.
   */
  get fieldsCount(): number {
    return this._fieldsCount;
  }

  /**
   * Годовое количество осадков. Включая дочерние подразделения.
   */
  get hierarchyAverageAnnualPrecipitation(): number {
    return this._hierarchyAverageAnnualPrecipitation;
  }

  /**
   * Число полей текущего подразделения. Включая дочерние подразделения.
   */

  get hierarchyFieldsCount(): number {
    return this._hierarchyFieldsCount;
  }

  /**
   * Уровень вложенности подразделения. 0 - верхнее подразделения.
   */
  get level(): number {
    return this._level;
  }

  /**
   * ID родительского подразделения.
   */
  get parent(): number | null {
    return this._parent;
  }

  /**
   * Наименование подразделения.
   */
  get name(): string {
    return this._name;
  }

  /**
   * ID подразделения.
   */
  get id(): number {
    return this._id;
  }

  private readonly _id: number;

  private readonly _name: string;

  private readonly _fullName: string;

  private readonly _parent: number | null;

  private readonly _level: number;

  private readonly _isSelectable: boolean;

  private _logo: string | undefined;

  private _fieldsCount = 0;

  private _averageAnnualPrecipitation = 0;

  private _area = 0;

  private _areaAcc = 0;

  private _hierarchyFieldsCount = 0;

  private _hierarchyAverageAnnualPrecipitation = 0;

  private _hierarchyArea = 0;

  private _hierarchyAreaAcc = 0;

  private _integrations: IntegrationModel[] = [];

  private _yieldUnitName: YieldUnitNameType = 'centner_he';

  private _centroid: Point | undefined;

  private _statAttributes = reactive<StructAttributeType[]>([]);

  private _tags: {id: number, name: string}[] = []

  private _users: {
    id: number,
    email: string,
    firstName: string,
    lastName: string
  }[] = [];

  constructor(dto: StructUnitDto) {
    this._id = dto.id;
    this._level = dto.level;
    this._parent = dto.parent;
    this._name = dto.name;
    this._fullName = dto.full_name.split('/').join(' / ');
    this._isSelectable = dto.is_selectable;
    this._tags = dto.tags;
  }

  async fetchUnitDetails(force = false): Promise<void> {
    return fetcher(LoadingNamesEnum.STRUCT_LIST_DETAILS, 'default', force, async () => {
      const { data } = await ApiService.struct.getStructDetails(this._id);
      this._logo = data.logo;

      if (data.statistics) {
        this._fieldsCount = data.statistics.field_count;
        this._averageAnnualPrecipitation = data.statistics.average_annual_precipitation;
        this._area = data.statistics.total_area;
        this._areaAcc = data.statistics.total_area_acc;
        this._statAttributes.splice(0, this._statAttributes.length);
        data.statistics.attributes.forEach((attribute) => {
          this._statAttributes.push({
            name: attribute.name,
            value: processingType(attribute.value, attribute.processing_type),
            processingType: attribute.processing_type,
            prefix: '',
            postfix: attribute.value_unit || '',
          } as StructAttributeType);
        });
      }

      if (data.hierarchy_statistics) {
        this._hierarchyFieldsCount = data.hierarchy_statistics.field_count;
        this._hierarchyAverageAnnualPrecipitation = data.hierarchy_statistics.average_annual_precipitation;
        this._hierarchyArea = data.hierarchy_statistics.total_area;
        this._hierarchyAreaAcc = data.hierarchy_statistics.total_area_acc;
      }

      if (data.conf) {
        this._yieldUnitName = data.conf.yield_unit_name;
        this._centroid = data.conf.centroid as Point;
      }

      this._users = [];
      data.users_list.forEach((userDto) => {
        this._users.push({
          id: userDto.id,
          email: userDto.email,
          firstName: userDto.first_name,
          lastName: userDto.last_name,
        });
      });
    });
  }

  async fetchIntegrations(force = false) {
    // if (LoadingStatus.isNone.value(LoadingNamesEnum.STRUCT_INTEGRATION) || force) {
    //   LoadingStatus.loading(LoadingNamesEnum.STRUCT_INTEGRATION);
    //   try {
    //     const { data } = await ApiService.struct.getIntegration();
    //     this._integrations = data.map((dto) => new IntegrationModel(dto));
    //     LoadingStatus.success(LoadingNamesEnum.STRUCT_INTEGRATION);
    //   } catch (e) {
    //     LoadingStatus.error(LoadingNamesEnum.STRUCT_INTEGRATION, e);
    //   }
    // }
  }
}
