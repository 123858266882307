import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "POITable" }
const _hoisted_2 = { class: "POITable-controllers" }
const _hoisted_3 = { class: "flex-row gap-5" }
const _hoisted_4 = { class: "flex-col flex-col-align-start gap-5" }
const _hoisted_5 = { class: "flex-row" }
const _hoisted_6 = {
  class: "POITable-table",
  ref: "table"
}
const _hoisted_7 = { class: "POITable-container" }
const _hoisted_8 = { class: "POITable-container-header" }
const _hoisted_9 = ["id"]
const _hoisted_10 = {
  key: 0,
  class: "flex-row fw fh pv-3 ph-5 flex-row-justify-between gap-1"
}
const _hoisted_11 = { class: "title" }
const _hoisted_12 = {
  key: 0,
  style: {"font-weight":"400","color":"#999","margin-left":"0.5rem","white-space":"nowrap"}
}
const _hoisted_13 = { class: "flex-row gap-2" }
const _hoisted_14 = { class: "flex-col gap-6" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "POITable-container-header" }
const _hoisted_20 = { class: "POITable-container-row" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 4,
  class: "flex-row gap-3"
}
const _hoisted_23 = { class: "ml-6" }
const _hoisted_24 = { class: "ml-4" }
const _hoisted_25 = {
  key: 0,
  class: "POITable-cell"
}
const _hoisted_26 = { class: "POITable-cell--poi-name" }
const _hoisted_27 = {
  class: "flex-row flex-row-align-center flex-row-justify-center",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_28 = {
  key: 1,
  class: "POITable-cell-rate"
}
const _hoisted_29 = {
  key: 0,
  class: "POITable-cell"
}
const _hoisted_30 = {
  key: 1,
  class: "POITable-cell"
}
const _hoisted_31 = { class: "POITable-container-footer" }
const _hoisted_32 = ["id"]
const _hoisted_33 = { class: "flex-row flex-col-justify-between" }
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = ["onClick"]
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_button_group = _resolveComponent("el-button-group")
  const _component_el_popover = _resolveComponent("el-popover")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_SortCaret = _resolveComponent("SortCaret")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_rate = _resolveComponent("el-rate")
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_el_image = _resolveComponent("el-image")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_popover, {
          title: _ctx.$t('download-table',{ns:'common'}),
          width: 190,
          trigger: "hover"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_button_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, { onClick: _ctx.createTableCsv }, {
                    default: _withCtx(() => [
                      _createTextVNode("csv")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_el_button, { onClick: _ctx.createTableXlsx }, {
                    default: _withCtx(() => [
                      _createTextVNode("xlsx")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_el_button, { onClick: _ctx.createTableHtml }, {
                    default: _withCtx(() => [
                      _createTextVNode("html")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          reference: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "ml-8",
              icon: _ctx.Document
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('save',{ns:'common'})), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["icon"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title"]),
        _createElementVNode("div", null, [
          _createVNode(_component_el_input, {
            class: "ml-8",
            modelValue: _ctx.searchValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.searchValue) = $event)),
            placeholder: _ctx.$t('search'),
            style: {"width":"400px"}
          }, {
            append: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.searchParam,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.searchParam) = $event)),
                placeholder: _ctx.$t('params'),
                style: {"width":"150px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedOptions, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.name,
                      label: item.alias,
                      value: item.name
                    }, null, 8 /* PROPS */, ["label", "value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "placeholder"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "placeholder"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_tooltip, {
          content: _ctx.$t('tooltip-add-column', {ns:'ui-table'})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              class: "ml-8",
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.confirmAddColumnConfirm())),
              icon: _ctx.Plus
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('add-column',{ns:'ui-table'})), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["icon"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["content"]),
        _createVNode(_component_el_select, {
          class: "ml-8",
          modelValue: _ctx.showListParams,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.showListParams) = $event)),
          multiple: "",
          filterable: "",
          "allow-create": "",
          "default-first-option": "",
          "reserve-keyword": false,
          placeholder: "Показать",
          "fit-input-width": true,
          "collapse-tags": "",
          "collapse-tags-tooltip": ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localKeys, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item,
                label: item.alias || item.name,
                value: item.name
              }, null, 8 /* PROPS */, ["label", "value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedKeys, (key, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["POITable-container-header-item", {stickHead: key.lock}]),
              id: `${key.name}Head`,
              style: _normalizeStyle({left: _ctx.shadowHeaderWidth(key.name)})
            }, [
              (_ctx.editingKey?.name !== key.name)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_el_tooltip, {
                      content: `Shape key: ${key.name} (${key.type}) `
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          _createTextVNode(_toDisplayString(key.alias || key.name), 1 /* TEXT */),
                          (_ctx.POIParams[key.name]?.unit)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, "(" + _toDisplayString(_ctx.POIParams[key.name]?.unit) + ")", 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"]),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_SortCaret, {
                        sort: _ctx.keyToSort === key.name ? _ctx.sortOrder : 0,
                        "inactive-color": "#374957",
                        onClick: $event => (_ctx.prepareSortingKey(key.name))
                      }, null, 8 /* PROPS */, ["sort", "onClick"]),
                      _createVNode(_component_el_popover, {
                        width: 190,
                        trigger: "hover"
                      }, {
                        reference: _withCtx(() => [
                          _createVNode(_component_ui_icon, {
                            name: "mdiDotsVertical",
                            size: 14,
                            color: "#000000"
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", {
                              class: "flex-row",
                              style: {"cursor":"pointer"},
                              onClick: $event => (_ctx.lockColumn(key.name))
                            }, [
                              _createVNode(_component_ui_icon, {
                                name: "mdiLockOutline",
                                color: key.lock ? '#5adc36' : '#000000'
                              }, null, 8 /* PROPS */, ["color"]),
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('lock-column',{ns:'ui-table'})), 1 /* TEXT */)
                            ], 8 /* PROPS */, _hoisted_15),
                            (key.editAble)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "flex-row",
                                  style: {"cursor":"pointer"},
                                  onClick: $event => (_ctx.editKey(key))
                                }, [
                                  _createVNode(_component_ui_icon, {
                                    name: "mdiTableEdit",
                                    color: "#000000"
                                  }),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('edit-name-column', {ns: 'ui-table'})), 1 /* TEXT */)
                                ], 8 /* PROPS */, _hoisted_16))
                              : _createCommentVNode("v-if", true),
                            (key.params?.deleteAble)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: "flex-row",
                                  style: {"cursor":"pointer"},
                                  onClick: $event => (_ctx.confirmDeleteColumn(key))
                                }, [
                                  _createVNode(_component_ui_icon, {
                                    name: "mdiTrashCanOutline",
                                    color: "#f00000"
                                  }),
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('delete-column', {ns: 'ui-table'})), 1 /* TEXT */)
                                ], 8 /* PROPS */, _hoisted_17))
                              : _createCommentVNode("v-if", true)
                          ])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.activeKeyValue,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.activeKeyValue) = $event)),
                      onChange: _cache[5] || (_cache[5] = $event => (_ctx.changeKey()))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]))
            ], 14 /* CLASS, STYLE, PROPS */, _hoisted_9))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_19, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchData, (row, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_20, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedKeys, (key, index1) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["POITable-container-row-item", { stick: key.lock }]),
                  style: _normalizeStyle({ left:  _ctx.shadowHeaderWidth(key.name)}),
                  onClick: $event => (_ctx.setActiveCell($event, key, row, index))
                }, [
                  (_ctx.isActiveCell(key.name, index))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (key.type === _ctx.TableKeyEnum.poiName)
                          ? (_openBlock(), _createBlock(_component_el_input, {
                              key: 0,
                              modelValue: _ctx.activeValue,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.activeValue) = $event)),
                              onBlur: _ctx.change
                            }, null, 8 /* PROPS */, ["modelValue", "onBlur"]))
                          : _createCommentVNode("v-if", true),
                        (key.type === _ctx.TableKeyEnum.stars)
                          ? (_openBlock(), _createBlock(_component_el_rate, {
                              key: 1,
                              max: 3,
                              modelValue: _ctx.activeValue,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.activeValue) = $event)),
                              onChange: _ctx.change,
                              clearable: ""
                            }, null, 8 /* PROPS */, ["modelValue", "onChange"]))
                          : (key.type === _ctx.TableKeyEnum.int)
                            ? (_openBlock(), _createBlock(_component_el_input_number, {
                                key: 2,
                                controls: false,
                                max: _ctx.POIParams[key.name]?.max || 9999,
                                min: _ctx.POIParams[key.name]?.min || 0,
                                onChange: _cache[8] || (_cache[8] = $event => (_ctx.changeInputNumber($event))),
                                modelValue: _ctx.activeValue,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.activeValue) = $event)),
                                onBlur: _ctx.change
                              }, null, 8 /* PROPS */, ["max", "min", "modelValue", "onBlur"]))
                            : (key.type === _ctx.TableKeyEnum.number)
                              ? (_openBlock(), _createBlock(_component_el_input_number, {
                                  key: 3,
                                  controls: false,
                                  "value-on-clear": null,
                                  max: _ctx.POIParams[key.name]?.max || 9999,
                                  min: _ctx.POIParams[key.name]?.min || 0,
                                  onChange: _cache[10] || (_cache[10] = $event => (_ctx.changeInputNumber($event))),
                                  modelValue: _ctx.activeValue,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.activeValue) = $event)),
                                  onBlur: _ctx.change
                                }, null, 8 /* PROPS */, ["max", "min", "modelValue", "onBlur"]))
                              : (key.type === _ctx.TableKeyEnum.soilIndex)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                    _createVNode(_component_el_input, {
                                      modelValue: _ctx.activeValue,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.activeValue) = $event)),
                                      onChange: _ctx.change
                                    }, null, 8 /* PROPS */, ["modelValue", "onChange"]),
                                    _createElementVNode("div", _hoisted_23, [
                                      _createVNode(_component_el_button, {
                                        onClick: _cache[13] || (_cache[13] = $event => (_ctx.addActiveValue('↑')))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("↑")
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _createElementVNode("div", _hoisted_24, [
                                      _createVNode(_component_el_button, {
                                        onClick: _cache[14] || (_cache[14] = $event => (_ctx.addActiveValue('↓')))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("↓")
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ])
                                  ]))
                                : (key.type === _ctx.TableKeyEnum.string)
                                  ? (_openBlock(), _createBlock(_component_el_input, {
                                      key: 5,
                                      type: "textarea",
                                      modelValue: _ctx.activeValue,
                                      "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.activeValue) = $event)),
                                      onBlur: _ctx.change
                                    }, null, 8 /* PROPS */, ["modelValue", "onBlur"]))
                                  : (key.type === _ctx.TableKeyEnum.select)
                                    ? (_openBlock(), _createBlock(_component_el_select, {
                                        key: 6,
                                        type: "textarea",
                                        modelValue: _ctx.activeValue,
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.activeValue) = $event)),
                                        onChange: _cache[17] || (_cache[17] = $event => (_ctx.change()))
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(key.params.selectValues, (item) => {
                                            return (_openBlock(), _createBlock(_component_el_option, {
                                              key: item.value,
                                              label: item.title || item.label,
                                              value: item.value
                                            }, null, 8 /* PROPS */, ["label", "value"]))
                                          }), 128 /* KEYED_FRAGMENT */))
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue"]))
                                    : _createCommentVNode("v-if", true)
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createCommentVNode(" is not active cell "),
                        (key.type === _ctx.TableKeyEnum.poiName)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("div", null, [
                                  _createVNode(_component_el_image, {
                                    class: "mr-8",
                                    style: {"width":"30px","height":"30px","border-radius":"5px"},
                                    src: `${row[key.name]?.photoList[0]}&size=70`,
                                    size: 30,
                                    "preview-src-list": row[key.name]?.photoList || undefined,
                                    "initial-index": 4,
                                    "preview-teleported": true,
                                    fit: "cover"
                                  }, {
                                    error: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_27, [
                                        _createVNode(_component_ui_icon, {
                                          name: "Picture",
                                          color: "#374957",
                                          size: 30
                                        })
                                      ])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src", "preview-src-list"])
                                ]),
                                _createElementVNode("span", null, _toDisplayString(row[key.name].name), 1 /* TEXT */)
                              ])
                            ]))
                          : (key.type === 'stars')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                _createVNode(_component_el_rate, {
                                  max: 3,
                                  modelValue: row[key.name],
                                  "onUpdate:modelValue": $event => ((row[key.name]) = $event),
                                  disabled: "",
                                  clearable: "",
                                  onChange: _cache[18] || (_cache[18] = $event => (_ctx.change()))
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                              ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                (['int','number'].includes(key.type) && String(row[key.name]) === '-1')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_29))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(row[key.name]), 1 /* TEXT */))
                              ], 64 /* STABLE_FRAGMENT */))
                      ], 64 /* STABLE_FRAGMENT */))
                ], 14 /* CLASS, STYLE, PROPS */, _hoisted_21))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_31, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedKeys, (key, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["POITable-container-footer-item", {stickFooter: key.lock }]),
              id: `${key.name}Footer`,
              style: _normalizeStyle({left: _ctx.shadowHeaderWidth(key.name)})
            }, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", {
                  innerHTML: _ctx.computedColumnValue(key)
                }, null, 8 /* PROPS */, _hoisted_34),
                (['int','number'].includes( key.type ))
                  ? (_openBlock(), _createBlock(_component_el_popover, {
                      key: 0,
                      width: 240,
                      trigger: "hover"
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_ui_icon, {
                          color: "#000000",
                          name: "CaretTop"
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "breakNone",
                          style: _normalizeStyle({cursor: 'pointer', color: key.columnMetrics === 0 ? '#0F8764': '#000'}),
                          onClick: $event => (key.columnMetrics = 0)
                        }, _toDisplayString(_ctx.$t('sum-data', { ns: 'ui-table' })), 13 /* TEXT, STYLE, PROPS */, _hoisted_35),
                        _createElementVNode("div", {
                          class: "breakNone",
                          style: _normalizeStyle({cursor: 'pointer', color: key.columnMetrics === 1? '#0F8764': '#000'}),
                          onClick: $event => (key.columnMetrics = 1)
                        }, _toDisplayString(_ctx.$t('average-data', { ns: 'ui-table' })), 13 /* TEXT, STYLE, PROPS */, _hoisted_36),
                        _createElementVNode("div", {
                          class: "breakNone",
                          style: _normalizeStyle({cursor: 'pointer', color: key.columnMetrics ===2 ? '#0F8764': '#000'}),
                          onClick: $event => (key.columnMetrics = 2)
                        }, _toDisplayString(_ctx.$t('max-data', { ns: 'ui-table' })), 13 /* TEXT, STYLE, PROPS */, _hoisted_37),
                        _createElementVNode("div", {
                          class: "breakNone",
                          style: _normalizeStyle({cursor: 'pointer', color: key.columnMetrics ===3 ? '#0F8764': '#000'}),
                          onClick: $event => (key.columnMetrics = 3)
                        }, _toDisplayString(_ctx.$t('min-data', { ns: 'ui-table' })), 13 /* TEXT, STYLE, PROPS */, _hoisted_38)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true)
              ])
            ], 14 /* CLASS, STYLE, PROPS */, _hoisted_32))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ]))
}