<template lang="pug">
.NavigationSeasonItem(
  :class="{ active: SeasonList.activeSeason.value.id === season.id }"
)
  .NavigationSeasonItem-grid
    .NavigationSeasonItem-name(
      @click="setSeason(season)"
    )
      .NavigationSeasonItem-season {{ season.name }}
      .NavigationSeasonItem-agroSeason {{ formatRuDate(season.startDate) }} &mdash; {{ formatRuDate(season.endDate) }}
    .NavigationSeasonItem-note
      el-popover(
        v-if="season.note && !isButton"
      )
        div {{ season.note }}
        template(#reference)
          ui-icon(name="mdiCommentOutline")
    .NavigationSeasonItem-edit
      el-tooltip(
        content="Редактировать сезон"
      )
        ui-icon(
          name="mdiSquareEditOutline"
          @click="$emit('season-select', season)"
          v-if="!isButton"
        )
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useBilling } from '@/composables/useBilling';
import { useUser } from '@/composables/useUser';
import SeasonList from '@/modules/season/SeasonList';
import { SeasonModel } from '@/models/season/SeasonModel';
import { formatRuDate } from '@/utils/formatRuDate';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import ApiService from '@/services/api/ApiService';
import StructList from '@/modules/struct/StructList';

export default defineComponent({
  name: 'NavigationSeasonItem',
  components: { UiIcon },
  props: {
    isButton: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Object as PropType<SeasonModel>,
      required: true,
    },
  },
  emit: ['season-select'],
  setup(props) {
    const { contract } = useBilling();
    const { user } = useUser();
    const {
      fitStruct,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const setSeason = (season: SeasonModel): void => {
      !props.isButton && SeasonList.setActiveSeason(season.id);
      setTimeout(() => {
        fitStruct({
          duration: 100,
          maxZoom: 14.5,
        });
      }, 1000);
      StructList.activeStruct.value.fetchStatistics(true);
    };

    return {
      formatRuDate,
      SeasonList,
      contract,
      user,
      setSeason,
    };
  },
});
</script>

<style lang="scss">
.NavigationSeasonItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 3px;
  padding: 10px;
  cursor: pointer;
  transition: var(--transition-default);
  &.active {
    color: var(--color-primary-500);
    background-color: var(--color-white-500);
  }
  &:hover {
    background-color: var(--color-white-600);
    color: var(--color-primary-500);
  }
  &-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 20px 20px;
    gap: 10px;
  }
  &-season {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    text-align: left;
  }

  &-agroSeason {
    font-size: 11px;
    text-align: left;
    line-height: 11px;
    opacity: 0.7;
  }
}
</style>
