import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_ArrowDown = _resolveComponent("ArrowDown")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["UiSelector", {active: _ctx.active}]),
    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggle && _ctx.toggle(...args))),
    onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.leave && _ctx.leave(...args))),
    onMouseenter: _cache[2] || (_cache[2] = (...args) => (_ctx.enter && _ctx.enter(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.selectValue.title || _ctx.selectValue.value), 1 /* TEXT */),
    ((!_ctx.selectValue.title && !_ctx.selectValue.value))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.placeholder), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_el_icon, null, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowDown)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["UiSelector-body", {active: _ctx.active}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsList, (options) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "UiSelector-item",
          onClick: $event => (_ctx.selectThis(options))
        }, _toDisplayString(options.title || options.value), 9 /* TEXT, PROPS */, _hoisted_2))
      }), 256 /* UNKEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 34 /* CLASS, NEED_HYDRATION */))
}