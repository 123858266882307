<script lang="ts">
import { computed, ref } from 'vue';
import { MapLayerTaskMapFactModel } from '@/models/map/Layers/MapLayerTaskMapFactModel';

export default {
  props: {
    layer: {
      type: MapLayerTaskMapFactModel,
      required: true,
    },
  },
  setup(props: { layer: MapLayerTaskMapFactModel }) {
    const hideChartDrops = ref(false);
    const chart = ref(null);

    const statsTable = computed(() => Object.entries(props.layer.data?.statistics?.targetStats ?? {}).map(([key, stats]) => {
      console.log(key, stats);
      return {
        key,
        // @ts-ignore
        actual: stats.actual,
        // @ts-ignore
        expected: stats.expected,
        // @ts-ignore
        area: stats.area,
      };
    }));

    const series = computed(() => {
      if (!props.layer.data?.geojson?.features) {
        return [];
      }

      const features = props.layer.data.geojson.features;
      const data = features
        .filter((feature) => {
          if (hideChartDrops.value) {
            return feature.properties.techniqueInput?.status === 'normal';
          }
          return true;
        })
        .map((feature) => ({
          x: feature.properties.techniqueInput?.value || 0,
          y: feature.properties.area_ga || 0,
        }))
        .sort((a, b) => a.x - b.x);

      return [{
        name: 'Площадь',
        data,
      }];
    });

    const chartOptions = computed(() => ({
      chart: {
        type: 'scatter',
        height: 350,
        zoom: {
          enabled: true,
          type: 'xy',
        },
      },
      xaxis: {
        title: {
          text: 'Норма внесения',
        },
      },
      yaxis: {
        title: {
          text: 'Площадь (га)',
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `${Math.round(val * 100) / 100} га`,
        },
        x: {
          formatter: (val) => `${Math.round(val * 100) / 100}`,
        },
      },
    }));

    return {
      statsTable,
      series,
      chartOptions,
      hideChartDrops,
      chart,
    };
  },
};
</script>

<template lang="pug">
.FactMapStatsWidget(v-if="layer.data?.statistics")
  .FactMapStatsWidget-content
    div {{ $t('fact-map-analyze.stats.area', {'ns': 'taskMap'}) }}: {{ layer.data.statistics.totalArea.toFixed(2) }} га.
    div {{ $t('fact-map-analyze.stats.count', {'ns': 'taskMap'}) }}: {{ layer.data.statistics.count }}
    div {{ $t('fact-map-analyze.stats.avgValuePerArea', {'ns': 'taskMap'}) }}: {{ Math.round(layer.data.statistics.avgValuePerArea * 100) / 100 }}
    br
    div {{ $t('fact-map-analyze.stats.skip.count', {'ns': 'taskMap'}) }}: {{ layer.data.statistics.statusCounts.skip }} ({{ Math.round(layer.data.statistics.statusCounts.skip * 100 / layer.data.statistics.count) }}%)
    div {{ $t('fact-map-analyze.stats.drop.count', {'ns': 'taskMap'}) }}: {{ layer.data.statistics.statusCounts.drop }} ({{ Math.round(layer.data.statistics.statusCounts.drop * 100 / layer.data.statistics.count) }}%)
    div {{ $t('fact-map-analyze.stats.normal.count', {'ns': 'taskMap'}) }}: {{ layer.data.statistics.statusCounts.normal }} ({{ Math.round(layer.data.statistics.statusCounts.normal * 100 / layer.data.statistics.count) }}%)
    div {{ $t('fact-map-analyze.stats.normal.area', {'ns': 'taskMap'}) }}: {{ layer.data.statistics.areaByStatus.normal.toFixed(2) }} га.

    h3.mt-15.mb-5 {{ $t('fact-map-analyze.stats.target.header', {'ns': 'taskMap'}) }}
    el-table(
      v-if="statsTable"
      :data="statsTable"
      style="width: 100%"
    )
      el-table-column(
        prop="key"
        :label="$t('fact-map-analyze.stats.table.key', {'ns': 'taskMap'})"
      )
      el-table-column(
        prop="expected"
        :label="$t('fact-map-analyze.stats.table.expected', {'ns': 'taskMap'})"
      )
        template(v-slot="scope") {{ Math.round(scope.row.expected * 100) / 100 }}
      el-table-column(
        prop="actual"
        :label="$t('fact-map-analyze.stats.table.actual', {'ns': 'taskMap'})"
      )
        template(v-slot="scope") {{ Math.round(scope.row.actual * 100) / 100 }}
      el-table-column(
        :label="$t('fact-map-analyze.stats.table.deflection', {'ns': 'taskMap'})"
      )
        template(v-slot="scope") {{ Math.round(scope.row.actual * 10000 / scope.row.expected) / 100 }}%
      el-table-column(
        :label="$t('fact-map-analyze.stats.table.area', {'ns': 'taskMap'})"
      )
        template(v-slot="scope") {{ Math.round(scope.row.area * 100) / 100 }} га.

</template>

<style lang="scss" scoped>
.FactMapStatsWidget {
  width: 100%;
  height: 100%;
  overflow: auto;

  &-content {
    padding: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }
}
</style>
