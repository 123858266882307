import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["width", "height", "x", "fill"]
const _hoisted_3 = { class: "UiChart-labels" }
const _hoisted_4 = { class: "text-x" }
const _hoisted_5 = { class: "text-x-right" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: "UiChart",
    style: _normalizeStyle({ height: `${_ctx.height + 50}px`})
  }, [
    (_openBlock(), _createElementBlock("svg", {
      class: "UiChart-body",
      viewBox: `0 0 100 ${_ctx.height}`,
      style: _normalizeStyle({ width: '100%', height: `${_ctx.height}px` }),
      preserveAspectRatio: "none"
    }, [
      _createElementVNode("g", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matrixChart, (count, idx) => {
          return (_openBlock(), _createElementBlock("rect", {
            width: 100 / _ctx.accuracy,
            height: `${Number(count) * 100 / _ctx.maxCount}%`,
            x: (idx * 100) / _ctx.accuracy,
            y: "0",
            fill: _ctx.reactivePaint(idx)
          }, null, 8 /* PROPS */, _hoisted_2))
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ], 12 /* STYLE, PROPS */, _hoisted_1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.labels[0].toFixed(2)), 1 /* TEXT */),
        _createElementVNode("span", null, _toDisplayString(_ctx.labels[1].toFixed(2)), 1 /* TEXT */),
        _createElementVNode("span", null, _toDisplayString(_ctx.labels[2].toFixed(2)), 1 /* TEXT */),
        _createElementVNode("span", null, _toDisplayString(_ctx.labels[3].toFixed(2)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.labels[4].toFixed(2)), 1 /* TEXT */)
      ])
    ])
  ], 4 /* STYLE */))
}