<template lang="pug">
.UniqValuesForm
  .flex-row.flex-row-justify-end.mb-6
    el-button(
      @click="addRow"
      icon="plus"
      type="sucsses"
    ) Добавить строку
  template(
    v-for="(row, idx) in rows"
  )
    el-form(
      inline
      label-position="top"
      :class="{dragElement: idx === draggingItemOrder }"
    )
      el-form-item(
        label="Цвет"
      )
        div
          color-picker(
            :format="'hex4'"
            disable-alpha
            v-model:pureColor="row.color"
          )
      el-form-item(
        label="Значение"
      )
        el-input(
          v-model="row.value"
        )

      el-form-item(
        label="Подпись"
        style="width: 330px;"
      )
        el-input(
          v-model="row.label"
        )

      .mt-24.mr-15
        el-popconfirm(
          title="Удалить палитру?"
          confirm-button-text="Удалить"
          confirm-button-type="danger"
          width="200"
          @confirm="rows.splice(idx, 1)"
        )
          template(#reference)
            el-button(
              circle
              type="danger"
            )
              ui-icon(name="mdiDeleteOutline")

      .dragHandler(
        @mouseenter="hoverIdx = idx"
        @mouseleave="hoverIdx = -1"
        :draggable="true"
        :data-idx="idx"
        @dragstart="dragStart(idx)"
        @dragend="dragEnd"
        @dragover="dragOver"
        style="cursor: grab"
        :class="{hover:idx === draggingItemOrder || hoverIdx === idx}"
      )
        ui-icon(name="mdiDrag")
  .mt-10.fw.flex.flex-row.flex-row-justify-between
    div
      el-button(
        @click="$emit('cancel')"
      ) {{ $t('cancel') }}

      el-popconfirm(
        title="Удалить текущий стандарт?"
        :confirm-button-text="$t('delete')"
        confirm-button-type="danger"
        width="200"
        @click="$emit('delete')"
      )
        template(#reference)
          el-button(
            type="danger"
          ) {{ $t('delete') }}

    el-button(
      @click="$emit('save',rows.map((a)=> ({value:a.value,label: a.label, color: a.color,})))"
      type="primary"
    ) {{ $t('save') }}
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { ColorPicker } from 'vue3-colorpicker';
import { UniqValuesType } from '@/constants/types/palette/UnifiedVectorPaletteType';

export default defineComponent({
  name: 'UniqValuesForm',
  components: { ColorPicker, UiDialog },
  props: {
    data: {
      type: Object as PropType<UniqValuesType[]>,
      required: true,
    },
  },
  emits: ['save', 'delete', 'cancel'],
  setup(props) {
    const rows = ref<(UniqValuesType & {order: number})[]>([]);

    props.data.forEach((v, i) => {
      rows.value.push({ ...JSON.parse(JSON.stringify(v)), order: i });
    });

    const hoverIdx = ref(-1);
    const addRow = () => {
      rows.value.forEach((a) => { a.order++; });
      rows.value.unshift({
        value: '',
        label: '',
        color: '#fff',
        order: 0,
      });
    };

    const moveItem = (fromIdx: number, toIdx: number) => {
      if (fromIdx === toIdx) return;
      if (toIdx > rows.value.length) toIdx = rows.value.length - 1;
      rows.value.splice(toIdx, 0, rows.value.splice(fromIdx, 1)[0]);
    };

    const draggingItemOrder = ref(-1);

    const dragStart = (index: number) => {
      draggingItemOrder.value = index;
      hoverIdx.value = -1;
    };

    const dragOver = (evt: DragEvent) => {
      const toIdx = Number((evt.target as HTMLElement).dataset?.idx);
      if (!Number.isNaN(toIdx) && draggingItemOrder.value !== toIdx) {
        moveItem(draggingItemOrder.value, toIdx);
        draggingItemOrder.value = toIdx;
      }
      evt.preventDefault();
    };

    const dragEnd = () => {
      draggingItemOrder.value = -1;
      let i = 1;
      rows.value.forEach((item) => { item.order = i++; });
    };

    return {
      rows,
      addRow,
      dragEnd,
      dragOver,
      dragStart,
      draggingItemOrder,
      hoverIdx,
    };
  },
});
</script>

<style lang="scss">
.UniqValuesForm {
  .dragHandler{
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.hover{
      background: #00000030;
    }
  }
  .dragElement{
    background: #00000010;
  }
  .el-form--inline.el-form--label-top {
    flex-wrap: nowrap;
  }
}
</style>
