<template lang="pug">
.SidePanel(
  :style="{ width: `${width + 40}px` }"
  :class="{ [`--reverse`]: isCompare }"
)
  .SidePanel-shuffle(
    @mousedown="panelResizeStart"
    v-show="activePanel !== 'none'"
  )
    .SidePanel-shuffle-icon

  .SidePanel-content(
    v-show="activePanel !== 'none'"
  )
    .SidePanel-content-panel(
      :style="{ width: `${width - 4}px` }"
      v-for="panel in panels"
      :class="{ active: activePanel === panel.name }"
    )
      component(
        v-if="!isCompare || panel.compareAble"
        :is="panel.component"
        :map-container="mapContainer"
        :is-compare="isCompare"
        :options="panel.options"
        :active="activePanel === panel.name"
        @close="closePanel"
      )
    .SidePanel-content-panel(
      :style="{ width: `${width - 4}px` ,zIndex: 2}"
      :class="{ active: informationMode !== 'none' }"
    )
      InfoPanel(
        :map-container="mapContainer"
      )
  .SidePanel-navigation
    template(
      v-for="panel in filteredPanels"
    )
      el-tooltip(
        :content="$t(panel.tooltip, { ns: 'side-panel' })"
        placement="left"
        :show-after="200"
      )
        .SidePanel-navigation-button(
          @click="toggleSide(panel.name)"
          :class="{ active: activePanel === panel.name }"
        )
          ui-icon(
            :name="panel.icon"
            color="currentColor"
            :size="32"
        )
</template>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskTypeEnum } from '@/constants/enums/TaskTypeEnum';
import FieldsPanel from '@/modules/map/container/SidePanel/FieldsPanel/FieldsPanel.vue';
import ImagesPanel from '@/modules/map/container/SidePanel/ImagesPanel/ImagesPanel.vue';
import InfoPanel from '@/modules/map/container/SidePanel/InfoPanel/InfoPanel.vue';
import MonitoringPanel from '@/modules/map/container/SidePanel/MonitoringPanel/MonitoringPanel.vue';
import PoisPanel from '@/modules/map/container/SidePanel/PoisPanel/PoisPanel.vue';
import TaskMapPanel from '@/modules/map/container/SidePanel/TaskMapPanel/TaskMapPanel.vue';
import {
  computed, defineComponent, PropType, ref, watch,
} from 'vue';
import PermissionsList from '@/modules/permissions/PermissionsList';

type SidePanelType = 'none' | 'fields' | 'poi' | 'images' | 'task-map' | 'fact-task-map' | 'monitoring' | 'info';

const panels: {
  name: SidePanelType,
  component: any,
  options: Record<string, any>,
  tooltip: string,
  icon: string,
  width: number,
  compareAble: boolean,
}[] = [
  {
    name: 'fields',
    tooltip: 'fields-contour',
    component: FieldsPanel,
    options: {},
    icon: '/images/icons/fields.svg',
    width: 300,
    compareAble: true,
  },
  {
    name: 'poi',
    tooltip: 'pois',
    component: PoisPanel,
    options: {},
    icon: '/images/icons/poi.svg',
    width: 370,
    compareAble: true,
  },
  {
    name: 'images',
    tooltip: 'images-panel',
    component: ImagesPanel,
    options: {},
    icon: 'mdiLayersOutline',
    width: 300,
    compareAble: false,
  },
  {
    name: 'fact-task-map',
    tooltip: 'task-map-type-fact-plurar',
    component: TaskMapPanel,
    options: {
      tab: TaskTypeEnum.FACT,
    },
    icon: '/images/icons/fact-map.svg',
    width: 300,
    compareAble: true,
  },
  {
    name: 'task-map',
    tooltip: 'task-map-plurar',
    component: TaskMapPanel,
    options: {
      tab: TaskTypeEnum.WORK,
    },
    icon: '/images/icons/work-map.svg',
    width: 300,
    compareAble: true,
  },
  {
    name: 'monitoring',
    tooltip: 'monitoring',
    component: MonitoringPanel,
    options: {},
    icon: '/images/icons/monitoring.svg',
    width: 350,
    compareAble: true,
  },
];

export default defineComponent({
  name: 'SidePanel',
  components: { InfoPanel, UiIcon },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const activePanel = ref<SidePanelType | undefined>(window.document.body.offsetWidth > 768 ? 'fields' : 'none');

    const {
      sidePanel,
      informationMode,
    } = useMapContainers(props.mapContainer);

    const toggleSide = (panel: SidePanelType) => {
      if (activePanel.value === panel) {
        activePanel.value = 'none';
      } else {
        activePanel.value = panel;
        const panelWidth = panels.find((a) => a.name === panel)?.width;
        sidePanel.value.width = panelWidth;
      }
    };

    const width = computed(() => (activePanel.value === 'none' ? 0 : sidePanel.value.width));

    const filteredPanels = computed(() => panels.filter((v) => {
      if (v.name === 'monitoring' && !PermissionsList.hasPermission('monitoringconfiguration', 'view')) {
        return false;
      }
      if (props.isCompare && v.name === 'images') {
        return false;
      }
      return true;
    }));

    watch([informationMode, activePanel], (a, b) => {
      if (!props.isCompare) {
        const [newInfo, newPanel] = a;
        const [oldInfo, oldPanel] = b;

        if (newInfo !== 'none' && newPanel === 'none') {
          activePanel.value = 'fields';
        } else if (newPanel !== 'none' && oldPanel !== 'none' && newPanel !== oldPanel) {
          informationMode.value = 'none';
        }
      }
    });

    const closePanel = () => {
      activePanel.value = 'none';
    };
    // region Side panel shuffle
    const panelResize = {
      active: false,
      startX: 0,
      startWidth: sidePanel.value.width,
    };
    const panelResizeStart = (evt: MouseEvent) => {
      panelResize.startX = evt.clientX;
      panelResize.startWidth = sidePanel.value.width;
      panelResize.active = true;
    };
    window.addEventListener('mousemove', (evt: MouseEvent) => {
      if (panelResize.active) {
        sidePanel.value.width = Math.max(Math.min(panelResize.startX - evt.clientX + panelResize.startWidth, 600), 250);
        evt.stopImmediatePropagation();
        evt.preventDefault();
      }
    });
    window.addEventListener('mouseup', () => {
      if (panelResize.active) {
        panelResize.active = false;
      }
    });

    // endregion

    return {
      activePanel,
      panels,
      toggleSide,
      TaskTypeEnum,
      width,
      panelResizeStart,
      filteredPanels,
      informationMode,
      closePanel,

    };
  },
});
</script>

<style lang="scss" scoped>
.SidePanel {
  position: relative;
  height: calc(100vh - 80px);

  @media screen and (max-width: $bp-m) {
    height: calc(100vh - 50px);
  }
  display: flex;
  flex-direction: row;
  &.--reverse {
    flex-direction: row-reverse;
  }
  &-shuffle {
    height: 100%;
    width: 4px;
    z-index: 10;
    background: var(--color-white-600);
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    cursor: ew-resize;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
    &:hover {
      background: var(--color-white-500);
    }
    &-icon {
      width: 2px;
      background: var(--color-white-700);
      height: 10vh;
    }
  }
  &-content {
    position: relative;
    z-index: 1;
  }
  &-content {
    background: #FFF;
    height: 100%;
    width: 100%;
    min-height: 100%;
    position: relative;
    box-shadow: inset 4px 0 4px #E0E0E0;
    &-panel {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      height: 100%;
      &.active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &-navigation {
    width: 40px;
    height: calc(100% - 30px);
    padding: 15px 0;
    background: var(--color-white-600);
    box-shadow: inset 2px -2px 2px #DDD;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;
    &-button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-black-300);
      cursor: pointer;
      transition: var(--transition-default);
      &.active {
        background: var(--color-white-500);
        color: var(--color-primary-500);
      }
      &:hover:not(.disabled):not(.active) {
        color: var(--color-black-400);
        background: rgba(255, 255, 255, 0.5);
      }
      &.disabled {
        cursor: default;
        color: var(--color-black-100);
        background: var(--color-white-600);
      }
    }
  }
}
</style>
