<template lang="pug">
.FieldView(
  :data-field="field?.id"
)
  .FieldView_polygon(
    :class="classList"
  )
    svg.field-preview(
      :viewBox="`0 0 ${width} ${height}`"
      :width="width"
      :height="height"
      :class="classList"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    )
      g(
        v-if="fields.length"
        :transform-origin="`${width/2} ${height/2}`"
      )
        g(v-for="(field, index) in fields" :key="index")
          polygon(
            v-for="(polygon, pIndex) in field"
            :key="pIndex"
            :points="polygon"
            class="field-polygon"
          )
      text.error-text(
        v-else
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
      ) No data
</template>
<style lang="scss" src="./FieldView.scss"/>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { FieldModel } from '@/models/field/FieldModel';
import { UiSizesType } from '@/constants/types/UiSizesType';
import { FieldPreviewModel } from '@/models/field/FieldPreviewModel';

const sizes = {
  xs: 16,
  sm: 24,
  m: 32,
  lg: 42,
  xl: 72,
  xxl: 92,
};

export default defineComponent({
  props: {
    field: {
      type: Object as PropType<FieldModel>,
      required: true,
    },
    size: {
      type: String as PropType<UiSizesType>,
      default: 'm',
    },
    active: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Object as PropType<FieldPreviewModel>,
    },
  },
  setup(props) {
    const svgData = computed(() => (props.preview !== undefined ? props.preview.getSvgData() : props.field.preview.getSvgData() || {
      polygons: [],
      viewBox: '0 0 0 0',
      width: 0,
      height: 0,
    }));

    const classList = computed(() => ({
      [`--size-${props.size}`]: true,
      '--active': props.active,
    }));

    return {
      classList,
      viewbox: computed(() => svgData.value.viewBox),
      fields: computed(() => svgData.value.polygons),
      width: computed(() => svgData.value.width),
      height: computed(() => svgData.value.height),
    };
  },
});
</script>
