import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "SelectNirAndIndex" }
const _hoisted_2 = { class: "SelectNirAndIndex-block" }
const _hoisted_3 = { class: "SelectNirAndIndex-nir" }
const _hoisted_4 = { class: "SelectNirAndIndex-nir-image" }
const _hoisted_5 = { class: "SelectNirAndIndex-nir-props" }
const _hoisted_6 = { class: "mb-1 fs-12" }
const _hoisted_7 = { class: "pl-5" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "SelectNirAndIndex-indexes" }
const _hoisted_10 = { class: "SelectNirAndIndex-indexes-items" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "SelectNirAndIndex-indexes-items" }
const _hoisted_13 = {
  key: 0,
  class: "p-20"
}
const _hoisted_14 = { class: "form-header mb-2" }
const _hoisted_15 = { class: "flex-row flex-row-align-center" }
const _hoisted_16 = { class: "mr-7" }
const _hoisted_17 = { class: "SelectNirAndIndex-indexes-files" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "SelectNirAndIndex-indexes-file-caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_IndexesInfo = _resolveComponent("IndexesInfo")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_NotFound = _resolveComponent("NotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.selectedFile && _ctx.activeField)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_image, {
                    src: _ctx.selectedFile?.thumbnailUrl,
                    "preview-src-list": [_ctx.selectedFile?.url],
                    "preview-teleported": true
                  }, null, 8 /* PROPS */, ["src", "preview-src-list"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('monitoring-select-nir-file-title', { ns: 'monitoring' })), 1 /* TEXT */),
                    _createVNode(_component_el_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSelectFile = !_ctx.showSelectFile)),
                      class: _normalizeClass({ active: _ctx.showSelectFile })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ui_icon, {
                          name: "mdiCalendarMonthOutline",
                          color: "inherit"
                        }),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formatRuDate(_ctx.selectedFile?.scene.datetime.toString()) || ''), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ]),
                  _createVNode(_component_el_checkbox, {
                    modelValue: _ctx.isShowPicture,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShowPicture) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('monitoring-show-image-checkbox', { ns: 'monitoring' })), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_el_select, {
                    placeholder: _ctx.$t('task_name_placeholder',{ns:'taskMap'}),
                    modelValue: _ctx.activeIndex,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeIndex) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectIndexTypeOptions, (opt) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          value: opt.value,
                          label: opt.label
                        }, null, 8 /* PROPS */, ["value", "label"]))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["placeholder", "modelValue"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indexesKinds?.groups || [], (group) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.indexesKinds?.kinds || []).filter((k) => k.groupId === group.id && _ctx.activeIndex  === k.value), (kind) => {
                        return (_openBlock(), _createBlock(_component_el_tooltip, {
                          placement: "top",
                          "show-after": 500
                        }, {
                          content: _withCtx(() => [
                            _createVNode(_component_IndexesInfo, {
                              index: kind.name
                            }, null, 8 /* PROPS */, ["index"])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(["MapMonitoringMenu-indexes-item", { active: _ctx.selectedIndex && _ctx.selectedIndex!== 'task-vui' &&_ctx.selectedIndex.name === kind.name }]),
                              onClick: ($event: any) => (_ctx.indexMenuItemClicked(kind))
                            }, _toDisplayString(kind.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_11)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    ]))
                  }), 256 /* UNKEYED_FRAGMENT */)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["SelectNirAndIndex-indexes-item", { active: _ctx.isSelectTask }]),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.indexMenuItemClicked('task-view')))
                    }, _toDisplayString(_ctx.$t('task-layer',{ns:'taskMap'})), 3 /* TEXT, CLASS */)
                  ])
                ])
              ])
            ]),
            (_ctx.isSelectTask)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('opacity')), 1 /* TEXT */)
                    ])
                  ]),
                  _createVNode(_component_el_slider, {
                    modelValue: _ctx.opacity,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.opacity) = $event)),
                    step: 5,
                    min: 0,
                    max: 100,
                    marks: _ctx.SliderMarks0to100
                  }, null, 8 /* PROPS */, ["modelValue", "marks"])
                ]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createBlock(_component_NotFound, {
            key: 1,
            message: _ctx.$t('monitoring-not-found-message', { ns: 'monitoring' })
          }, null, 8 /* PROPS */, ["message"]))
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        class: _normalizeClass(["SelectNirAndIndex-indexes-selectFile", { active: _ctx.showSelectFile}])
      }, [
        _createElementVNode("div", {
          class: "SelectNirAndIndex-indexes-selectFile-back",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showSelectFile = false))
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["SelectNirAndIndex-indexes-selectFile-wrapper", { active: _ctx.showSelectFile}])
        }, [
          _createElementVNode("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeField?.nirFiles, (file) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["SelectNirAndIndex-indexes-file", { active: _ctx.selectedFile?.id === file.id }]),
                onClick: ($event: any) => {_ctx.selectedFile = file; _ctx.showSelectFile = false;}
              }, [
                _createElementVNode("img", {
                  src: file.thumbnailUrl
                }, null, 8 /* PROPS */, _hoisted_19),
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.formatRuDate(file.scene.datetime.toString())), 1 /* TEXT */)
              ], 10 /* CLASS, PROPS */, _hoisted_18))
            }), 256 /* UNKEYED_FRAGMENT */))
          ])
        ], 2 /* CLASS */)
      ], 2 /* CLASS */)
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}