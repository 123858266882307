import { SatelliteRgbFileDto } from '@/services/api/dto/satellites/SatelliteRgbFileDto';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import type { FieldModel } from '@/models/field/FieldModel';
import { FieldEstimationDto } from '@/services/api/dto/satellites/FieldEstimationDto';
import { SatelliteEstimationModel } from '@/models/satellite/SatelliteEstimationModel';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { SocketMessageType } from '@/constants/types/SocketMessageType';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';
import LoggerService from '@/services/logger/LoggerService';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { SatelliteIndexFileType } from '@/constants/types/satellite/SatelliteIndexFileType';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { computed } from 'vue';

export class SatelliteFileModel {
  get activeIndexFile(): SatelliteIndexFileType | undefined {
    return this._activeIndexFile;
  }

  get indicesFiles(): { [key in FileIndexKindEnums]?: SatelliteIndexFileType } {
    return this._indicesFiles;
  }

  get cloudiness(): number {
    return this._cloudiness;
  }

  get combinedCount(): number {
    return this._combinedCount;
  }

  get nir(): FieldNirModel {
    return this._nir;
  }

  get estimation(): SatelliteEstimationModel {
    return this._estimation;
  }

  get field(): FieldModel {
    return this._field;
  }

  private readonly _nir: FieldNirModel;

  private readonly _estimation: SatelliteEstimationModel;

  private readonly _field: FieldModel;

  private readonly _combinedCount: number;

  private readonly _cloudiness: number;

  private _activeIndexFile: SatelliteIndexFileType | undefined;

  private _indicesFiles: { [key in FileIndexKindEnums]?: SatelliteIndexFileType} = {};

  constructor(rgbDto: SatelliteRgbFileDto, estimationDto: FieldEstimationDto, field: FieldModel) {
    this._field = field;
    this._nir = new FieldNirModel(rgbDto, field.id);
    this._estimation = new SatelliteEstimationModel(estimationDto);
    this._combinedCount = rgbDto.combined_count;
    this._cloudiness = rgbDto.cloudiness;
  }

  async fetchIndexFile(index: FileIndexKindEnums): Promise<void> {
    const { data } = await ApiService.satellites.getIndexFile(this.field.id, this._nir.scene.id, index);
    this._indicesFiles[index] = {
      file: data.length > 0 ? new FieldIndexMonitoringModel(data[0]) : undefined,
      fieldId: this.field.id,
      scene: this._nir.scene.id,
      kind: index,
    };
  }

  setActiveIndexFile(indexFile: SatelliteIndexFileType | undefined = undefined): void {
    this._activeIndexFile = indexFile;
    if (indexFile?.file) {
      useMapContainers(MapContainerEnum.MAIN_MAP).mapModel.value?.render(indexFile.file);
    } else {
      useMapContainers(MapContainerEnum.MAIN_MAP).mapModel.value?.removeLayer(MapLayerTypeEnum.MONITORING_INDEX);
    }
  }

  /**
   * Вызывает генерацию указанного индекса. Возвращает Promise, когда сервер будет готов отобразить индекс.
   * @param index
   */
  // eslint-disable-next-line consistent-return
  async callIndex(index: FileIndexKindEnums) {
    if (index === FileIndexKindEnums.NDVI) {
      this._field.animateLoading = true;
      if (!this._indicesFiles[index]) {
        await this.fetchIndexFile(index);
      }
      if (!this._indicesFiles[index]?.file) {
        this._field.animateLoading = true;
        const { data } = await ApiService.satellites.generateNdviFile(this._field.id, this._nir.scene.id);

        let done = false;

        const onSocketMessage = (message: SocketMessageType) => {
          if (message.event === 'SATELLITE_TASK_DONE' && message.payload.process_id === data.process_id) {
            this._field.animateLoading = false;
            this.fetchIndexFile(index);
            done = true;
            EventBus.$off(EventsEnum.SocketMessage, onSocketMessage);
          }
        };
        EventBus.$on(EventsEnum.SocketMessage, onSocketMessage);
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (done) {
              clearInterval(interval);
              resolve(true);
            }
          }, 100);
        });
      }
      this._field.animateLoading = true;
      return this._indicesFiles[index];
    }
    LoggerService.error(`Try to call not implemented yet index: ${index}`);
  }
}
