import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "SatelliteLeftBlock" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_SatelliteEstimationBlock = _resolveComponent("SatelliteEstimationBlock")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_Content = _resolveComponent("Content")

  return (_openBlock(), _createBlock(_component_Content, { title: "Спутниковые снимки" }, {
    footer: _withCtx(() => [
      (_ctx.selectMode !== 2)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            effect: "dark",
            placement: "top",
            "raw-content": true,
            content: _ctx.selectMode !== 1 ? _ctx.$t('downloadRgbBtnTooltip1') : _ctx.$t('downloadRgbBtnTooltip2')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: _ctx.searchMode,
                type: _ctx.selectMode === 1 ? 'primary' : 'default',
                disabled: _ctx.selectMode === 1 && _ctx.selectedFields.length === 0
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Поиск снимков")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick", "type", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectMode !== 1)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            effect: "dark",
            placement: "top",
            "raw-content": true,
            content: _ctx.selectMode !== 1 ? _ctx.$t('calculateIndexesBtnTooltip1') : _ctx.$t('calculateIndexesBtnTooltip2')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: _ctx.indexesMode,
                type: _ctx.selectMode === 2 ? 'primary' : 'default',
                disabled: _ctx.selectMode === 2 && _ctx.selectedFields.length === 0
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Расчет индексов")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick", "type", "disabled"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["content"]))
        : _createCommentVNode("v-if", true),
      (_ctx.selectMode !== 0)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 2,
            size: "small",
            onClick: _ctx.unsetMode
          }, {
            default: _withCtx(() => [
              _createTextVNode("Отмена")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.activeField === undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "выберите поле"))
          : (_openBlock(), _createBlock(_component_SatelliteEstimationBlock, { key: 1 })),
        _createCommentVNode("el-input("),
        _createCommentVNode("  v-model=\"searchRow\""),
        _createCommentVNode("  class=\"w-50 m-2\""),
        _createCommentVNode("  placeholder=\"Поиск\""),
        _createCommentVNode("  :prefix-icon=\"Search\""),
        _createCommentVNode(")"),
        _createCommentVNode("  el-button-group("),
        _createCommentVNode("    v-if=\"selectMode !== 0\""),
        _createCommentVNode("  )"),
        _createCommentVNode("    el-tooltip("),
        _createCommentVNode("      class=\"box-item\""),
        _createCommentVNode("      effect=\"dark\""),
        _createCommentVNode("      content=\"Убрать выбор со всех полей\""),
        _createCommentVNode("    )"),
        _createCommentVNode("      el-button("),
        _createCommentVNode("        :icon=\"SemiSelect\""),
        _createCommentVNode("        @click=\"removeFields()\""),
        _createCommentVNode("        round"),
        _createCommentVNode("      )"),
        _createCommentVNode("    el-tooltip("),
        _createCommentVNode("      class=\"box-item\""),
        _createCommentVNode("      effect=\"dark\""),
        _createCommentVNode("      content=\"Выбрать все поля\""),
        _createCommentVNode("    )"),
        _createCommentVNode("      el-button("),
        _createCommentVNode("        :icon=\"Select\""),
        _createCommentVNode("        @click=\"selectAllFields()\""),
        _createCommentVNode("        round"),
        _createCommentVNode("      )"),
        _createCommentVNode(".SatelliteLeftBlock_stopMessage("),
        _createCommentVNode("  v-if=\"fields.length === 0\""),
        _createCommentVNode(") Подразделение не имеет полей"),
        _createCommentVNode("template("),
        _createCommentVNode("  v-else"),
        _createCommentVNode(")"),
        _createCommentVNode("  FieldsSelector("),
        _createCommentVNode("    :fields=\"fields\""),
        _createCommentVNode("    :show-area=\"false\""),
        _createCommentVNode("    :select-able=\"selectMode !== 0\""),
        _createCommentVNode("    :active-able=\"selectMode === 0\""),
        _createCommentVNode("    :search-row=\"searchRow\""),
        _createCommentVNode("    :disabled-fields=\"computedDisabledFields\""),
        _createCommentVNode("    size=\"m\""),
        _createCommentVNode("  )"),
        _createCommentVNode("    template("),
        _createCommentVNode("      #notice=\"slotProps\""),
        _createCommentVNode("      slot-scope=\"slotProps\""),
        _createCommentVNode("    )"),
        _createCommentVNode("      .SatelliteLeftBlock_info("),
        _createCommentVNode("        :data-slot=\"JSON.stringify(slotProps?.feature)\""),
        _createCommentVNode("      )"),
        _createCommentVNode("        .SatelliteProgressBlock_info-loading("),
        _createCommentVNode("          v-if=\"!slotProps?.field?.counter\""),
        _createCommentVNode("        )"),
        _createCommentVNode("          el-skeleton("),
        _createCommentVNode("            :rows=\"0\""),
        _createCommentVNode("            animated"),
        _createCommentVNode("          )"),
        _createCommentVNode("        .SatelliteLeftBlock_info-progress("),
        _createCommentVNode("          v-else-if=\"[SatelliteCounterStatusEnums.PROGRESS, SatelliteCounterStatusEnums.QUEUE].includes(slotProps?.field?.counter?.status.progress)\""),
        _createCommentVNode("        )"),
        _createCommentVNode("          .SatelliteLeftBlock_info-progress-bar("),
        _createCommentVNode("            v-if=\"slotProps?.field?.counter?.timers?.started_at && slotProps?.field?.counter?.timers?.finish_at\""),
        _createCommentVNode("          )"),
        _createCommentVNode("            el-progress("),
        _createCommentVNode("              :text-inside=\"true\""),
        _createCommentVNode("              :stroke-width=\"16\""),
        _createCommentVNode("              :percentage=\"timerPercentage(slotProps?.field?.counter?.timers)\""),
        _createCommentVNode("            )"),
        _createCommentVNode("            .SatelliteLeftBlock_info-progress-bar-message {{ formatSecondsToTime(timerLeft(slotProps?.field?.counter?.timers)) }}"),
        _createCommentVNode("          .SatelliteLeftBlock_info-progress-info("),
        _createCommentVNode("            v-else"),
        _createCommentVNode("          )"),
        _createCommentVNode("            img("),
        _createCommentVNode("              src=\"@/assets/images/loading.svg\""),
        _createCommentVNode("              width=\"20\""),
        _createCommentVNode("            )"),
        _createCommentVNode(""),
        _createCommentVNode("            .SatelliteLeftBlock_info-progress-info-message {{ computedProgressTitle(slotProps.field) }}"),
        _createCommentVNode(""),
        _createCommentVNode("        .SatelliteLeftBlock_info-tags("),
        _createCommentVNode("          v-else-if=\"slotProps?.field\""),
        _createCommentVNode("        )"),
        _createCommentVNode("          .SatelliteLeftBlock_info-tag.counter("),
        _createCommentVNode("            :class=\"counterClass(slotProps?.field)\""),
        _createCommentVNode("          ) {{ slotProps?.field?.counter?.completed_amount || 0 }} / {{ slotProps?.field?.counter?.known_amount || 0 }}"),
        _createCommentVNode(""),
        _createCommentVNode("    template("),
        _createCommentVNode("      #icon=\"slotProps\""),
        _createCommentVNode("      slot-scope=\"slotProps\""),
        _createCommentVNode("      v-if=\"slotProps?.field?.counter?.status?.progress === 30\""),
        _createCommentVNode("    )"),
        _createCommentVNode("      .SatelliteLeftBlock_icon"),
        _createCommentVNode("        Warning("),
        _createCommentVNode("          color=\"#FF2828\""),
        _createCommentVNode("        )")
      ])
    ]),
    _: 1 /* STABLE */
  }))
}