<template src="./NavigationStructUnits.pug" lang="pug"/>
<style src="./NavigationStructUnits.scss" lang="scss"/>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import { useBilling } from '@/composables/useBilling';
import StructList from '@/modules/struct/StructList';
import { StructModel } from '@/models/struct/StructModel';
import { useTolgee } from '@tolgee/vue';
import { Tree } from 'element-plus/lib/components/tree-v2/src/types';
import { ElTree } from 'element-plus';
import { useUser } from '@/composables/useUser';

export default defineComponent({
  name: 'NavigationStructUnits',

  setup() {
    const { contract } = useBilling();
    const { user } = useUser();

    const searchStruct = ref('');

    const drawer = ref(false);
    const treeRef = ref<InstanceType<typeof ElTree>>();

    const filterTags = ref<number[]>([]);

    const confirmStructChange = ref({
      active: false,
      value: null,
    });

    const filterNode = (value: [string, number[]], data: Tree & { label: string, tags: number[] }) => {
      if (!value[0] && value[1] === undefined) return true;

      const labelMatches = value[0]
        ? data.label.toLowerCase().includes(value[0].toLowerCase())
        : true;

      const tagMatches = value[1].length > 0
        ? value[1].some((v) => data.tags.includes(v))
        : true;

      return labelMatches && tagMatches;
    };

    const allTags = computed(() => {
      const uniqueTags = new Map();

      StructList.structs.value.forEach((struct) => {
        struct.tags.forEach((tag) => {
          if (!uniqueTags.has(tag.id)) {
            uniqueTags.set(tag.id, tag);
          }
        });
      });
      console.log(Array.from(uniqueTags.values()));
      return Array.from(uniqueTags.values());
    });

    watch([searchStruct, filterTags], (a) => {
      console.log('call');
      treeRef.value!.filter(a);
    });

    const structTree = computed(() => {
      const rootItems = [];
      const lookup = {};

      // Создаем хэш-таблицу для быстрого доступа к узлам
      for (let i = 0; i < StructList.structs.value.length; i++) {
        const item = StructList.structs.value[i];
        const { id, name, tags } = item;
        lookup[id] = {
          id, label: name, children: [], tags: tags.map((a) => a.id),
        };
      }

      // Строим дерево
      for (let i = 0; i < StructList.structs.value.length; i++) {
        const item = StructList.structs.value[i];
        const { id, parent } = item;
        const treeItem = lookup[id];

        if (parent === null) {
          // Если у элемента нет родителя, добавляем его в корневые элементы
          rootItems.push(treeItem);
        } else {
          // Если у элемента есть родитель, добавляем его в список детей родителя
          lookup[parent].children.push(treeItem);
        }
      }
      return rootItems;
    });

    const structTreeProps = {
      children: 'children',
      label: 'label',
    };

    const selectUnit = async (unit: StructModel) => {
      if (unit.isSelectable) {
        window.location.href = `/app/struct/${unit.id}`;
      }
    };

    const title = computed(() => {
      if (StructList.activeStruct.value) {
        return `<span>${StructList.structPath(StructList.activeStruct.value).join('</span><span>')}</span>`;
      }
      return useTolgee().value?.t('struct-is-not-selected', { ns: 'navigation' });
    });

    const goToStructConfirmation = (node: { id: number, label: string }) => {
      const unit = StructList.structs.value.find((s) => s.id === node.id);
      if (unit?.isSelectable) {
        confirmStructChange.value.value = node;
        confirmStructChange.value.active = true;
      }
    };

    const goToStruct = (node: { id: number, label: string }) => {
      const unit = StructList.structs.value.find((s) => s.id === node.id);
      if (unit?.isSelectable) {
        window.location.href = `/app/struct/${node.id}`;
      }
    };

    return {
      searchStruct,
      StructList,
      selectUnit,
      title,
      contract,
      structTree,
      structTreeProps,
      filterNode,
      goToStruct,
      goToStructConfirmation,
      confirmStructChange,
      allTags,
      drawer,
      treeRef,
      filterTags,
      user,
    };
  },
});
</script>
