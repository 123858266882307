<template lang="pug">
el-color-picker(
  :color-format="colorFormat"
  :show-alpha="showAlpha"
  :model-value="color"
  @active-change="onChange"
  @close="onBlur"
)
</template>
<script  lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';

export default defineComponent({
  name: 'UiColorPicker',
  components: { UiIcon },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    colorFormat: {
      type: String as PropType<'hex' | 'rgb'>,
      required: true,
    },
    showAlpha: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const color = ref(props.modelValue);
    const onChange = (value: string) => {
      color.value = value;
      // emit('update:modelValue', value);
    };
    const onBlur = () => {
      emit('update:modelValue', color.value);
    };
    return {
      color,
      onChange,
      onBlur,
    };
  },
});
</script>

<style lang="scss">
</style>
