import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  class: "mt-10 fw"
}
const _hoisted_5 = { class: "flex-row flex-align-center flex-row-justify-between fw" }
const _hoisted_6 = {
  key: 0,
  class: "POIEditBlock_Visited"
}
const _hoisted_7 = { class: "POIEditBlock" }
const _hoisted_8 = { class: "POIEditBlock_Body" }
const _hoisted_9 = { class: "flex-row flex-row-justify-start flex-align-center mt-16" }
const _hoisted_10 = {
  key: 0,
  class: "mb-15"
}
const _hoisted_11 = { class: "form-header" }
const _hoisted_12 = { class: "flex-row flex-align-center flex-row-justify-between fw" }
const _hoisted_13 = { class: "form-dl" }
const _hoisted_14 = { class: "form-header mt-15" }
const _hoisted_15 = { class: "form-dl" }
const _hoisted_16 = { class: "form-header mt-16" }
const _hoisted_17 = { class: "POIEditBlock_BodyIMGlist" }
const _hoisted_18 = { class: "POIEditBlock_BodyIMGlist-item" }
const _hoisted_19 = { class: "image-slot" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 2,
  class: "form-header mb-8"
}
const _hoisted_22 = {
  key: 0,
  style: {"font-weight":"400","color":"#999","margin-left":"0.5rem","white-space":"nowrap"}
}
const _hoisted_23 = {
  key: 3,
  class: "flex-row fw flex-align-center"
}
const _hoisted_24 = { class: "ml-6" }
const _hoisted_25 = { class: "ml-4" }
const _hoisted_26 = { class: "POIEditBlock_Footer" }

export function render(_ctx, _cache) {
  const _component_EditPen = _resolveComponent("EditPen")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_Place = _resolveComponent("Place")
  const _component_Delete = _resolveComponent("Delete")
  const _component_el_button_group = _resolveComponent("el-button-group")
  const _component_el_alert = _resolveComponent("el-alert")
  const _component_Picture = _resolveComponent("Picture")
  const _component_el_image = _resolveComponent("el-image")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_rate = _resolveComponent("el-rate")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_input_number = _resolveComponent("el-input-number")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_el_form = _resolveComponent("el-form")
  const _component_RightPanel = _resolveComponent("RightPanel")

  return (_openBlock(), _createBlock(_component_RightPanel, { closeable: false }, {
    title: _withCtx(() => [
      (!_ctx.active && !_ctx.poiMoveMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("v-if", true),
      (_ctx.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(`${'edit-poi'}`, 'edit-poi', { ns: 'poi' })), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.poiMoveMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(`${'move-poi'}`, 'move-poi', { ns: 'poi' })), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    actions: _withCtx(() => [
      (!_ctx.active && !_ctx.poiMoveMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_button_group, { round: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_tooltip, null, {
                    content: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${'tooltip-to-edit-poi'}`, 'tooltip-to-edit-poi', { ns: 'poi' })), 1 /* TEXT */)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        plain: "",
                        round: "",
                        onClick: _ctx.activeEdit
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_EditPen)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_tooltip, null, {
                    content: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${'tooltip-to-move-poi'}`, 'tooltip-to-move-poi', { ns: 'poi' })), 1 /* TEXT */)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        plain: "",
                        round: "",
                        onClick: _ctx.dragPoint
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_Place)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createCommentVNode("el-tooltip("),
                  _createCommentVNode(")"),
                  _createCommentVNode("  template(#content) {{$t(`${'tooltip-moving-to-poi'}`, 'tooltip-moving-to-poi', { ns: 'poi' })}}"),
                  _createCommentVNode("  el-button("),
                  _createCommentVNode("    round"),
                  _createCommentVNode("    @click=\"flyTo\""),
                  _createCommentVNode("  )"),
                  _createCommentVNode("    el-icon"),
                  _createCommentVNode("      MapLocation"),
                  _createVNode(_component_el_tooltip, null, {
                    content: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${'tooltip-delete-poi'}`, 'tooltip-delete-poi', { ns: 'poi' })), 1 /* TEXT */)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        onClick: _ctx.deletePOI,
                        round: "",
                        type: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_Delete)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              (_ctx.poi?.visited)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t(`${'visited-poi'}`, 'visited-poi', { ns: 'poi' })), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.poiMoveMode)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_el_alert, {
                  type: "info",
                  closable: false,
                  "show-icon": ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`${'move-poi-manual'}`, 'move-poi-manual', { ns: 'poi' })), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _ctx.saveMove
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${'save'}`, 'save', )), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_el_button, { onClick: _ctx.cancelMove }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${'cancel'}`, 'cancel', )), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (!_ctx.poiMoveMode && !_ctx.active)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.poi?.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.poi?.description), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t(`${'params'}`, 'params', { ns: 'poi' })), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("small", null, "LAT: " + _toDisplayString(Math.round(_ctx.poi?.y * 10000000) / 10000000) + " LNG: " + _toDisplayString(Math.round(_ctx.poi?.x * 10000000) / 10000000), 1 /* TEXT */),
                  _createVNode(_component_el_button, {
                    type: "primary",
                    link: "",
                    onClick: _ctx.copyToClipboard
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('copy', 'copy')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _createElementVNode("dl", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedParams, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (item.category ==='limit-factor' && Number(item.value) !== 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (Number(item.value))
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t(`${item.key}`, item.key, { ns: 'poi' })) + ":", 1 /* TEXT */),
                                  _createElementVNode("dd", null, _toDisplayString(_ctx.$t(`${item.key}-${item.value}`, `${item.key}-${item.value}`, { ns: 'poi' })), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : _createCommentVNode("v-if", true)
                          ], 64 /* STABLE_FRAGMENT */))
                        : (item.key !== 'n_prob')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createElementVNode("dt", null, _toDisplayString(_ctx.$t(`${item.key}`, item.key, { ns: 'poi' })) + ":", 1 /* TEXT */),
                              _createElementVNode("dd", null, _toDisplayString(_ctx.formatValue(item.value)) + " " + _toDisplayString(item.unit), 1 /* TEXT */)
                            ], 64 /* STABLE_FRAGMENT */))
                          : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ]),
                (_ctx.computedAdditionalParams.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('additional-parameters', 'additional-parameters', { ns: 'poi' })), 1 /* TEXT */),
                      _createElementVNode("dl", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedAdditionalParams, (item) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (_ctx.formatValue(item.value))
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("dt", null, _toDisplayString(item.key) + ":", 1 /* TEXT */),
                                  _createElementVNode("dd", null, _toDisplayString(_ctx.formatValue(item.value)), 1 /* TEXT */)
                                ], 64 /* STABLE_FRAGMENT */))
                              : _createCommentVNode("v-if", true)
                          ], 64 /* STABLE_FRAGMENT */))
                        }), 256 /* UNKEYED_FRAGMENT */))
                      ])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                (_ctx.imgList.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('photo', 'photo', { ns: 'poi' })), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgList, (item, idx) => {
                          return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createVNode(_component_el_image, {
                              src: item.src,
                              "preview-src-list": _ctx.imgStrings,
                              alt: item.name,
                              "initial-index": idx,
                              "hide-on-click-modal": "",
                              "close-on-press-escape": "",
                              "preview-teleported": ""
                            }, {
                              placeholder: _withCtx(() => []),
                              error: _withCtx(() => [
                                _createElementVNode("div", _hoisted_19, [
                                  _createVNode(_component_Picture)
                                ])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["src", "preview-src-list", "alt", "initial-index"]),
                            _createElementVNode("div", {
                              class: "POIEditBlock_BodyIMGlist-item-delete",
                              onClick: $event => (_ctx.deletePhoto(item))
                            }, [
                              _createVNode(_component_ui_icon, {
                                size: 17,
                                name: "Delete"
                              })
                            ], 8 /* PROPS */, _hoisted_20)
                          ]))
                        }), 256 /* UNKEYED_FRAGMENT */))
                      ])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (!_ctx.poiMoveMode && _ctx.active)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createTextVNode(_toDisplayString(_ctx.$t('edit-params', 'edit params', { ns: 'poi' })), 1 /* TEXT */),
                _createVNode(_component_el_form, {
                  model: _ctx.poiFormInfo,
                  "label-width": "150px",
                  "label-position": "left",
                  rules: _ctx.rules,
                  ref: "POIFormRef"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tabs, {
                      modelValue: _ctx.activeTabsEdit,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.activeTabsEdit) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tab_pane, {
                          label: _ctx.$t('main', 'main', { ns: 'poi' }),
                          name: "Info"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('name', 'name', { ns: 'poi' }),
                              prop: "name"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  placeholder: _ctx.$t('input-name', 'input-name', { ns: 'poi' }),
                                  modelValue: _ctx.poiFormInfo.name,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.poiFormInfo.name) = $event)),
                                  "show-word-limit": "",
                                  maxlength: "80"
                                }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label"]),
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('note', 'note', { ns: 'poi' })
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  rows: "3",
                                  type: "textarea",
                                  placeholder: _ctx.$t('input-params-note', 'input-params-note', { ns: 'poi' }),
                                  modelValue: _ctx.poiFormInfo.descr,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.poiFormInfo.descr) = $event)),
                                  maxlength: _ctx.POIParams.description.maxLength,
                                  "show-word-limit": ""
                                }, null, 8 /* PROPS */, ["placeholder", "modelValue", "maxlength"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label"]),
                            _createVNode(_component_el_form_item, {
                              label:  _ctx.$t('point-visited', 'point-visited', { ns: 'poi' })
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_switch, {
                                  modelValue: _ctx.poiFormInfo.visited,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.poiFormInfo.visited) = $event))
                                }, null, 8 /* PROPS */, ["modelValue"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTabs, (tab) => {
                          return (_openBlock(), _createBlock(_component_el_tab_pane, {
                            label:  _ctx.$t(`${tab}`, tab, { ns: 'poi' }),
                            name: tab,
                            key: tab
                          }, {
                            default: _withCtx(() => [
                              ( tab === 'no-standard' )
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64 /* STABLE_FRAGMENT */))
                                : _createCommentVNode("v-if", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poiFormInfo.params, (item, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                  (item.category === tab)
                                    ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                                        label: _withCtx(() => [
                                          _createElementVNode("div", null, [
                                            _createTextVNode(_toDisplayString(_ctx.$t(`${item.key}`, item.key, { ns: 'poi' })), 1 /* TEXT */),
                                            (item.unit)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_22, "(" + _toDisplayString(item.unit || '') + ")", 1 /* TEXT */))
                                              : _createCommentVNode("v-if", true)
                                          ])
                                        ]),
                                        default: _withCtx(() => [
                                          (item.type === 'string')
                                            ? (_openBlock(), _createBlock(_component_el_input, {
                                                key: 0,
                                                placeholder:  _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                                modelValue: item.value,
                                                "onUpdate:modelValue": $event => ((item.value) = $event),
                                                maxlength: item.maxLength || 0,
                                                "show-word-limit": !!item.maxLength
                                              }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onUpdate:modelValue", "maxlength", "show-word-limit"]))
                                            : _createCommentVNode("v-if", true),
                                          (item.type === 'stars')
                                            ? (_openBlock(), _createBlock(_component_el_rate, {
                                                key: 1,
                                                modelValue: item.value,
                                                "onUpdate:modelValue": $event => ((item.value) = $event),
                                                max: 3,
                                                clearable: ""
                                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                                            : _createCommentVNode("v-if", true),
                                          (item.type === 'select')
                                            ? (_openBlock(), _createBlock(_component_el_select, {
                                                key: 2,
                                                modelValue: item.value,
                                                "onUpdate:modelValue": $event => ((item.value) = $event),
                                                placeholder: "--"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.select, (item) => {
                                                    return (_openBlock(), _createBlock(_component_el_option, {
                                                      key: item.value,
                                                      label: item.title || item.label,
                                                      value: item.value
                                                    }, null, 8 /* PROPS */, ["label", "value"]))
                                                  }), 128 /* KEYED_FRAGMENT */))
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]))
                                            : _createCommentVNode("v-if", true),
                                          (item.type === 'soilIndex')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                _createVNode(_component_el_input, {
                                                  placeholder: _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                                  modelValue: item.value,
                                                  "onUpdate:modelValue": $event => ((item.value) = $event),
                                                  "show-word-limit": !!item.maxLength,
                                                  maxlength: item.maxLength || 0
                                                }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onUpdate:modelValue", "show-word-limit", "maxlength"]),
                                                _createElementVNode("div", _hoisted_24, [
                                                  _createVNode(_component_el_button, {
                                                    onClick: $event => (_ctx.addArrow(item,'↑'))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode("↑")
                                                    ]),
                                                    _: 2 /* DYNAMIC */
                                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                                ]),
                                                _createElementVNode("div", _hoisted_25, [
                                                  _createVNode(_component_el_button, {
                                                    onClick: $event => (_ctx.addArrow(item,'↓'))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode("↓")
                                                    ]),
                                                    _: 2 /* DYNAMIC */
                                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                                ])
                                              ]))
                                            : _createCommentVNode("v-if", true),
                                          (item.type === 'number')
                                            ? (_openBlock(), _createBlock(_component_el_input_number, {
                                                key: 4,
                                                placeholder: _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                                "model-value": item.value,
                                                "value-on-clear": null,
                                                onChange: $event => (_ctx.changeInputNumber($event,item)),
                                                min: item.min,
                                                max: item.max
                                              }, null, 8 /* PROPS */, ["placeholder", "model-value", "onChange", "min", "max"]))
                                            : _createCommentVNode("v-if", true),
                                          (item.type === 'int')
                                            ? (_openBlock(), _createBlock(_component_el_input_number, {
                                                key: 5,
                                                placeholder: _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                                onChange: $event => (_ctx.changeInputNumber($event,item)),
                                                min: item.min,
                                                max: item.max,
                                                "model-value": item.value,
                                                "value-on-clear": null
                                              }, null, 8 /* PROPS */, ["placeholder", "onChange", "min", "max", "model-value"]))
                                            : _createCommentVNode("v-if", true),
                                          (item.type === 'boolean')
                                            ? (_openBlock(), _createBlock(_component_el_switch, {
                                                key: 6,
                                                modelValue: item.value,
                                                "onUpdate:modelValue": $event => ((item.value) = $event)
                                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                                            : _createCommentVNode("v-if", true)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */))
                                    : _createCommentVNode("v-if", true)
                                ], 64 /* STABLE_FRAGMENT */))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "name"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["model", "rules"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_26, [
          (_ctx.active)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.onSubmit(_ctx.POIFormRef)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('save', 'save')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_el_button, {
                  onClick: _cache[5] || (_cache[5] = $event => {_ctx.active=!_ctx.active ;_ctx.activeTabsEdit = 'Info';})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('close', 'close')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}