import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "NavigationSeason" }
const _hoisted_2 = { class: "NavigationSeason-menu" }
const _hoisted_3 = { class: "NavigationSeason-menu-list" }
const _hoisted_4 = { class: "NavigationSeason-addSeason mt-10" }

export function render(_ctx, _cache) {
  const _component_NavigationSeasonItem = _resolveComponent("NavigationSeasonItem")
  const _component_el_button = _resolveComponent("el-button")
  const _component_SeasonForm = _resolveComponent("SeasonForm")
  const _component_el_drawer = _resolveComponent("el-drawer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NavigationSeasonItem, {
        season: _ctx.SeasonList.activeSeason.value,
        "is-button": true,
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.drawer = true))
      }, null, 8 /* PROPS */, ["season"])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_el_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.drawer) = $event)),
        size: 350,
        title: _ctx.$t('seasons-header', { ns: 'navigation' }),
        "z-index": 2000
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SeasonList.seasons.value, (season) => {
                return (_openBlock(), _createBlock(_component_NavigationSeasonItem, {
                  key: season.id,
                  season: season,
                  onSeasonSelect: _ctx.openEditDialog
                }, null, 8 /* PROPS */, ["season", "onSeasonSelect"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.isOpenNewSeason = true)),
              type: "primary",
              text: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('navigation.season.addSeason.label', { ns: 'navigation' })), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_button, {
                type: "info",
                text: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('navigation.eason.addSeason.help', { ns: 'navigation' })), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          (_ctx.isOpenNewSeason)
            ? (_openBlock(), _createBlock(_component_SeasonForm, {
                key: 0,
                season: null,
                onClose: _cache[2] || (_cache[2] = $event => (_ctx.isOpenNewSeason = false))
              }))
            : _createCommentVNode("v-if", true),
          (_ctx.isOpenEditSeason)
            ? (_openBlock(), _createBlock(_component_SeasonForm, {
                key: 1,
                season: _ctx.editSeason,
                onClose: _cache[3] || (_cache[3] = $event => (_ctx.isOpenEditSeason = false))
              }, null, 8 /* PROPS */, ["season"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "title"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}