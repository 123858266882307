import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "UserListContent" }
const _hoisted_2 = { class: "UserListContent_Body" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "UserListContent_Body-item_Name" }
const _hoisted_5 = { class: "UserListContent_Body-item_Name-lname" }
const _hoisted_6 = { class: "UserListContent_Body-item_Name-fname" }
const _hoisted_7 = { class: "UserListContent_Body-item-email" }

export function render(_ctx, _cache) {
  const _component_LeftPanel = _resolveComponent("LeftPanel")

  return (_openBlock(), _createBlock(_component_LeftPanel, { title: "Пользователи" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subject, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "UserListContent_Body-item",
              key: "item.id",
              onClick: $event => (_ctx.openEdit(item.id))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(item.last_name), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_6, _toDisplayString(item.first_name), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.email), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}