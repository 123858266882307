import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "NavigationSeasonItem-grid" }
const _hoisted_2 = { class: "NavigationSeasonItem-season" }
const _hoisted_3 = { class: "NavigationSeasonItem-agroSeason" }
const _hoisted_4 = { class: "NavigationSeasonItem-note" }
const _hoisted_5 = { class: "NavigationSeasonItem-edit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["NavigationSeasonItem", { active: _ctx.SeasonList.activeSeason.value.id === _ctx.season.id }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "NavigationSeasonItem-name",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSeason(_ctx.season)))
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.season.name), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formatRuDate(_ctx.season.startDate)) + " — " + _toDisplayString(_ctx.formatRuDate(_ctx.season.endDate)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.season.note && !_ctx.isButton)
          ? (_openBlock(), _createBlock(_component_el_popover, { key: 0 }, {
              reference: _withCtx(() => [
                _createVNode(_component_ui_icon, { name: "mdiCommentOutline" })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.season.note), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_tooltip, { content: "Редактировать сезон" }, {
          default: _withCtx(() => [
            (!_ctx.isButton)
              ? (_openBlock(), _createBlock(_component_ui_icon, {
                  key: 0,
                  name: "mdiSquareEditOutline",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('season-select', _ctx.season)))
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ], 2 /* CLASS */))
}