import { bbox } from '@turf/turf';
import mapboxgl, { LngLat, PointLike } from 'mapbox-gl';
import { Feature } from 'geojson';

export const getByBboxFeatures = (map: mapboxgl.Map, polygon: Feature, FilterTypeList: string[] = []) => {
  const areaBbox = bbox(polygon);

  const a = map.project(new LngLat(areaBbox[0], areaBbox[3]));
  const b = map.project(new LngLat(areaBbox[2], areaBbox[1]));

  const features = map.queryRenderedFeatures([a, b] as [PointLike, PointLike]);

  return features.filter((f) => {
    // @ts-ignore
    if (f.layer.metadata?.type === undefined) {
      return false;
    }
    // @ts-ignore
    return FilterTypeList.length > 0 ? FilterTypeList.some((l) => f.layer.metadata?.type === l) : true;
  });
};
