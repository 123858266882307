import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ph-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_LegendTable = _resolveComponent("LegendTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.legendType === 'raster')
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 0,
          src: _ctx.legend.image,
          style: {"max-width":"300px","max-height":"300px"},
          "preview-src-list": [_ctx.legend.image],
          "preview-teleported": ""
        }, null, 8 /* PROPS */, ["src", "preview-src-list"]))
      : (_openBlock(), _createBlock(_component_LegendTable, {
          key: 1,
          data: _ctx.data
        }, null, 8 /* PROPS */, ["data"]))
  ]))
}