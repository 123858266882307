<template lang="pug">
el-select(
  :model-value="layer?.data?.activeMaterial.key"
  @change="onChange"
)
  el-option(
    v-for="option in options"
    :key="option.key"
    :label="option.label"
    :value="option.value"
  )
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import {
  Check, CirclePlus, Delete, Setting,
} from '@element-plus/icons-vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import OrderedList from '@/components/ui/OrderedList/OrderedList.vue';
import Content from '@/components/shared/Content/Content.vue';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import TaskMapCard from '@/components/shared/TaskMapCard/TaskMapCard.vue';
import { MapLayerTaskMapFactModel } from '@/models/map/Layers/MapLayerTaskMapFactModel';
import { TaskMapMaterialType } from '@/constants/types/taskMap/TaskMapMaterialType';

export default defineComponent({
  name: 'FactMapAnalyzeContent',
  components: {
    RightPanel,
    Setting,
    CirclePlus,
    Delete,
    Check,
    OrderedList,
    Content,
    NoActiveField,
    TaskMapCard,
  },
  props: {
    layer: {
      type: Object as PropType<MapLayerTaskMapFactModel>,
      required: true,
    },
  },
  setup(props) {
    const options = computed(() => props.layer?.data?.availableMaterials.map((material: TaskMapMaterialType) => ({
      key: material.key,
      value: material.key,
      label: material.key,
    })));

    const onChange = (value: string) => {
      props.layer.data.setActiveMaterial(value);
      props.layer.update();
    };

    return {
      options,
      onChange,
    };
  },
});
</script>

<style lang="scss">
</style>
