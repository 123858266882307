import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "TotalArea-button" }
const _hoisted_2 = { class: "TotalArea-attribute" }
const _hoisted_3 = { class: "TotalArea-attribute-label" }
const _hoisted_4 = {
  key: 0,
  class: "TotalArea-attribute-value"
}
const _hoisted_5 = {
  key: 1,
  class: "TotalArea-attribute-value"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_popover = _resolveComponent("el-popover")

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "top-start",
    title: _ctx.$t('struct-stats-title', { ns: 'statusbar' }),
    width: 400,
    trigger: "hover",
    "show-after": 500
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_icon, {
          name: "mdiInformationOutline",
          color: "#666",
          size: 15
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('struct-stats-label', { ns: 'statusbar' })), 1 /* TEXT */)
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.StructList.activeStruct.value?.statAttributes, (attribute) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(attribute.name, attribute.name, { ns: 'statusbar' })), 1 /* TEXT */),
          (attribute.value === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('information-is-empty')), 1 /* TEXT */)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (attribute.prefix.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t(attribute.prefix, attribute.prefix)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (attribute.processingType === 'day-month')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.dayMonth(attribute.value)), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(attribute.value), 1 /* TEXT */)),
                (attribute.postfix.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t(attribute.postfix, attribute.postfix)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]))
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}