<template lang="pug">
ui-dialog(
    :model-value="isModal"
    :width="400"
    @close="$emit('update:isModal', false);"
  )
    template(#header)
      h4  {{ $t('create-task-map-title', { ns: 'taskMap' }) }}
    el-form(
      ref="ruleFormRef"
      label-position="top"
      label-width="150px"
      :disabled="LoadingStatus.isLoading.value(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT)"
      :model="form"
      :rules="rules"
    )
      el-form-item(
        label="Название операции"
        prop="name"
      )
        el-input(
          placeholder="Введите название"
          v-model="form.name"
        )
      hr
      el-switch(
        :model-value="true"
        disabled="true"
        :active-text="$t('save-on-tf-server', { ns: 'taskMap' })"
      )

      el-switch(
        v-model="emails.active"
        :active-text="$t('send-task-map-on-email', { ns: 'taskMap' })"
      )

      div(
        v-if="emails.active"
      )
        el-button(
          @click="addUser"
          type="success"
          link
          style='position: absolute;right: 15px'
        ) {{ $t('add') }}
        el-form-item(
          :label="$t('email')"
        )
          template(
            v-for="(email , idx) in emails.email"

          )
            .flex-row(
              style="width: 100%"
            ).gap-6
              el-input(
                v-model="email.value"
                placeholder="Введите e-mail"
              ).mb-15
              el-button(
                v-if="idx"
                icon="Delete"
                type="danger"
                @click="removeElement(idx)"
              )

        el-form-item(
          label="Тип техники"
          prop="type"
          style="margin-top: -15px"
        )
          el-select(
            v-model="emails.techType"
            multiple
            collapse-tags
          )
            el-option(
              v-for='item in techType'
              :key="item.value"
              :label="item.name"
              :value="item.value"
            )
              .flex-row.flex-row-justify-between
                span(
                ) {{item.name}}
                span(
                  :style="{fontSize: 10+'px',color:'var(--color-foreground-light)'}"
                ) {{item.type}}

      el-switch(
        v-if="PermissionsList.hasPermission('techjob', 'add')"
        v-model="devices.active"
        :active-text="$t('send-task-on-device', { ns: 'taskMap' })"
      )
      div(
        v-if="devices.active"
      )
        el-form-item(
          :label="$t('devices', { ns: 'taskMap' })"
          size="small"
        )
          el-select(
            v-model="devices.device"
            multiple
            collapse-tags
          )
            el-option(
              v-for="item of IntegrationsList.tech"
              :value="item.id"
              :label="item.techName"
            )

    template(#footer)
      el-button(
        @click="$emit('update:isModal', false)"
      ) {{ $t('cancel') }}

      el-button(
        type="primary"
        :disabled="!isEmailsValid"
        :loading="LoadingStatus.isLoading.value(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT)"
        @click="createTask()"
      ) {{ $t('create') }}
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, ref,
} from 'vue';
import ApiService from '@/services/api/ApiService';
import StructList from '@/modules/struct/StructList';
import { ElNotification, FormRules } from 'element-plus';
import BillingService from '@/modules/billing/BillingService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { useTaskMap } from '@/modules/taskMap/composables/useTaskMap';
import { CreatingTaskMapModel } from '@/models/taskMap/CreatingTaskMapModel';
import { useUser } from '@/composables/useUser';
import { TaskMapMaterialTypeEnum } from '@/constants/enums/TaskMapMaterialTypeEnum';
import { useBilling } from '@/composables/useBilling';
import IntegrationsList from '@/modules/integration/IntegrationsList';
import { techType } from '@/constants/constants/techTupe';
import { RuleMaterialName } from '@/constants/rules/RuleMaterialName';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import PermissionsList from '@/modules/permissions/PermissionsList';

interface formInterface {
    name: string,
    fieldsId: number,
    materials: {
      norma: number,
      name: string,
      unit: string,
      type: TaskMapMaterialTypeEnum,
    }[]
}
export default defineComponent({
  name: 'CreateTaskMapFormDialog',
  components: {
    UiDialog,
  },
  props: {
    data: {
      type: Array as PropType<CreatingTaskMapModel[]>,
      default: [] as string[],
    },
    form: {
      type: Object as PropType<formInterface>,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:isModal'],
  setup(props, { emit }) {
    const {
      selectBaseTaskMaps,
    } = useTaskMap();

    const { user } = useUser();
    const { contract } = useBilling();

    const rules = ref<FormRules>({
      ...RuleMaterialName,
    });

    const emails = ref<{active: boolean, email: {value: string}[], techType: number[]}>({
      active: false,
      email: [{ value: JSON.parse(JSON.stringify(user.value?.email)) }],
      techType: [],
    });

    const devices = ref<{ active: boolean, device: number[]}>({
      active: false,
      device: [],
    });

    const createTask = () => {
      const acc: Array<any> = [];
      selectBaseTaskMaps.value.forEach((f) => {
        const dp = {
          task_map: f.id,
          material_config: [],
        };

        props.data.forEach((task) => {
          if (task.baseMap.id === f.id) {
            // @ts-ignore
            dp.material_config.push(task.toJSON());
          }
        });

        acc.push(dp);
      });
      const tasks = {
        task_name: props.form.name,
        users: emails.value.active ? emails.value.email.flatMap((a) => emails.value.techType.map((b) => ({
          email: a.value,
          type: b,
        }))) : [],
        tech_jobs: devices.value.active ? devices.value.device.map((a) => ({ device: a })) : [],
        fields: acc,
        materials: [
          ...props.form.materials,
        ],
      };

      ApiService.taskMap.postCreateTask(StructList.activeStruct.value?.id || 0, tasks).then((resp) => {
        ElNotification({
          title: 'Успешно',
          message: resp.data.description,
          type: 'success',
          position: 'bottom-right',
          customClass: 'tasksMap',
        });
        if (contract.value?.isDemo) {
          BillingService.fetchStructBilling();
        }
        LoadingStatus.success(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT);
      }).catch((e) => {
        LoadingStatus.error(LoadingNamesEnum.TASK_MAP_CREATE_SUBMIT, e);
      });
      emit('update:isModal', false);
    };

    const addUser = () => {
      emails.value.email.push({ value: '' });
    };

    const removeElement = (index: number) => {
      emails.value.email.splice(index, 1);
    };

    const isEmailsValid = computed(() => emails.value.email.every((a) => a.value.match(/^\S+@\S+\.\S+$/)));

    return {
      addUser,
      removeElement,
      createTask,
      emails,
      devices,
      isEmailsValid,
      LoadingStatus,
      LoadingNamesEnum,
      IntegrationsList,
      techType,
      rules,
      PermissionsList,
    };
  },
});
</script>

<style  lang="scss">

</style>
