<template lang="pug">
.NavigationLogo
  router-link(
    :to='{ name: "struct" }'
  )
    img(
      src='@images/logo.svg'
    )
</template>

<style lang="scss" src="./NavigationLogo.scss"></style>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavigationLogo',
});
</script>
