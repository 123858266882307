<template lang='pug'>
.ExperimentAddForm.theme-dark(
  v-if="experiment && isActive"
)
  .ExperimentAddForm-block
    el-form(
      label-position="top"
    )
      .ExperimentAddForm-form
        .ExperimentAddForm-form-left
          el-form-item(
            label="Наименование"
            size="small"
          )
            el-input(
              v-model="experiment.title"
            )
          el-form-item(
            label="Цвет"
            size="small"
          )
            div {{localActiveGradient?.name}}
            ColorGradientPicker(
              v-if="mounted"
              v-model="experiment.color"
              :gradients="gradients"
              v-model:active-gradient="localActiveGradient"
              @update:active="setGradient"
              :prefix-colors="['#FBFC02']"
              :count="19"
            )

          el-form-item(
            size="small"
          )
            el-button(
              @click="$emit('add', experiment)"
              :disabled="experiment.isSticky"
              type="primary"
            ) {{ $t('add') }}

            el-button(
              @click="$emit('cancel', experiment)"
            ) {{ $t('cancel') }}
        .ExperimentAddForm-form-right

          el-form-item(
            label="Высота (м)"
            size="small"
          )
            el-input-number(
              v-model="experiment.height"
              :min="1"
              @change="refreshMap"
            )
          el-form-item(
            label="Ширина (м)"
            size="small"
          )
            el-input-number(
              v-model="experiment.width"
              :min="1"
              @change="refreshMap"
            )
          el-form-item(
            label="Угол поворота"
            size="small"
          )
            el-input-number(
              v-model="experiment.turn"
              @change="refreshMap"
            )

    .ExperimentAddForm-info(
      v-if="experiment.isSticky"
    )
      p {{ $t('add-experiment-instruction', { ns: 'experiments' }) }}

</template>

<script lang='ts'>
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { GradientModel } from '@/models/assets/GradientModel';
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { rgbaToHex } from '@/lib/convertors/rgbToHex';
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { ExperimentModel } from '@/models/ExperimentModel';
import { FieldModel } from '@/models/field/FieldModel';
import { MapMouseEvent, MapWheelEvent } from 'mapbox-gl';
import { Feature } from 'geojson';
import ColorGradientPicker from '@/components/shared/ColorGradientPicker/ColorGradientPicker.vue';
import { useExperiments } from '@/pages/task-map/create/experiments/composables/useExperiments';

export default defineComponent({
  name: 'ExperimentAddForm',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    gradients: {
      type: Array as PropType<GradientModel[]>,
      required: true,
    },
    activeGradient: {
      type: Object as PropType<GradientModel>,
      required: true,
    },
  },
  components: {
    ColorGradientPicker,
  },
  emits: ['add', 'cancel', 'update:activeGradient'],
  setup(props, { emit }) {
    const name = ref('');
    const {
      activeField,
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const mounted = ref(false);

    const {
      refreshMap,
      addNewExperiment,
      calculateTitle,
    } = useExperiments();

    const _exp = new ExperimentModel(-1, activeField.value as FieldModel);
    _exp.title = calculateTitle();
    addNewExperiment(_exp);

    const localActiveGradient = ref<GradientModel>(props.activeGradient);
    const experiment = reactive(_exp);

    mapModel.value?.map?.on('click', (e: MapMouseEvent) => {
      if (experiment.isCreating) {
        if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
          if (experiment.isSticky) {
            experiment.isSticky = false;
            experiment.opacity = 1;
          } else {
            const features = mapModel.value?.map?.queryRenderedFeatures(e.point);
            if (features?.some((f: Feature) => f.properties?.uuid === experiment.uuid)) {
              experiment.isSticky = true;
              experiment.opacity = 0.7;
            }
          }
        }
      }
    });

    mapModel.value?.map?.on('wheel', (event: MapWheelEvent) => {
      if (event.originalEvent.shiftKey && !(event.originalEvent.ctrlKey || event.originalEvent.metaKey)) {
        experiment.width += event.originalEvent.deltaY > 0 ? -1 : 1;
        event.preventDefault();
      }
      if (event.originalEvent.altKey && !(event.originalEvent.ctrlKey || event.originalEvent.metaKey)) {
        experiment.height += event.originalEvent.deltaY > 0 ? -1 : 1;
        event.preventDefault();
      }
    });

    mapModel.value?.map?.on('mouseover', () => {
      if (experiment.isSticky) {
        experiment.opacity = 0.7;
      }
    });

    mapModel.value?.map?.on('mouseout', () => {
      if (experiment.isSticky) {
        experiment.opacity = 0;
      }
    });
    const setGradient = ($event: GradientModel) => {
      emit('update:activeGradient', $event);
    };
    watch(activeField, () => {
      emit('cancel', experiment);
    });

    watch(() => experiment.width, refreshMap);
    watch(() => experiment.height, refreshMap);
    watch(() => experiment.color, refreshMap);

    watch(localActiveGradient, (a) => {
      setGradient(a as GradientModel);
    });
    onMounted(() => {
      mounted.value = true;
    });

    return {
      refreshMap,
      experiment,
      name,
      rgbaToHex,
      BaseMapColors,
      setGradient,
      localActiveGradient,
      mounted,
    };
  },
});
</script>
<style lang='scss'>
.ExperimentAddForm {
  pointer-events: none;
  top: 10px;
  left: 62px;
  right: 350px;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &-block {
    position: relative;
    pointer-events: all;
    width: 100%;
    max-width: 480px;
    padding: 10px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, .35);
    background: var(--color-black-500);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-form {
    display: grid;
    grid-template-columns: 1fr 130px;
    column-gap: 20px;
    &-right {
      .el-form-item--small {
        margin-bottom: 5px;
      }
    }
  }

  &-info {
    background-color: var(--color-black-400);
    padding: 4px;
    font-size: 0.85rem;
    font-weight: 500;
    color: var(--color-warning-300);
    text-align: left;
  }
}
</style>
