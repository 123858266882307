import { booleanPointInPolygon, intersect } from '@turf/turf';
import { getByBboxFeatures } from '@/lib/map/getByBboxFeatures';
import { Feature, Polygon } from 'geojson';

export const getByIntersectFeatures = (map: mapboxgl.Map, polygon: Feature, FilterTypeList: string[] = []) => getByBboxFeatures(map, polygon, FilterTypeList)?.filter((a) => {
  if (a.geometry.type === 'Point') {
    return booleanPointInPolygon(a.geometry, polygon.geometry as Polygon);
  }
  return intersect(polygon.geometry as Polygon, a.geometry as Polygon) !== null;
});
