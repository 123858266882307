<template lang="pug">
  div(
    :style="{width: width ? width + 'px' : '100%'}"
  )
    Markdown(
      :source="text"
      html
    )
</template>

<script  lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import Markdown from 'vue3-markdown-it';
import ApiService from '@/services/api/ApiService';

export default defineComponent({
  name: 'WikiContent',
  components: { UiDialog, Markdown },
  props: {
    id: {
      type: Number,
      required: true,
    },
    input: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
    },
  },
  setup(props) {
    const text = ref('');

    const extractTextBetweenMarkers = (input: string): string => {
      const regexString = `<!--\\s*${props.input} start\\s*-->([\\s\\S]*?)<!--\\s*${props.input} end\\s*-->`;

      const match = input.match(regexString);
      return match ? match[1].trim() : '';
    };
    const fetchWikiPageContent = async () => {
      const response = await ApiService.services.getWikiPage(props.id);
      const content = response.data?.data?.pages?.single?.content ?? '';

      const extractedContent = extractTextBetweenMarkers(content);
      text.value = extractedContent;
    };

    onMounted(async () => {
      await fetchWikiPageContent();
    });
    return {
      text,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
