import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "FieldCard" }
const _hoisted_2 = ["data-pk"]
const _hoisted_3 = { class: "FieldCard_image" }
const _hoisted_4 = { class: "FieldCard_info" }
const _hoisted_5 = { class: "FieldCard_info-name" }
const _hoisted_6 = {
  key: 0,
  class: "FieldCard_info-area"
}

export function render(_ctx, _cache) {
  const _component_FieldView = _resolveComponent("FieldView")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "FieldCard_feature",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickElement && _ctx.clickElement(...args))),
      onMouseenter: _cache[1] || (_cache[1] = $event => (_ctx.enterElement(_ctx.field, $event))),
      onMouseleave: _cache[2] || (_cache[2] = $event => (_ctx.leaveElement())),
      "data-pk": `feature-${_ctx.field.id}`
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FieldView, {
          field: _ctx.field,
          size: _ctx.size
        }, null, 8 /* PROPS */, ["field", "size"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.field.name), 1 /* TEXT */),
        (_ctx.showArea)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(Math.round(_ctx.field.sq * 100) / 100) + " га", 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
  ]))
}