import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    "model-value": _ctx.layer?.data?.activeMaterial.key,
    onChange: _ctx.onChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: option.key,
          label: option.label,
          value: option.value
        }, null, 8 /* PROPS */, ["label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "onChange"]))
}