import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "POIAddCompactBlock" }
const _hoisted_2 = { class: "POIAddCompactBlock-body" }
const _hoisted_3 = { class: "flex-col" }
const _hoisted_4 = { class: "flex-row flex-col-align-center flex-row-justify-between gap-20" }
const _hoisted_5 = { class: "POIAddCompactBlock-footer" }

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_tempalate = _resolveComponent("tempalate")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_ui_drawer = _resolveComponent("ui-drawer")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_drawer, {
        title: _ctx.$t('add-poi','add-poi',{ns:'poi'}),
        show: _ctx.open,
        position: "right",
        onClose: _cache[5] || (_cache[5] = $event => (_ctx.closeDrawer()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_form, {
                model: _ctx.formPoi,
                "label-position": "top",
                ref: "formPOIRef",
                rules: _ctx.rules
              }, {
                default: _withCtx(() => [
                  (_ctx.formPoi.poi !== undefined)
                    ? (_openBlock(), _createBlock(_component_tempalate, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.$t('name','name',{ns:'poi'}),
                            prop: "name"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                placeholder: _ctx.$t('input-name','input-name',{ns:'poi'}),
                                modelValue: _ctx.formPoi.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.formPoi.name) = $event))
                              }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"]),
                          _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("span", null, "LAT: " + _toDisplayString(Math.round(_ctx.formPoi.poi.y * 10000000) / 10000000), 1 /* TEXT */),
                            _createElementVNode("span", null, "LNG: " + _toDisplayString(Math.round(_ctx.formPoi.poi.x * 10000000) / 10000000), 1 /* TEXT */)
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('group','group',{ns:'poi'}),
                      prop: "group",
                      style: {"width":"220px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.formPoi.group,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.formPoi.group) = $event)),
                          placeholder: _ctx.$t('input-name','input-name',{ns:'poi'})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.PoisList.groups.value, (struct) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: struct.id,
                                value: struct.id,
                                label: struct.name
                              }, null, 8 /* PROPS */, ["value", "label"]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue", "placeholder"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_el_button, {
                      class: "mt-15",
                      onClick: _cache[2] || (_cache[2] = $event => (_ctx.addGroupActive = !_ctx.addGroupActive)),
                      circle: "",
                      icon: _ctx.Plus,
                      type: "info"
                    }, null, 8 /* PROPS */, ["icon"])
                  ]),
                  (_ctx.formPoi.poi !== undefined)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        label: _ctx.$t('note','note',{ns:'poi'})
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            rows: "3",
                            type: "textarea",
                            placeholder: _ctx.$t('input-params-note','input-params-note',{ns:'poi'}),
                            modelValue: _ctx.formPoi.poi.description,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.formPoi.poi.description) = $event)),
                            "show-word-limit": "",
                            maxlength: "127"
                          }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["label"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["model", "rules"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[4] || (_cache[4] = $event => (_ctx.onSubmit(_ctx.formPOIRef)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('save','save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title", "show"])
    ])),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.addGroupActive,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.addGroupActive) = $event)),
        width: 500,
        header: _ctx.$t('add-group','add-group',{ns:'poi'}),
        onClose: _cache[10] || (_cache[10] = $event => (_ctx.groupAddForm.name = ''))
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_el_button, {
            onClick: _cache[8] || (_cache[8] = $event => (_ctx.onGroupAddSubmit(_ctx.formGroupAddRef))),
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('add','add')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_form, {
            "label-position": "top",
            ref: "formGroupAddRef",
            model: _ctx.groupAddForm,
            rules: _ctx.groupAddRules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('name','name',{ns:'poi'}),
                prop: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.groupAddForm.name,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.groupAddForm.name) = $event)),
                    placeholder: _ctx.$t('input-name','input-name',{ns:'poi'})
                  }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('type-group','type-group',{ns:'poi'}),
                prop: "type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.groupAddForm.type,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.groupAddForm.type) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_option, {
                        label: _ctx.$t('info','info',{ns:'poi'}),
                        value: 0
                      }, null, 8 /* PROPS */, ["label"]),
                      _createVNode(_component_el_option, {
                        label: _ctx.$t('soil-cut','soil-cut',{ns:'poi'}),
                        value: 1
                      }, null, 8 /* PROPS */, ["label"]),
                      _createVNode(_component_el_option, {
                        label: _ctx.$t('agrochemical-samples','agrochemical-samples',{ns:'poi'}),
                        value: 2
                      }, null, 8 /* PROPS */, ["label"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model", "rules"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "header"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}