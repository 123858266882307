<template lang="pug">
.MapAreaChart(
  id='chart'
  ref="container"
)
</template>

<script  lang="ts">
import {
  computed, defineComponent, onMounted, PropType, ref,
} from 'vue';
import ApexCharts from 'apexcharts';
import ru from 'apexcharts/dist/locales/ru.json';
import MonitoringChartPlotConfig from '@/assets/data/MonitoringChartPlotConfig';

export default defineComponent({
  name: 'MapAreaChart',
  components: {},
  props: {
    data: {
      type: Array as PropType<{
        name: string,
        value: number,
      }[]>,
      required: true,
    },
  },
  setup(props) {
    const myChart = ref<ApexCharts>();

    const container = ref<HTMLElement>();

    const options = computed(() => ({
      chart: {
        foreColor: '#C9C9C9',
        height: 250,
        type: 'line',
        animations: {
          enabled: false,
        },
        locales: [ru],
        defaultLocale: 'ru',
        events: {
          // mounted: () => {
          //   showElement();
          // },
          // updated: () => {
          //   showElement();
          // },
          legendClick: (e: any, chart: any, config: any) => {
            // if (chart === 3) {
            //   activeTemp.value = !activeTemp.value;
            //   myChart.value?.updateOptions({ series: computedChartY.value });
            // }
          },

        },
      },
      ...MonitoringChartPlotConfig.options,
      tooltip: {
        theme: 'dark',
      },

    }));

    const createChart = async () => {
      myChart.value !== undefined && myChart.value.destroy();
      (myChart.value = new ApexCharts(container.value, options.value));
      await myChart.value?.render();
    };

    onMounted(() => {
      createChart();
    });

    return {
      container,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
