import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "FieldSplittingBlock" }
const _hoisted_2 = { class: "flex-col flex-col-justify-between" }
const _hoisted_3 = { class: "flex-col" }
const _hoisted_4 = { class: "flex-row flex-row-justify-between" }
const _hoisted_5 = { class: "flex-row flex-row-justify-between pt-6" }
const _hoisted_6 = { class: "flex-col" }
const _hoisted_7 = { class: "flex-row flex-row-justify-between pt-8" }
const _hoisted_8 = {
  key: 0,
  class: "mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_FieldView = _resolveComponent("FieldView")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_ui_dialog = _resolveComponent("ui-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenAddForm = true)),
              disabled: _ctx.selectedGeomIndex.length === _ctx.activeFieldToFields.length
            }, {
              default: _withCtx(() => [
                _createTextVNode("Добавить поле")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"]),
            _createVNode(_component_el_button, {
              onClick: _ctx.clean,
              type: "danger"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('clean')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (f, idx) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_FieldView, {
                  preview: new _ctx.FieldPreviewModel(f.geom.coordinates)
                }, null, 8 /* PROPS */, ["preview"]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(f.name), 1 /* TEXT */),
                  _createElementVNode("div", null, _toDisplayString(_ctx.formatArea(f.sq_acc)), 1 /* TEXT */)
                ])
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_button, {
            disabled: _ctx.selectedGeomIndex.length !== _ctx.activeFieldToFields.length,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('save')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"]),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('close')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.isOpenAddForm,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isOpenAddForm) = $event))
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_el_button, {
            disabled: _ctx.disabledAddButton,
            onClick: _ctx.addField
          }, {
            default: _withCtx(() => [
              _createTextVNode("Добавить")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_form, { "label-position": "top" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Название поля" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.form.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.name) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_form_item, { label: "Описание" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.form.description,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.description) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_checkbox_group, {
            modelValue: _ctx.form.selectedFields,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.selectedFields) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.selectedGeomIndex), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeFieldToFields, (f, idx) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (_ctx.selectedGeomIndex.some((a) => a !== idx) || _ctx.selectedGeomIndex.length === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_el_checkbox, { value: idx }, {
                            default: _withCtx(() => [
                              _createVNode(_component_FieldView, {
                                preview: f.preview
                              }, null, 8 /* PROPS */, ["preview"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}