import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache) {
  const _component_SettingsPalettePanel = _resolveComponent("SettingsPalettePanel")

  return (_ctx.node)
    ? (_openBlock(), _createBlock(_component_SettingsPalettePanel, {
        key: 0,
        node: _ctx.node,
        onDeletePalette: _cache[0] || (_cache[0] = $event => (_ctx.$emit('delete-palette', $event))),
        onSaveName: _cache[1] || (_cache[1] = $event => (_ctx.$emit('save-name', $event)))
      }, null, 8 /* PROPS */, ["node"]))
    : _createCommentVNode("v-if", true)
}