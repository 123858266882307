<template lang="pug">
transition(
  name="modal"
)
  .UiDialog(
    v-if="modelValue"
    :class="{ [`--center`]: center, [`--contentscreen`]: contentscreen }"
  )
    .UiDialog-content(
      :class="{ [`--fullscreen`]: fullscreen, [`--contentscreen`]: contentscreen }"
      :style="{ width: contentWidth(), marginTop: !center && !fullscreen && !contentscreen ? top : 'inherit' }"
      :data-width="contentWidth()"
    )
      .UiDialog-content-header
        .UiDialog-content-header-label
          template(v-if="hasSlot('header')")
            slot(name="header")
          template(v-else) {{ header }}
        .UiDialog-content-header-close(
          @click="close"
        )

      .UiDialog-content-actions(
        v-if="hasSlot('actions')"
      )
        slot(name="actions")
      .UiDialog-content-body
        slot
      .UiDialog-content-footer(
        v-if="hasSlot('footer')"
      )
        slot(name="footer")
    .UiDialog-wrap(
      @click="close"
      v-if="!contentscreen"
    )
</template>

<script  lang="ts">
import { isNumber } from '@/utils/isNumber';
import { defineComponent, ref, useSlots } from 'vue';

export default defineComponent({
  name: 'UiDialog',
  props: {
    modelValue: {
      type: Boolean,
      required: false,
    },
    header: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    contentscreen: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: '15vh',
    },
    width: {
      type: [Number, String],
      default: 400,
    },
    allowClose: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'close'],
  setup(props, { emit }) {
    const slots = useSlots();
    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: any[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type.toString() !== 'Symbol(Comment)').length > 0;
    };

    const titleIcon = ref('#bdbdbd');

    const close = () => {
      if (props.allowClose) {
        emit('update:modelValue', false);
        emit('close');
      }
    };

    const contentWidth = () => {
      if (props.fullscreen || props.contentscreen) {
        return '100%';
      }
      if (props.width.toString().endsWith('%')) {
        return props.width;
      }
      if (isNumber(props.width)) {
        return `${props.width}px`;
      }
      return props.width;
    };

    return {
      hasSlot,
      close,
      titleIcon,
      contentWidth,
    };
  },
});
</script>

<style lang="scss">
.UiDialog {
  $this: &;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
  &.--contentscreen {
    inset: 50px 0 30px 0;
    z-index: 3;
  }
  &.--center {
    align-items: center;
  }
  &-wrap {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }
  &-content {
    background: #FFF;
    position: relative;
    z-index: 2;
    transition: var(--transition-default);
    width: 100%;
    margin: 16px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 32px);
    &.--fullscreen {
      position: absolute;
      inset: 0 0 0 0;
      margin: 0;
      max-height: 100vh;
      height: 100vh;
      width: 100vw;
    }
    &.--contentscreen {
      position: absolute;
      inset: 0;
      margin: 0;
      max-height: calc(100vh - 80px);
      height: calc(100vh - 80px);
      width: 100vw;
      box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.3);
    }
    &:not(.--fullscreen) {
      box-shadow: 0 0 25px rgba(0, 0, 0, .3);
    }
    &-header {
      padding: 16px 16px 0 16px;
      display: grid;
      width: calc(100% - 40px);
      grid-template-columns: 1fr 28px;
      column-gap: 10px;
      align-items: center;
      &-label {
        text-align: left;
        font-size: 1.2rem;
        color: var(--color-black-300);
        font-weight: 500;
      }
      &-close {
        @include cross(28px, #666, 2px);
      }
    }
    &-actions {
      padding: 16px 16px 0 16px;
    }
    &-body {
      padding: 16px;
      flex-grow: 1;
      max-height: 100%;
      overflow: auto;
    }
    &-footer {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
