import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ta-left"
}
const _hoisted_2 = { class: "ta-right" }

export function render(_ctx, _cache) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createBlock(_component_ui_dialog, {
    header: _ctx.title,
    onClose: _cache[6] || (_cache[6] = $event => (_ctx.$emit('close'))),
    "model-value": true,
    width: 500,
    "z-index": 2030,
    top: ""
  }, {
    footer: _withCtx(() => [
      (_ctx.editingSeason.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_popconfirm, {
              width: "260",
              icon: "warning",
              "confirm-button-text": "Удалить",
              "confirm-button-type": "danger",
              title: "Вы уверены, что хотите удалить все данные о сезоне?",
              onConfirm: _ctx.deleteSeason
            }, {
              reference: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "danger",
                  link: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Удалить сезон")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onConfirm"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_button, {
          onClick: _cache[5] || (_cache[5] = $event => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.saveSeason,
          disabled: !!_ctx.nameError
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.editingSeason.id ? 'save' : 'add')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, { "label-position": "top" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Дата начала и окончания сезона" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.editingSeason.start,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.editingSeason.start) = $event)),
                    format: "DD.MM.YYYY",
                    type: "date",
                    placeholder: "Начало сезона",
                    clearable: false
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.editingSeason.end,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.editingSeason.end) = $event)),
                    disabled: !_ctx.editingSeason.start,
                    "disabled-date": _ctx.disabledDate,
                    format: "DD.MM.YYYY",
                    type: "date",
                    placeholder: "Окончание сезона",
                    clearable: false
                  }, null, 8 /* PROPS */, ["modelValue", "disabled", "disabled-date"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_form_item, {
            label: "Наименование сезона",
            error: _ctx.nameError
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.editingSeason.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.editingSeason.name) = $event)),
                maxlength: 25,
                "show-word-limit": ""
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["error"]),
          _createVNode(_component_el_input, {
            rows: 3,
            type: "textarea",
            placeholder: "Комментарий (необязательно)",
            maxlength: 250 ,
            "show-word-limit": "",
            modelValue: _ctx.editingSeason.note,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.editingSeason.note) = $event)),
            style: {"max-height":"400px"}
          }, null, 8 /* PROPS */, ["modelValue"]),
          (!_ctx.editingSeason.id)
            ? (_openBlock(), _createBlock(_component_el_checkbox, {
                key: 0,
                modelValue: _ctx.isCopyField,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.isCopyField) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Копировать поля из текущего сезона")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header"]))
}