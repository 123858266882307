import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "LeftPanel" }
const _hoisted_2 = {
  class: "LeftPanel_Header",
  ref: "headerRef"
}
const _hoisted_3 = { class: "LeftPanel_Header-title" }
const _hoisted_4 = {
  key: 0,
  class: "LeftPanel_Actions",
  ref: "actionsRef"
}
const _hoisted_5 = {
  key: 1,
  class: "LeftPanel_Filters",
  ref: "filtersRef"
}
const _hoisted_6 = ["data-style"]
const _hoisted_7 = {
  key: 2,
  class: "LeftPanel_Footer",
  ref: "footerRef"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
      (_ctx.closeable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "LeftPanel_Header-close",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */),
    (_ctx.hasActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "actions")
        ], 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true),
    (_ctx.hasFilters)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "filters")
        ], 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: "LeftPanel_Body",
      style: _normalizeStyle({height: _ctx.bodyHeight}),
      "data-style": JSON.stringify(_ctx.bodyHeight)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 12 /* STYLE, PROPS */, _hoisted_6),
    (_ctx.hasFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer")
        ], 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ]))
}