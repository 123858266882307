<script  lang="ts">
import ApiService from '@/services/api/ApiService';
import { TaskMapReportDto } from '@/services/api/dto/taskMap/TaskMapReportDto';
import { formatRuDateTime } from '@/utils/formatRuDateTime';
import { Download } from '@element-plus/icons-vue';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'TaskMapReportMapContainer',
  components: {
    Download,
  },
  setup() {
    const reports = ref<TaskMapReportDto[]>([]);
    onMounted(async () => {
      const { data } = await ApiService.taskMap.getTaskMapReport();
      reports.value = data;
    });

    const download = (href: string) => {
      window.open(href);
    };

    return {
      reports,
      formatRuDateTime,
      Download,
      download,
    };
  },
});
</script>

<template lang='pug'>
el-popover(
    placement="right-start"
    :width="320"
    :teleported="false"
    :hide-after="0"
    trigger="click"
)
  template(
    #reference
  )
    .MapContainer-Button(
    )
      ui-icon(
        name="mdiAdjust"
        :size="24"
      )
  .TaskMapReportMapContainer
    .TaskMapReportMapContainer-item(
      v-for="item in [...reports].reverse()"
      :key="item.id"
    )
      .TaskMapReportMapContainer-item-taskName {{item.task_name}}
      .TaskMapReportMapContainer-item-date {{formatRuDateTime(item.create_date)}}
        el-button(
          icon="Download"
          size="small"
          type="primary"
          @click="download(item.file)"
        )
</template>

<style lang="scss">
.TaskMapReportMapContainer{
   height: 100%;
  overflow: auto;
  &-item{
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 5px;
    border: solid 2px var(--color-border);
    border-radius: 4px;
    margin-bottom: 3px;
    &-date{
      font-size: 0.8em;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: var(--color-foreground-extra-light);
    }
  }
}
</style>
