import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("div", {
    class: "NavigationIntegrations",
    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_el_tooltip, { c: "" }, {
      content: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(`${'integration'}`, 'integration', { ns: 'navigation' })), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ui_icon, {
          name: "mdiDotsGrid",
          size: 28,
          color: "#333"
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}