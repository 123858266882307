import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex-row flex-row-justify-between" }
const _hoisted_2 = { style: {float: 'left'} }
const _hoisted_3 = { style: {fontSize: 10+'px',color:'var(--color-foreground-light)'} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ui_dialog = _resolveComponent("ui-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      size: "small",
      type: "primary",
      icon: _ctx.Download,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isModal = true))
    }, null, 8 /* PROPS */, ["icon"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ui_dialog, {
        modelValue: _ctx.isModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isModal) = $event)),
        header: "Скачать карту задания в выбранном формате"
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.download())),
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Скачать")
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Формат карт заданий" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.activeTypeTech,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTypeTech) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.techType, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      value: item.value,
                      key: item.value,
                      label: item.name
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(item.name), 1 /* TEXT */),
                          _createElementVNode("span", _hoisted_3, _toDisplayString(item.type), 1 /* TEXT */)
                        ])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "label"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}