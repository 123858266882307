<template lang='pug'>
.NavigationPanelItem(
  v-if="item.block || item.route || item.callback"
  @click="open(item)"
  :class="{ active: isActive(item) }"
)
  template(v-if="mode === 'collapse'")
    el-tooltip(
      :content="$t(`${item.name}`, item.name, { ns: 'navigation' })"
      placement="right"
    )
      .NavigationPanelItem-itemIcon(
        :class="{ active: isActive(item) }"
        v-if="item?.icon"
      )
        ui-icon(
          :name="icon"
          :size="32"
        )
  template(v-if="mode === 'expand'")
    .NavigationPanelItem-itemIcon(
      :class="{ active: isActive(item) }"
      v-if="item?.icon"
    )
      ui-icon(
        :name="icon"
        :size="32"
      )
    .NavigationPanelItem-itemName(
      :class="{ active: isActive(item) }"
    ) {{ $t(`${item.name}`, item.name, { ns: 'navigation' }) }}
</template>

<script lang='ts'>
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import {
  computed, defineComponent, PropType, toRef,
} from 'vue';
import { NavigationMenuItemType } from '@/constants/types/NavigationMenuItemType';
import { useMapLayout } from '@/composables/useMapLayout';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NavigationPanelItem',
  props: {
    item: {
      type: Object as PropType<NavigationMenuItemType>,
      required: true,
    },
    mode: {
      type: String as PropType<'collapse' | 'expand'>,
      required: true,
    },
  },
  components: { UiIcon },
  setup(props) {
    const router = useRouter();
    const { showBlock } = useMapLayout();

    const isActive = computed(() => (item: NavigationMenuItemType) => false);

    const icon = toRef(`/images/icons/navigation/${props.item.icon}.svg`);

    const open = (item: NavigationMenuItemType) => {
      if (item.block) {
        item.block && showBlock(item.block);
      } else if (item.route) {
        router.push({ name: item.route });
      } else if (item.callback) {
        item.callback();
      }
    };

    return {
      icon,
      open,
      isActive,
    };
  },
});
</script>
<style lang='scss'>
.NavigationPanelItem {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.1s linear;
  &:hover {
    background: var(--color-black-400);
  }
  &.active {
    background: var(--color-dark-accent);
  }

  &-itemIcon {
    margin-left: 5px;
    width: 45px;
    min-width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0 0 8px;
    svg {
      max-width: 32px;
      max-height: 32px;
      height: 100%;
      color: #FFF;
    }
  }
  &-itemName {
    margin-left: 5px;
    height: 45px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 13px;
    min-width: 200px;
    position: relative;
    transition: width 0.15s ease;
    color: #FFF;
    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
