import ApiService from '@/services/api/ApiService';
import { SatelliteRgbFileDto } from '@/services/api/dto/satellites/SatelliteRgbFileDto';
import type { FieldModel } from '@/models/field/FieldModel';
import { FieldEstimationDto } from '@/services/api/dto/satellites/FieldEstimationDto';
import { SatelliteFileModel } from '@/models/satellite/SatelliteFileModel';
import {
  computed, reactive, Ref, ref, UnwrapRef,
} from 'vue';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';

class SatelliteFilesList {
  get files(): Array<UnwrapRef<SatelliteFileModel>> {
    return this._files;
  }

  get active(): Ref<SatelliteFileModel | undefined> {
    return this._active;
  }

  readonly isActive = computed(() => (file: SatelliteFileModel) => this.active.value?.nir.id === file.nir.id)

  private _files = reactive<SatelliteFileModel[]>([] as SatelliteFileModel[]);

  private _active = ref<SatelliteFileModel | undefined>();

  async fetchFieldData(field: FieldModel) {
    LoadingStatus.loading(LoadingNamesEnum.SATELLITE);
    while (this._files.find((r) => r.field.id === field.id)) {
      this._files.splice(this._files.findIndex((r) => r.field.id === field.id), 1);
    }
    try {
      const { data } = await ApiService.satellites.getRgbFiles(field.id);
      ApiService.satellites.getEstimation(field.id).then((response) => {
        data.forEach((file: SatelliteRgbFileDto) => {
          response.data.forEach((estimationDto: FieldEstimationDto) => {
            if (estimationDto.scene === file.scene.id) {
              this._files.push(new SatelliteFileModel(file, estimationDto, field));
            }
          });
        });
        LoadingStatus.success(LoadingNamesEnum.SATELLITE);
      });
    } catch (e) {
      LoadingStatus.error(LoadingNamesEnum.SATELLITE, e);
    }
  }

  setActiveFile(file: SatelliteFileModel | undefined = undefined) {
    if (this._active.value) {
      this._active.value?.setActiveIndexFile();
    }
    this._active.value = file;
  }
}

export default new SatelliteFilesList();
