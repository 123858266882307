<template lang='pug'>
.SettingsPalettePanel
  .SettingsPalettePanel-header {{ $t('palette-name', { ns: 'palette' }) }}
  .SettingsPalettePanel-row
    el-input(
      v-model="name"
      :disabled="!activeNameEdit"
      placeholder="Наименование слоя"
    )
      template(#append)
        el-button(
          circle
          @click="save"
          v-if="activeNameEdit"
          :icon="Check"
        )
        el-button(
          circle
          @click="activeNameEdit = true"
          v-if="!activeNameEdit"
          :icon="Edit"
        )

    el-popconfirm(
      :title="$t('palette-delete-confirm', { ns: 'palette' })"
      @confirm="deletePalette"
      hide-icon
      :confirm-button-type="'danger'"
      :confirm-button-text="$t('delete')"
      :width="250"
    )
      template(#reference)
        el-button(
          circle
          type="danger"
          :icon="Delete"
        )
</template>

<script lang="ts">
import { ImageNodeType } from '@/constants/types/ImageNodeType';
import { defineComponent, PropType, ref } from 'vue';
import { Check, Delete, Edit } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'SettingsPalettePanel',
  props: {
    node: {
      type: Object as PropType<ImageNodeType>,
      required: true,
    },
  },
  emits: ['delete-palette', 'save-name'],
  setup(props, { emit }) {
    const activeNameEdit = ref(false);
    const name = ref(props.node.label);

    const save = () => {
      // eslint-disable-next-line vue/no-mutating-props
      props.node.label = name.value;

      emit('save-name', props.node);
      activeNameEdit.value = false;
    };

    const deletePalette = () => {
      emit('delete-palette', props.node);
    };

    return {
      Edit,
      Check,
      Delete,
      activeNameEdit,
      save,
      deletePalette,
      name,
    };
  },
});
</script>

<style lang="scss">
.SettingsPalettePanel {
  background: #F6F7F9;
  padding: 10px 20px 15px 20px;
  &-header {
    color: var(--color-black-300);
    padding-bottom: 4px;
  }
  &-row {
    display: grid;
    grid-template-columns: 1fr 24px;
    column-gap: 10px;
  }
}
</style>
