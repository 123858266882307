import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "POIEditBlock" }
const _hoisted_2 = {
  key: 0,
  style: {"font-weight":"400","color":"#999","margin-left":"0.5rem","white-space":"nowrap"}
}
const _hoisted_3 = {
  key: 3,
  class: "flex-row fw flex-align-center"
}
const _hoisted_4 = { class: "ml-6" }
const _hoisted_5 = { class: "ml-4" }
const _hoisted_6 = { class: "POIEditBlock_Footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ui_dialog = _resolveComponent("ui-dialog")!

  return (_openBlock(), _createBlock(_component_ui_dialog, {
    modelValue: _ctx.open,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.open) = $event)),
    header: _ctx.$t('edit-params', 'edit params', { ns: 'poi' }),
    width: "600",
    top: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          model: _ctx.poiFormInfo,
          "label-width": "250px",
          "label-position": "left",
          rules: _ctx.rules,
          ref: "POIFormRef"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.activeTabsEdit,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeTabsEdit) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: _ctx.$t('main', 'main', { ns: 'poi' }),
                  name: "Info"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('name', 'name', { ns: 'poi' }),
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          placeholder: _ctx.$t('input-name', 'input-name', { ns: 'poi' }),
                          modelValue: _ctx.poiFormInfo.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.poiFormInfo.name) = $event)),
                          "show-word-limit": "",
                          maxlength: "80"
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('note', 'note', { ns: 'poi' })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          rows: "3",
                          type: "textarea",
                          placeholder: _ctx.$t('input-params-note', 'input-params-note', { ns: 'poi' }),
                          modelValue: _ctx.poiFormInfo.descr,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.poiFormInfo.descr) = $event)),
                          maxlength: _ctx.POIParams.description.maxLength,
                          "show-word-limit": ""
                        }, null, 8 /* PROPS */, ["placeholder", "modelValue", "maxlength"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label:  _ctx.$t('point-visited', 'point-visited', { ns: 'poi' })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: _ctx.poiFormInfo.visited,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.poiFormInfo.visited) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTabs, (tab) => {
                  return (_openBlock(), _createBlock(_component_el_tab_pane, {
                    label:  _ctx.$t(`${tab}`, tab, { ns: 'poi' }),
                    name: tab,
                    key: tab
                  }, {
                    default: _withCtx(() => [
                      ( tab === 'no-standard' )
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.poiFormInfo.params, (item, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                          (item.category === tab)
                            ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                                label: _withCtx(() => [
                                  _createElementVNode("div", null, [
                                    _createTextVNode(_toDisplayString(_ctx.$t(`${item.key}`, item.key, { ns: 'poi' })), 1 /* TEXT */),
                                    (item.unit)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(item.unit || '') + ")", 1 /* TEXT */))
                                      : _createCommentVNode("v-if", true)
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  (item.type === 'string')
                                    ? (_openBlock(), _createBlock(_component_el_input, {
                                        key: 0,
                                        placeholder:  _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                        modelValue: item.value,
                                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                        maxlength: item.maxLength || 0,
                                        "show-word-limit": !!item.maxLength
                                      }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onUpdate:modelValue", "maxlength", "show-word-limit"]))
                                    : _createCommentVNode("v-if", true),
                                  (item.type === 'stars')
                                    ? (_openBlock(), _createBlock(_component_el_rate, {
                                        key: 1,
                                        modelValue: item.value,
                                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                        max: 3,
                                        clearable: ""
                                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                                    : _createCommentVNode("v-if", true),
                                  (item.type === 'select')
                                    ? (_openBlock(), _createBlock(_component_el_select, {
                                        key: 2,
                                        modelValue: item.value,
                                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                        placeholder: "--"
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.select, (item) => {
                                            return (_openBlock(), _createBlock(_component_el_option, {
                                              key: item.value,
                                              label: item.title || item.label,
                                              value: item.value
                                            }, null, 8 /* PROPS */, ["label", "value"]))
                                          }), 128 /* KEYED_FRAGMENT */))
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]))
                                    : _createCommentVNode("v-if", true),
                                  (item.type === 'soilIndex')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _createVNode(_component_el_input, {
                                          placeholder: _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                          modelValue: item.value,
                                          "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                          "show-word-limit": !!item.maxLength,
                                          maxlength: item.maxLength || 0
                                        }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onUpdate:modelValue", "show-word-limit", "maxlength"]),
                                        _createElementVNode("div", _hoisted_4, [
                                          _createVNode(_component_el_button, {
                                            onClick: ($event: any) => (_ctx.addArrow(item,'↑'))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("↑")
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                        ]),
                                        _createElementVNode("div", _hoisted_5, [
                                          _createVNode(_component_el_button, {
                                            onClick: ($event: any) => (_ctx.addArrow(item,'↓'))
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("↓")
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                        ])
                                      ]))
                                    : _createCommentVNode("v-if", true),
                                  (item.type === 'number')
                                    ? (_openBlock(), _createBlock(_component_el_input_number, {
                                        key: 4,
                                        placeholder: _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                        "model-value": item.value,
                                        "value-on-clear": null,
                                        onChange: ($event: any) => (_ctx.changeInputNumber($event,item)),
                                        min: item.min,
                                        max: item.max
                                      }, null, 8 /* PROPS */, ["placeholder", "model-value", "onChange", "min", "max"]))
                                    : _createCommentVNode("v-if", true),
                                  (item.type === 'int')
                                    ? (_openBlock(), _createBlock(_component_el_input_number, {
                                        key: 5,
                                        placeholder: _ctx.$t('input-params', 'input-params', { ns: 'poi' }),
                                        onChange: ($event: any) => (_ctx.changeInputNumber($event,item)),
                                        min: item.min,
                                        max: item.max,
                                        "model-value": item.value,
                                        "value-on-clear": null
                                      }, null, 8 /* PROPS */, ["placeholder", "onChange", "min", "max", "model-value"]))
                                    : _createCommentVNode("v-if", true),
                                  (item.type === 'boolean')
                                    ? (_openBlock(), _createBlock(_component_el_switch, {
                                        key: 6,
                                        modelValue: item.value,
                                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event)
                                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "name"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model", "rules"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSubmit(_ctx.POIFormRef)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('save', 'save')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_button, {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.open = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('close', 'close')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "header"]))
}