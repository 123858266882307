import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MapContainer-ButtonsGroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tooltip, {
      placement: "left-start",
      content: _ctx.$t('move-to-home',{ns:'mapbox'})
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "MapContainer-Button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fitStruct && _ctx.fitStruct(...args)))
        }, [
          _createVNode(_component_ui_icon, {
            name: "/images/icons/home.svg",
            size: 26
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["content"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_tooltip, {
        placement: "left-start",
        content: "Увеличить зум"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "MapContainer-Button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.zoomIn && _ctx.zoomIn(...args)))
          }, [
            _createVNode(_component_ui_icon, {
              name: "CloseBold",
              size: 19,
              style: {"transform":"rotate(45deg)"}
            })
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_tooltip, {
        placement: "left-start",
        content: "Уменьшить зум"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "MapContainer-Button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.zoomOut && _ctx.zoomOut(...args)))
          }, [
            _createVNode(_component_ui_icon, {
              name: "SemiSelect",
              size: 19
            })
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_tooltip, {
        placement: "left-start",
        content: "Повернуться на север"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "MapContainer-Button",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.rotateZero && _ctx.rotateZero(...args)))
          }, [
            _createVNode(_component_ui_icon, {
              style: _normalizeStyle(_ctx.compassStyleRotate),
              name: "/images/icons/compass.svg",
              size: 26
            }, null, 8 /* PROPS */, ["style"])
          ])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ], 64 /* STABLE_FRAGMENT */))
}