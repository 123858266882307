<template lang="pug">
.LegendTable
  .LegendTable-row(
    v-for="item in data"
    :key="item.label"
  )
    .LegendTable-value
      .LegendTable-value-color(
        :style="`background-color: ${item.color}`"
      )
      .LegendTable-value-label(
        v-html="label(item)"
      )
  .LegendTable-empty(v-if="data.length === 0") {{ $t('empty-legend-table-message') }}
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useTranslate } from '@tolgee/vue';

export default defineComponent({
  name: 'LegendTable',
  props: {
    property: {
      type: String,
      default: '',
    },
    data: {
      type: Array as PropType<{ color: string, label: string }[]>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useTranslate('mapbox');
    const label = computed(() => (item: { color: string, label: string }) => {
      if (props.property.startsWith('default')) {
        return t.value(`legends-${props.property}-${item.label}`, item.label);
      }
      return item.label;
    });
    return {
      label,
    };
  },
});
</script>

<style lang="scss" scoped>
.LegendTable {
  display: grid;
  grid-template-columns: 1fr;
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
    font-size: .9rem;
    font-weight: 500;
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, .1);
    }
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, .02);
    }
  }
  &-value {
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;
    width: 100%;
    &-color {
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
    &-label {
      text-align: left;
      line-height: 20px;
      color: var(--color-black-400);
    }
  }
  &-empty {
    white-space: wrap;
    width: 230px;
    text-align: center;
    font-size: 1.1rem;
    color: var(--color-black-200);
  }
}
</style>
