import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_POIEditBlock = _resolveComponent("POIEditBlock")!
  const _component_FieldEditBlock = _resolveComponent("FieldEditBlock")!
  const _component_SidePanelContent = _resolveComponent("SidePanelContent")!

  return (_openBlock(), _createBlock(_component_SidePanelContent, {
    onClose: _ctx.close,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      (_ctx.activePoi && _ctx.informationMode === 'poi')
        ? (_openBlock(), _createBlock(_component_POIEditBlock, {
            key: 0,
            poi: _ctx.activePoi
          }, null, 8 /* PROPS */, ["poi"]))
        : _createCommentVNode("v-if", true),
      (_ctx.activeField && _ctx.informationMode === 'field')
        ? (_openBlock(), _createBlock(_component_FieldEditBlock, {
            key: 1,
            field: _ctx.activeField
          }, null, 8 /* PROPS */, ["field"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClose", "title"]))
}