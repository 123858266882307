import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "NavigationPanel-block" }
const _hoisted_2 = { class: "NavigationPanel-items" }

export function render(_ctx, _cache) {
  const _component_NavigationPanelItem = _resolveComponent("NavigationPanelItem")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["NavigationPanel", { hovered: (_ctx.submenuHovered && _ctx.menu.length > 0) || _ctx.submenuActive, [`--mode-${_ctx.mode}`]: true }]),
    onMouseenter: _cache[0] || (_cache[0] = $event => (_ctx.submenuHovered = true)),
    onMouseleave: _cache[1] || (_cache[1] = $event => (_ctx.submenuHovered = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['top', 'bottom'], (placement) => {
        return _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.NavigationMenuItems.filter((v) => v.placement === placement), (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children || [], (child) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_ctx.menuItemPermission(child))
                    ? (_openBlock(), _createBlock(_component_NavigationPanelItem, {
                        key: 0,
                        item: child,
                        mode: _ctx.mode
                      }, null, 8 /* PROPS */, ["item", "mode"]))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 256 /* UNKEYED_FRAGMENT */)),
              (placement === 'bottom')
                ? (_openBlock(), _createBlock(_component_NavigationPanelItem, {
                    key: 0,
                    item: _ctx.collapseItem,
                    mode: _ctx.mode
                  }, null, 8 /* PROPS */, ["item", "mode"]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      }), 64 /* STABLE_FRAGMENT */))
    ])
  ], 34 /* CLASS, NEED_HYDRATION */))
}