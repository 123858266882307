<template lang="pug">
.MapContainer-ButtonsHorizontalGroup.hide-medium
  el-tooltip(
    placement="right-start"
    :hide-after="0"
    content="Инструмент линейки"
  )
    .MapContainer-Button(
      :class="{ active:isRulerActive ,disabled: activeDrawer}"
      @click="toggleRulerActive"

    )
      ui-icon(
        name="mdiRuler"
        :size="24"
      )
  el-tooltip(
    placement="right-start"
    :hide-after="0"
    content="Инструмент расчета плошади"
  )
    .MapContainer-Button(
      :class="{ active: isAreaActive ,disabled: activeDrawer}"
      @click="toggleAreaActive"

    )
      ui-icon(
        name="mdiTextureBox"
        :size="24"
      )
</template>
<script lang="ts">
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapAreaModel } from '@/models/map/data/MapAreaModel';
import { MapRulerModel } from '@/models/map/data/MapRulerModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { MapLayerRulerModel } from '@/models/map/Layers/MapLayerRulerModel';
import { MapModel } from '@/models/map/MapModel';
import {
  defineComponent, onMounted, PropType, reactive, ref, UnwrapRef, watch,
} from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { useDrawer } from '@/composables/useDrawer';

export default defineComponent({
  name: 'CalculateTool',
  props: {
    map: {
      type: Object as PropType<UnwrapRef<MapModel>>,
      required: true,
    },
  },
  setup(props) {
    const map = reactive(props.map);
    const {
      helper,
      isHelperActive,

    } = useMapContainers(props.map.container as MapContainerEnum);

    const { activeDrawer } = useDrawer();
    const isAreaActive = ref(false);
    const isRulerActive = ref(false);
    const _areaModel = new MapAreaModel();
    const _rulerModel = new MapRulerModel();
    const areaModel = reactive(_areaModel);
    const areaLayer = ref<MapLayerDrawerModel>();
    const rulerModel = reactive(_rulerModel);
    const rulerLayer = ref<MapLayerRulerModel>();

    const fieldBlock = ref(false);

    const toggleAreaActive = () => {
      isAreaActive.value = !isAreaActive.value;
    };
    const toggleRulerActive = () => {
      isRulerActive.value = !isRulerActive.value;
      if (!isRulerActive.value) {
        _rulerModel.clearFeatures();
        rulerLayer.value?.closePopup();
      }
    };

    // watch(isAreaActive, (a) => {
    //   if (a) {
    //     _areaModel.clearCoordinates();
    //     areaLayer.value = map.render(_areaModel) as MapLayerDrawerModel;
    //     areaLayer.value.setActiveMode('create');
    //     if (isRulerActive.value) {
    //       isRulerActive.value = false;
    //       rulerModel.clearFeatures();
    //       rulerLayer.value?.closePopup();
    //     }
    //   } else {
    //     areaLayer.value.setActiveMode('none');
    //     areaLayer.value.offEventListener();
    //     areaModel.clearCoordinates();
    //     map.removeLayer(areaLayer.value.uuid);
    //     helper.value = '';
    //   }
    // });
    watch(isAreaActive, (a) => {
      if (a) {
        _areaModel.clearCoordinates();
        areaLayer.value = map.render(_areaModel) as MapLayerDrawerModel;

        if (areaLayer.value) {
          areaLayer.value.setActiveMode('create');
        }

        if (isRulerActive.value) {
          isRulerActive.value = false;
          rulerModel.clearFeatures();
          rulerLayer.value?.closePopup();
        }
      } else {
        if (areaLayer.value) {
          areaLayer.value.setActiveMode('none');
          areaLayer.value.offEventListener();
          areaModel.clearCoordinates();
          map.removeLayer(areaLayer.value.uuid);
        }
        helper.value = '';
      }
    });
    // watch(isRulerActive, (a) => {
    //   if (a) {
    //     rulerLayer.value = map.render(_rulerModel) as MapLayerRulerModel;
    //     if (isAreaActive.value) {
    //       isAreaActive.value = false;
    //       areaLayer.value.setActiveMode('none');
    //       areaLayer.value.offEventListener();
    //       areaModel.clearCoordinates();
    //       map.removeLayer(areaLayer.value.uuid);
    //     }
    //   } else {
    //     map.removeLayer(rulerLayer.value.uuid);
    //   }
    // });
    watch(isRulerActive, (a) => {
      if (a) {
        rulerLayer.value = map.render(_rulerModel) as MapLayerRulerModel;

        if (isAreaActive.value) {
          isAreaActive.value = false;

          if (areaLayer.value) {
            areaLayer.value.setActiveMode('none');
            areaLayer.value.offEventListener();
            areaModel.clearCoordinates();
            map.removeLayer(areaLayer.value.uuid);
          }
        }
      } else if (rulerLayer.value) {
        map.removeLayer(rulerLayer.value.uuid);
        rulerLayer.value?.closePopup();
      }
    });

    onMounted(() => {
      document.addEventListener('click', (ev: MouseEvent) => {
        if ((ev?.target as HTMLElement).id && (ev.target as HTMLElement).id === 'map-ruler-close') {
          toggleRulerActive();
        }
      });
    });
    map.events.onUpdateDrawerMode((mode) => {
      // if (mode === 'edit') {
      //   helper.value = editHelperHtml;
      // } if (mode === 'create') {
      //   helper.value = createHelperHtml;
      // } if (mode === 'none') {
      //   helper.value = '';
      // }
    });

    watch(activeDrawer, (v: boolean) => {
      if (v) {
        isAreaActive.value = false;
        isRulerActive.value = false;
      }
    });
    return {
      isAreaActive,
      isRulerActive,
      toggleAreaActive,
      toggleRulerActive,
      areaModel,
      rulerModel,
      fieldBlock,
      activeDrawer,
    };
  },
});
</script>

<style lang="scss">

</style>
