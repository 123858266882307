import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vue_json_pretty = _resolveComponent("vue-json-pretty")!
  const _component_ui_dialog = _resolveComponent("ui-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = true))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_ui_dialog, {
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
      header: _ctx.title,
      width: 600
    }, {
      default: _withCtx(() => [
        _createVNode(_component_vue_json_pretty, { data: _ctx.json }, null, 8 /* PROPS */, ["data"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "header"])
  ], 64 /* STABLE_FRAGMENT */))
}