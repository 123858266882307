import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "MonitoringPanel-wrap"
}
const _hoisted_2 = { class: "MonitoringPanel-data" }
const _hoisted_3 = { class: "menu-sub-header ml-6" }
const _hoisted_4 = { class: "menu-block" }
const _hoisted_5 = { class: "menu-block" }
const _hoisted_6 = { class: "menu-block" }
const _hoisted_7 = { class: "MonitoringPanel-indexes ph-10" }
const _hoisted_8 = ["data-group"]
const _hoisted_9 = { class: "menu-block" }
const _hoisted_10 = { class: "menu-sub-header" }
const _hoisted_11 = { class: "MonitoringPanel-indexes-items" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "menu-sub-header mt-8" }
const _hoisted_14 = { class: "menu-block" }
const _hoisted_15 = { class: "menu-block ph-10" }
const _hoisted_16 = { class: "menu-sub-header" }
const _hoisted_17 = { class: "MonitoringPanel-date" }
const _hoisted_18 = { class: "menu-block ph-10" }
const _hoisted_19 = { class: "MonitoringPanel-nir" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "MonitoringPanel-nir-image-caption" }
const _hoisted_23 = {
  key: 2,
  class: "p-20"
}
const _hoisted_24 = { class: "MonitoringPanel-indexes-files" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "MonitoringPanel-indexes-file-caption" }

export function render(_ctx, _cache) {
  const _component_NotFound = _resolveComponent("NotFound")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_IndexesInfo = _resolveComponent("IndexesInfo")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_TaskMapSelector = _resolveComponent("TaskMapSelector")
  const _component_el_skeleton = _resolveComponent("el-skeleton")
  const _component_NoActiveField = _resolveComponent("NoActiveField")
  const _component_ui_dialog = _resolveComponent("ui-dialog")
  const _component_SidePanelContent = _resolveComponent("SidePanelContent")

  return (_openBlock(), _createBlock(_component_SidePanelContent, {
    title: _ctx.$t('monitoring', { ns: 'route' }),
    style: {"overflow":"hide"}
  }, {
    default: _withCtx(() => [
      (_ctx.activeField && _ctx.active)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.activeFile === undefined && _ctx.loading)
              ? (_openBlock(), _createBlock(_component_NotFound, {
                  key: 0,
                  message: "По данному полю нет снимков"
                }))
              : (_ctx.activeFile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createTextVNode("Спутниковые снимки"),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_el_switch, {
                            modelValue: _ctx.showNir,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.showNir) = $event)),
                            "active-text": _ctx.$t('monitoring-show-image-checkbox', { ns: 'monitoring' })
                          }, null, 8 /* PROPS */, ["modelValue", "active-text"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_el_switch, {
                            modelValue: _ctx.cloudsFilter,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.cloudsFilter) = $event)),
                            "active-text": "Скрыть облачные снимки"
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_el_switch, {
                            modelValue: _ctx.showCloudMask,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.showCloudMask) = $event)),
                            "active-text": _ctx.$t('monitoring-show-cloud-mask-checkbox', { ns: 'monitoring' })
                          }, null, 8 /* PROPS */, ["modelValue", "active-text"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indexesKinds?.groups || [], (group) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "MonitoringPanel-indexes-group",
                            "data-group": group
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, _toDisplayString(group.label), 1 /* TEXT */),
                              _createElementVNode("div", _hoisted_11, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.indexesKinds?.kinds || []).filter((k) => k.groupId === group.id), (kind) => {
                                  return (_openBlock(), _createBlock(_component_el_tooltip, {
                                    placement: "top",
                                    "show-after": 500
                                  }, {
                                    content: _withCtx(() => [
                                      _createVNode(_component_IndexesInfo, {
                                        index: kind.name
                                      }, null, 8 /* PROPS */, ["index"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createElementVNode("div", {
                                        class: _normalizeClass(["MonitoringPanel-indexes-item", { active: _ctx.selectedIndex && _ctx.selectedIndex.name === kind.name }]),
                                        onClick: $event => (_ctx.indexMenuItemClicked(kind))
                                      }, _toDisplayString(kind.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_12)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                }), 256 /* UNKEYED_FRAGMENT */))
                              ])
                            ])
                          ], 8 /* PROPS */, _hoisted_8))
                        }), 256 /* UNKEYED_FRAGMENT */)),
                        _createElementVNode("div", _hoisted_13, [
                          _createTextVNode("Настройка отображения индекса"),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_el_switch, {
                              modelValue: _ctx.showContrast,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.showContrast) = $event)),
                              "active-text": _ctx.$t('monitoring-show-contrast-checkbox', { ns: 'monitoring' })
                            }, null, 8 /* PROPS */, ["modelValue", "active-text"])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createTextVNode("Контур карт заданий"),
                          _createVNode(_component_TaskMapSelector, {
                            ref: "taskSelectorRef",
                            modelValue: _ctx.buffTaskModel,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.buffTaskModel) = $event)),
                            "task-maps": _ctx.taskMaps,
                            title: _ctx.$t('monitoring-task-map-selector-title', { ns: 'monitoring' }),
                            render: true,
                            actions: ['opacity',  'select-fill'],
                            isCompare: _ctx.isCompare
                          }, null, 8 /* PROPS */, ["modelValue", "task-maps", "title", "isCompare"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeField?.nirFiles, (file) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (!_ctx.cloudsFilter ? true : file.cloudiness <= 30)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(["MonitoringPanel-nir-image", { active: _ctx.activeFile?.uuid === file.uuid }]),
                                    onClick: $event => {_ctx.activeFile = file; _ctx.showSelectFile = false;}
                                  }, [
                                    _createElementVNode("img", {
                                      src: file.thumbnailUrl,
                                      width: "65",
                                      height: "65"
                                    }, null, 8 /* PROPS */, _hoisted_21),
                                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.formatRuDate(file.scene.datetime.toString())), 1 /* TEXT */)
                                  ], 10 /* CLASS, PROPS */, _hoisted_20))
                                : _createCommentVNode("v-if", true)
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 256 /* UNKEYED_FRAGMENT */)),
                          _createCommentVNode(".MonitoringPanel-nir-props"),
                          _createCommentVNode("  div"),
                          _createCommentVNode("    .mb-4.fs-12 {{ $t('monitoring-select-nir-file-title', { ns: 'monitoring' }) }}"),
                          _createCommentVNode("    el-button("),
                          _createCommentVNode("      @click=\"showSelectFile = !showSelectFile\""),
                          _createCommentVNode("      :class=\"{ active: showSelectFile }\""),
                          _createCommentVNode("    )"),
                          _createCommentVNode("      ui-icon("),
                          _createCommentVNode("        name=\"mdiCalendarMonthOutline\""),
                          _createCommentVNode("        color=\"inherit\""),
                          _createCommentVNode("      )"),
                          _createCommentVNode("      .pl-5 {{ formatRuDate(activeFile?.scene.datetime.toString()) || '' }}"),
                          _createCommentVNode(""),
                          _createCommentVNode("  el-switch("),
                          _createCommentVNode("    v-model=\"showNir\""),
                          _createCommentVNode("    :active-text=\"$t('monitoring-show-image-checkbox', { ns: 'monitoring' })\""),
                          _createCommentVNode("  )")
                        ])
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createVNode(_component_el_skeleton, {
                      rows: 5,
                      animated: ""
                    })
                  ]))
          ], 64 /* STABLE_FRAGMENT */))
        : (_openBlock(), _createBlock(_component_NoActiveField, { key: 1 })),
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        _createVNode(_component_ui_dialog, {
          modelValue: _ctx.showSelectFile,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.showSelectFile) = $event)),
          header: _ctx.$t('monitoring-nir-file', { ns: 'monitoring' }),
          width: 850,
          top: "15px"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('monitoring-nir-file', { ns: 'monitoring' })), 1 /* TEXT */),
            _createVNode(_component_el_switch, {
              class: "pt-6",
              modelValue: _ctx.cloudsFilter,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.cloudsFilter) = $event)),
              "active-text": "Отображать облачные сники"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_24, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeField?.nirFiles, (file) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_ctx.cloudsFilter ? true : file.cloudiness <= 30)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["MonitoringPanel-indexes-file", { active: _ctx.activeFile?.uuid === file.uuid }]),
                        onClick: $event => {_ctx.activeFile = file; _ctx.showSelectFile = false;}
                      }, [
                        _createElementVNode("img", {
                          src: file.thumbnailUrl
                        }, null, 8 /* PROPS */, _hoisted_26),
                        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.formatRuDate(file.scene.datetime.toString())), 1 /* TEXT */)
                      ], 10 /* CLASS, PROPS */, _hoisted_25))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 256 /* UNKEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "header"])
      ]))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}