<template lang="pug">
.ph-20
  el-image(
  v-if="legendType === 'raster'"
    :src="legend.image"
    style="max-width: 300px; max-height: 300px"
    :preview-src-list="[legend.image]"
    preview-teleported
  )
  template(
    v-else
  )
    LegendTable(
      :data="data"
    )
</template>

<script  lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import LegendTable from '@/modules/map/container/MapLegend/LegendTable.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerRasterModel } from '@/models/map/Layers/MapLayerRasterModel';

export default defineComponent({
  name: 'RasterLegend',
  components: { LegendTable },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    layer: {
      type: Object as PropType<MapLayerRasterModel>,
      required: true,
    },
  },
  setup(props) {
    const legend = computed(() => props.layer.data.legend);

    const legendType = computed(() => props.layer.data.legend.type);
    const legendStops = computed(() => props.layer.data.legend.stops);
    const min = computed(() => props.layer.data.minValue);
    const max = computed(() => props.layer.data.maxValue);

    const getValueByPercentage = (percentage: number) => {
      if (percentage < 0 || percentage > 100) {
        throw new Error('Процент должен быть в диапазоне от 0 до 100');
      }
      return min.value + (max.value - min.value) * (percentage / 100);
    };

    const calculateLabel = (value: number, label: string) => (props.layer.data.legend?.valyeType === 'exact' ? label : getValueByPercentage(value));

    const data = computed(() => {
      if (legendType.value === 'gradient' && legend.value.label === 'both') {
        const stops = legendStops.value.map((v, idx, arr) => {
          const nextStop = arr[idx + 1];

          if (!nextStop && idx > 0) {
            return null;
            // const prevStop = arr[idx - 1];
            // return {
            //   color: v.color,
            //   label: `${calculateLabel(prevStop.value, prevStop.label)} - ${calculateLabel(v.value, v.label)}`,
            // };
          }

          if (nextStop && v.color === nextStop.color) {
            return null;
          }

          const label = nextStop ? `${calculateLabel(v.value, v.label)} - ${calculateLabel(nextStop.value, nextStop.label)}` : null;
          return label ? { color: v.color, label } : null;
        });

        return stops.filter(Boolean).reverse(); // Убираем null элементы
      }
      if (legendType.value === 'table') {
        return legendStops.value.map((v) => ({ label: calculateLabel(v.value, v.label), color: v.color }));
      } return [];
    });

    return {
      legend,
      legendType,
      data,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
