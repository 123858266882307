<template lang="pug">
.FieldSplittingBlock
  .flex-col.flex-col-justify-between
    .flex-col
      .flex-row.flex-row-justify-between
        el-button(
          @click="isOpenAddForm = true"
          :disabled="selectedGeomIndex.length === activeFieldToFields.length"
        ) Добавить поле
        el-button(
          @click="clean"
          type="danger"
        )  {{ $t('clean') }}
      div(v-for="(f,idx) of data")
        .flex-row.flex-row-justify-between.pt-6
          FieldView(
            :preview="new FieldPreviewModel(f.geom.coordinates)"
          )
          .flex-col
            div {{f.name}}
            div {{formatArea(f.sq_acc)}}
    .flex-row.flex-row-justify-between.pt-8
        el-button(
          :disabled="selectedGeomIndex.length !== activeFieldToFields.length"
          @click="save"
        )  {{ $t('save') }}

        el-button(
          type="danger"
          @click="close()"
        ) {{$t('close')}}
teleport(
  to="body"
)
  ui-dialog(
    v-model="isOpenAddForm"
  )
    el-form(
      label-position="top"
    )
      el-form-item(
        label="Название поля"
      )
        el-input(
          v-model="form.name"
        )
      el-form-item(
        label="Описание"
      )
        el-input(
          v-model="form.description"
        )
    el-checkbox-group(
      v-model="form.selectedFields"
    )
     div {{selectedGeomIndex}}
      template(
        v-for="(f, idx) of activeFieldToFields"
      )
        div(
          v-if="selectedGeomIndex.some((a) => a !== idx) || selectedGeomIndex.length === 0"
        ).mb-6
          el-checkbox(
            :value="idx"
          )
            FieldView(
              :preview="f.preview"
            )
    template(#footer)
        el-button(
          :disabled="disabledAddButton"
          @click="addField"
        ) Добавить
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { FieldPreviewModel } from '@/models/field/FieldPreviewModel';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import { polygon, multiPolygon, area } from '@turf/turf';
import { Feature, MultiPolygon } from 'geojson';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import ApiService from '@/services/api/ApiService';
import SeasonList from '@/modules/season/SeasonList';
import FieldsList from '@/modules/fields/FieldsList';
import { useStruct } from '@/composables/useStruct';
import { formatArea } from '@/utils/formatArea';

export default defineComponent({
  name: 'FieldSplittingBlock',
  emits: ['close'],
  components: {
    FieldView,
    UiDialog,
  },
  setup(props, { emit }) {
    const { activeField } = useMapContainers();
    const { structId } = useStruct();
    const fieldsGroup = ref([]);
    const isOpenAddForm = ref(false);

    const activeFieldToFields = computed(() => activeField.value.feature.geometry.coordinates.map((field) => {
      const result = {
        preview: new FieldPreviewModel([field]),
        geometry: field,
      };
      return result;
    }));

    const form = ref({
      name: '',
      description: '',
      selectedFields: [],
    });

    const data = ref<
      {
        id: number;
        'source_fields': number[],
        name: string,
        descr: string,
        'sq_acc': 0,
        geom: MultiPolygon
      }[]
    >([]);

    const selectedGeomIndex = ref<number[]>([]);

    const calculateFeature = () => {
      const arr = [];
      form.value.selectedFields.forEach((g) => {
        arr.push(activeFieldToFields.value[g].geometry);
      });

      return (multiPolygon(arr) as Feature<MultiPolygon>);
    };

    const close = () => {
      emit('close');
    };
    const addField = () => {
      selectedGeomIndex.value.push(...form.value.selectedFields);
      data.value.push({
        id: data.value.length ? undefined : activeField.value.id,
        source_fields: [],
        name: form.value.name,
        descr: form.value.description,
        sq_acc: area(calculateFeature()),
        geom: calculateFeature().geometry,
      });

      form.value = {
        name: '',
        description: '',
        selectedFields: [],
      };
      isOpenAddForm.value = false;
    };
    const save = async () => {
      await ApiService.struct.postMutate({
        mutations: data.value,
        season: SeasonList.activeSeason.value.id,
      });
      FieldsList.fetchFields(structId.value, true);
      close();
    };

    const clean = () => {
      selectedGeomIndex.value = [];
      data.value = [];
    };

    watch(activeFieldToFields, (a) => {
      fieldsGroup.value = [...a].map(() => 0);
    });

    const checkSomeName = computed(() => FieldsList.fields.some((field) => field.name === form.value.name) || data.value.some((d) => form.value.name === d.name));

    const disabledAddButton = computed(() => checkSomeName.value || form.value.name.length <= 1 || form.value.selectedFields.length === 0);
    onMounted(() => {
      fieldsGroup.value = activeFieldToFields.value.map(() => 0);
    });
    return {
      fieldsGroup,
      activeFieldToFields,
      save,
      selectedGeomIndex,
      form,
      isOpenAddForm,
      addField,
      data,
      FieldPreviewModel,
      clean,
      formatArea,
      close,
      checkSomeName,
      disabledAddButton,
    };
  },
});
</script>

<style lang="scss">

</style>
