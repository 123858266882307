import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["NavigationAdmin", { active: _ctx.activeSector === _ctx.sector}]),
    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_el_tooltip, { content: "Настройки подразделения" }, {
      content: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(`${'struct-settings'}`, 'struct-settings', { ns: 'navigation' })), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ui_icon, {
          name: "mdiCogs",
          size: 28,
          color: "#333"
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}