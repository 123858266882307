<script lang="ts">
import {
  computed, defineComponent, PropType, reactive, watch,
} from 'vue';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldModel } from '@/models/field/FieldModel';
import ApiService from '@/services/api/ApiService';
import { Loading, Select } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'NdviCheckbox',
  components: {
    Select,
    Loading,
  },
  props: {
    ndvi: {
      type: Object as PropType<FieldAverageIndexModel>,
      required: true,
    },
    field: {
      type: Object as PropType<FieldModel>,
      required: true,
    },
  },

  setup(props) {
    const ndvi = reactive<FieldAverageIndexModel>(props.ndvi);
    const field = reactive<FieldModel>(props.field);
    const onClick = () => {
      if (ndvi?.estimationUser === 0) {
        ndvi.estimationUser = (ndvi.estimationAuto === 1) ? -1 : 1;
      } else {
        ndvi.estimationUser = (ndvi.estimationUser === 1) ? -1 : 1;
      }
      ApiService.monitoring.patchAverageIndexes(ndvi.estimationUser, field.id, ndvi.id);
    };

    const formatNumberToBoolean = computed(() => (value: number) => value > 0);

    const computedEstimation = computed(() => (ndvi.estimationUser !== 0 ? formatNumberToBoolean.value(ndvi.estimationUser) : formatNumberToBoolean.value(ndvi.estimationAuto)));

    return {
      onClick,
      computedEstimation,
    };
  },
});
</script>

<template lang="pug">
.NdviCheckbox(
  :class="{ checked: computedEstimation,  userEstimation: ndvi.estimationUser !== 0 }"
  @click.stop="onClick"
)
    el-icon(
    :size="15"
    )
      Select(
      v-if="computedEstimation"
      )
</template>

<style  lang="scss">
.NdviCheckbox {
  border: 2px solid var(--color-foreground-extra-light);
  border-radius: 5px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition-default);

  &.userEstimation{
    border-color: #000000;

    &.checked{
      border-color: #000000;
      .el-icon {
        color: #000000;
      }
    }
  }
  &.checked {
    border-color: var(--color-foreground-extra-light);
    .el-icon {
      color: var(--color-foreground-extra-light);
    }

  }

  &.disabled {
    border-color: var(--color-foreground-light);
    background-color: rgba(0, 0, 0, .1);
    cursor: default;
  }

  .el-icon {
    color: var(--color-foreground-extra-light);
    transition: var(--transition-default);
  }
}

</style>
