<template lang="pug">
.ImagesContainer-file
  .ImagesContainer-file-icon
    ui-icon(
      name="mdiFileImageOutline"
      color="#C66300"
      :size="32"
    )
  .ImagesContainer-file-info
    .ImagesContainer-file-label {{ file.alias }}
    .ImagesContainer-file-date {{ formatRuDate(file.date) }}
  .ImagesContainer-file-actions

    el-tooltip(
      :content="$t('delete-file', { ns: 'side-panel' })"
      :show-after="500"
      v-if="file.scope === 'user' || (file.scope === 'expert' && isStaff)"
    )
      el-button(
        circle
        @click="$emit('delete')"
        type="danger"
      )
        ui-icon(
          name="mdiDeleteOutline"
        )
    el-tooltip(
      :content="$t('download-file', { ns: 'side-panel' })"
      :show-after="500"
      v-if="file.scope === 'user' || (file.scope === 'expert' && isStaff)"
    )
      el-button(
        circle
        @click="$emit('download','raster')"
        type="info"
      )
        ui-icon(
          name="mdiDownloadCircleOutline"
        )

    el-tooltip(
      :content="$t('add-file-to-layers-list', { ns: 'side-panel' })"
      :show-after="500"
    )
      el-button(
        circle
        @click="emitAddLayer"
        type="primary"
      )
        ui-icon(
          name="mdiLayersPlus"
        )
</template>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useUser } from '@/composables/useUser';
import { RasterModel } from '@/models/RasterModel';
import { formatRuDate } from '@/utils/formatRuDate';
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'VectorFileCard',
  methods: { formatRuDate },
  components: { UiIcon },
  props: {
    file: {
      type: Object as PropType<RasterModel>,
      required: true,
    },
  },
  emits: ['download', 'delete', 'add-layer'],
  setup(props, { emit }) {
    const { user } = useUser();
    const isStaff = computed(() => user.value.isStaff);
    const emitAddLayer = () => {
      emit('add-layer', props.file);
    };
    return {
      isStaff,
      emitAddLayer,
    };
  },
});

</script>

<style lang="scss">

</style>
