import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["SkeletonLoader", { active: _ctx.active }])
  }, [
    _createElementVNode("div", {
      class: "SkeletonLoader-message",
      innerHTML: _ctx.message
    }, null, 8 /* PROPS */, _hoisted_1),
    _createVNode(_component_el_skeleton, {
      rows: 5,
      animated: ""
    })
  ], 2 /* CLASS */))
}