import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-name"]

export function render(_ctx, _cache) {
  const _component_MDI = _resolveComponent("MDI")
  const _component_Path = _resolveComponent("Path")
  const _component_el_icon = _resolveComponent("el-icon")

  return (_openBlock(), _createElementBlock("div", {
    class: "UiIcon",
    style: _normalizeStyle({ width: _ctx.size + 'px', height: _ctx.size + 'px' }),
    "data-name": _ctx.name
  }, [
    (_ctx.type === 'mdi')
      ? (_openBlock(), _createBlock(_component_MDI, {
          key: 0,
          name: _ctx.name,
          color: _ctx.color,
          size: _ctx.size,
          "data-type": "mdi",
          "data-name": _ctx.name
        }, null, 8 /* PROPS */, ["name", "color", "size", "data-name"]))
      : (_ctx.type === 'path')
        ? (_openBlock(), _createBlock(_component_Path, {
            key: 1,
            name: _ctx.name,
            color: _ctx.color,
            size: _ctx.size,
            "data-type": "svg",
            "data-name": _ctx.name
          }, null, 8 /* PROPS */, ["name", "color", "size", "data-name"]))
        : (_openBlock(), _createBlock(_component_el_icon, {
            key: 2,
            color: _ctx.color,
            size: _ctx.size,
            "data-type": "element-icon",
            "data-name": _ctx.name
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.name)))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["color", "size", "data-name"]))
  ], 12 /* STYLE, PROPS */, _hoisted_1))
}