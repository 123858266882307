<template lang="pug">
.FieldCombiningBlock.flex-col.flex-col-justify-between
  div
    div(v-for="field in selectedFields" :key="field.name")
      .flex-row.flex-row-justify-between.flex-row-align-center
        FieldView(
          :field="field"
          )
        div {{field.name}}
  .flex-row.flex-row-justify-between
    el-button(
      @click="save()"
    ) {{$t('save')}}
    el-button(
      @click="close()"
    ) {{$t('close')}}
</template>

<script lang="ts">
import {
  defineComponent, ref, onMounted, onUnmounted, computed,
} from 'vue';
import { useMapContainers } from '@/composables/useMapContainers';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import ApiService from '@/services/api/ApiService';
import SeasonList from '@/modules/season/SeasonList';
import FieldsList from '@/modules/fields/FieldsList';
import { useStruct } from '@/composables/useStruct';

export default defineComponent({
  name: 'FieldCombiningBlock',
  components: { FieldView },
  emits: ['close'],
  setup(props, { emit }) {
    const {
      selectedFields, setSelectState, activeField,
      addSelectedField,
    } = useMapContainers();
    const { structId } = useStruct();
    const close = () => {
      emit('close');
    };
    const computedData = computed(() => {
      const geom = {
        coordinates: selectedFields.value.flatMap((field) => field.feature.geometry.coordinates),
        type: 'MultiPolygon',
      };

      const result = {
        name: activeField.value.name,
        description: activeField.value.dto.properties.descr,
        id: activeField.value.id,
        source_fields: selectedFields.value.map((i) => i.id).filter((a) => a !== activeField.value.id),
        sq_acc: activeField.value.sqAcc,
        geom,
      };

      return result;
    });
    const save = async () => {
      await ApiService.struct.postMutate({
        mutations: [computedData.value],
        season: SeasonList.activeSeason.value.id,
      });
      FieldsList.fetchFields(structId.value, true);
      close();
    };

    onMounted(() => {
      addSelectedField(activeField.value);
      setSelectState('multiple');
    });
    onUnmounted(() => {
      setSelectState('single');
    });
    return {
      selectedFields,
      save,
      close,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
