import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-width"]
const _hoisted_2 = { class: "UiDialog-content-header" }
const _hoisted_3 = { class: "UiDialog-content-header-label" }
const _hoisted_4 = { class: "UiDialog-content-body" }
const _hoisted_5 = {
  key: 0,
  class: "UiDialog-content-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["UiDialog", { [`--center`]: _ctx.center }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["UiDialog-content", { [`--fullscreen`]: _ctx.fullscreen }]),
              style: _normalizeStyle({ width: _ctx.contentWidth(), marginTop: !_ctx.center && !_ctx.fullscreen ? _ctx.top : 'inherit' }),
              "data-width": _ctx.contentWidth()
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.hasSlot('header'))
                    ? _renderSlot(_ctx.$slots, "header", { key: 0 })
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.header), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                ]),
                _createElementVNode("div", {
                  class: "UiDialog-content-header-close",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default")
              ]),
              (_ctx.hasSlot('footer'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "footer")
                  ]))
                : _createCommentVNode("v-if", true)
            ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1),
            _createElementVNode("div", {
              class: "UiDialog-wrap",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            })
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }))
}