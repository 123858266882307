import { FieldEstimationDto } from '@/services/api/dto/satellites/FieldEstimationDto';
import { SatelliteRgbFileDto } from '@/services/api/dto/satellites/SatelliteRgbFileDto';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { computed } from 'vue';

export class SatelliteEstimationModel {
  get id(): number {
    return this._id;
  }

  get scene(): number {
    return this._scene;
  }

  get field(): number {
    return this._field;
  }

  get acceptableEstimation(): number {
    return this._acceptableEstimation;
  }

  get isAcceptable(): boolean {
    return this._isAcceptable;
  }

  get estimationUser(): number {
    return this._estimationUser;
  }

  get estimationAuto(): number {
    return this._estimationAuto;
  }

  get updateDate(): string {
    return this._updateDate;
  }

  get createDate(): string {
    return this._createDate;
  }

  get isChecked(): boolean {
    if (this._estimationUser <= 0) {
      return this._estimationAuto >= this._acceptableEstimation;
    }
    return this._estimationUser > 0 || false;
  }

  private readonly _id: number;

  private readonly _scene: number;

  private readonly _field: number;

  private readonly _acceptableEstimation: number;

  private readonly _isAcceptable: boolean;

  private _estimationUser: number;

  private _estimationAuto: number;

  private readonly _updateDate: string;

  private readonly _createDate: string;

  constructor(dto: FieldEstimationDto) {
    this._id = dto.id;
    this._scene = dto.scene;
    this._field = dto.field;
    this._acceptableEstimation = dto.acceptable_estimation;
    this._isAcceptable = dto.is_acceptable;
    this._estimationUser = dto.estimation_user;
    this._estimationAuto = dto.estimation_auto;
    this._updateDate = dto.update_date;
    this._createDate = dto.create_date;
  }

  toggleEstimation = async () => {
    let estimation: -1 | 0 | 1;
    if (this._estimationUser === 0) {
      if (this._estimationAuto >= 0.8) {
        estimation = -1;
      } else {
        estimation = 1;
      }
    } else {
      estimation = this._estimationUser === -1 ? 1 : -1;
    }

    const { data } = await ApiService.satellites.patchEstimation(this._id, estimation);
    this._estimationUser = data.estimation_user;
    this._estimationAuto = data.estimation_auto;
  };
}
