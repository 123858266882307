import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({width: _ctx.width ? _ctx.width + 'px' : '100%'})
  }, [
    _createVNode(_component_Markdown, {
      source: _ctx.text,
      html: ""
    }, null, 8 /* PROPS */, ["source"])
  ], 4 /* STYLE */))
}