<template lang="pug">
SidePanelContent(
  @close="close"
  :title="title"
)
  template(
    v-if="activePoi && informationMode === 'poi'"
  )
    POIEditBlock(
      :poi="activePoi"
    )
  template(
    v-if="activeField && informationMode === 'field'"
  )
    FieldEditBlock(
      :field="activeField"
    )
</template>

<style lang="scss">

</style>

<script  lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { FieldModel } from '@/models/field/FieldModel';
import { PoiModel } from '@/models/poi/PoiModel';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import POIEditBlock from '@/modules/poi/ui/POIEditBlock/POIEditBlock.vue';
import FieldEditBlock from '@/modules/fields/ui/FieldEditBlock/FieldEditBlock.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { useMapContainers } from '@/composables/useMapContainers';

export default defineComponent({
  name: 'InfoPanel',
  components: {
    SidePanelContent,
    POIEditBlock,
    FieldEditBlock,
  },
  emit: ['close'],
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
  },

  setup(props) {
    const {
      activePoi,
      activeField,
      informationMode,
    } = useMapContainers(props.mapContainer);

    const close = () => {
      informationMode.value = 'none';
    };

    const title = computed(() => {
      if (informationMode.value === 'field') {
        return activeField.value?.name;
      }

      if (informationMode.value === 'poi') {
        return activePoi.value?.name;
      }
      return 'none';
    });

    return {
      close,
      PoiModel,
      FieldModel,
      activePoi,
      activeField,
      informationMode,
      title,
    };
  },
});

</script>
