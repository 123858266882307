<template lang="pug">
.BorderValuesForm
  .BorderValuesForm-grid
    .BorderValuesForm-grid-add
    .BorderValuesForm-grid-color Цвет
    .BorderValuesForm-grid-range-from От
    .BorderValuesForm-grid-range-to До
    .BorderValuesForm-grid-label Подпись

  .BorderValuesForm-grid(
    v-for="(row, idx) in rows"
  )
    .BorderValuesForm-grid-add
      .BorderValuesForm-grid-add-button(
        v-if="idx < rows.length - 1"
      )
        el-tooltip(
          content="Добавить новую строку"
          placement="right"
        )
          el-button(
            circle
            type="primary"
            @click="addRow(idx)"
            size="small"
          )
            ui-icon(
              name="Plus"
              :size="18"
            )
    .BorderValuesForm-grid-color
      color-picker(
        :format="'hex4'"
        disable-alpha
        v-model:pureColor="row.color"
      )
    .BorderValuesForm-grid-range-from
      el-input(
        disabled
        v-if="idx > 0"
        :model-value="row.range.from"
        style="width: 120px"
      )
      el-input(
        disabled
        v-if="idx === 0"
        model-value="-∞"
        style="width: 120px"
      )
    .BorderValuesForm-grid-range-to
      el-input(
        v-model="row.range.to"
        type="number"
        v-if="idx < rows.length - 1"
        @input="handlerValueChange(idx)"
        :min="rows[idx-1]?.range.to || -Infinity"
        :max="rows[idx+1]?.range.to || Infinity"
        style="width: 120px"
        input-style="{ borderColor: #F00 }"
      )

      el-input(
        v-if="idx === rows.length - 1"
        model-value="∞"
        disabled
        style="width: 120px"
      )
    .BorderValuesForm-grid-label
      el-input(
        v-model="row.label"
        autosize
        style="width: 100%"
      )
    .BorderValuesForm-grid-delete
      el-popconfirm(
        width="250"
        confirm-button-text="Удалить"
        confirm-button-type="danger"
        title="Удалить строку палитры?"
        @confirm="deleteRow(idx)"
      )
        template(#reference)
          el-button(
            circle
            type="danger"
            size="small"
          )
            ui-icon(name="Delete" size="16")

  .mt-10.fw.flex.flex-row.flex-row-justify-between
    div
      el-button(
        @click="$emit('cancel')"
      ) {{ $t('cancel') }}

      el-popconfirm(
        title="Удалить текущий стандарт?"
        :confirm-button-text="$t('delete')"
        confirm-button-type="danger"
        width="200"
        @click="$emit('delete')"
      )
        template(#reference)
          el-button(
            type="danger"
          ) {{ $t('delete') }}

    el-button(
      :disabled="saveDisabled"
      @click="$emit('save', rows)"
      type="primary"
    ) {{ $t('save') }}
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { ColorPicker } from 'vue3-colorpicker';
import { BorderValuesType } from '@/constants/types/palette/UnifiedVectorPaletteType';
import hexToRgba from '@/lib/convertors/hexToRgba';
import { rgbaToHex } from '@/lib/convertors/rgbToHex';

export default defineComponent({
  name: 'BorderValuesForm',
  components: { ColorPicker, UiDialog },
  props: {
    data: {
      type: Object as PropType<BorderValuesType[]>,
      required: true,
    },
    saveDisabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['save', 'delete', 'cancel'],
  setup(props) {
    const rows = ref<BorderValuesType[]>([]);

    props.data.forEach((v) => {
      rows.value.push(JSON.parse(JSON.stringify(v)));
    });
    rows.value[0].range.from = -Infinity;
    rows.value[rows.value.length - 1].range.to = Infinity;

    const addRow = (idx: number) => {
      const beforeRow = rows.value[idx];
      const afterRow = rows.value[idx + 1];

      const color = () => {
        const a1 = hexToRgba(beforeRow.color);
        const a2 = hexToRgba(afterRow.color);
        return rgbaToHex([Math.floor((a1[0] + a2[0]) / 2), Math.floor((a1[1] + a2[1]) / 2), Math.floor((a1[2] + a2[2]) / 2)]);
      };

      rows.value.splice(idx + 1, 0, {
        range: {
          from: beforeRow.range.to,
          to: beforeRow.range.to,
        },
        color: color(),
        label: `${beforeRow.range.to} - ${beforeRow.range.to}`,
      } as BorderValuesType);
    };

    const recalculateLabels = () => {
      rows.value.forEach((row) => {
        if (row.label.match(/^\d+\s*-\s*\d+|<\s*\d+|>\s*\d+$/g)) {
          if (row.range.to === Infinity) {
            row.label = `> ${row.range.from}`;
          } else if (row.range.from === -Infinity) {
            row.label = `< ${row.range.to}`;
          } else {
            row.label = `${row.range.from} - ${row.range.to}`;
          }
        }
      });
    };

    const handlerValueChange = (idx: number) => {
      const beforeRow = rows.value[idx - 1] || undefined;
      const currentRow = rows.value[idx];
      const nextRow = rows.value[idx + 1] || undefined;
      if (idx > 0) {
        // Лимитируем нижнюю границу значения
        if (Number(currentRow.range.to) < beforeRow.range.to) {
          currentRow.range.to = Number(beforeRow.range.to);
        }
      }
      if (idx < rows.value.length - 1) {
        if (beforeRow && Number(currentRow) > beforeRow.range.from) {
          currentRow.range.to = Number(nextRow.range.from);
        }
        if (nextRow) {
          nextRow.range.from = Number(currentRow.range.to);
        }
      }
      recalculateLabels();
    };

    const deleteRow = (idx: number) => {
      if (idx > 0) {
        rows.value[idx - 1].range.to = rows.value[idx].range.to;
        rows.value.splice(idx, 1);
      } else {
        rows.value[1].range.from = -Infinity;
        rows.value.splice(0, 1);
      }
      recalculateLabels();
    };

    return {
      rows,
      addRow,
      deleteRow,
      handlerValueChange,
    };
  },
});
</script>

<style lang="scss">

.BorderValuesForm {
  &-grid {
    padding: 8px 0;
    display: grid;
    grid-template-columns: 30px 50px 120px 120px 1fr 30px;
    grid-column-gap: 15px;
    align-items: center;
    &:nth-of-type(odd) {
      background: #F6F6F6;
    }
    &-add {
      position: relative;
      &-button {
        position: absolute;
        bottom: -36px;
      }
    }
    &-color {}
    &-range-from {}
    &-range-to {}
    &-label {}
    &-delete {}
  }
}
</style>
