<template lang="pug">
.SidePanel(
  :class="{ [`--reverse`]: isCompare }"
)
  .SidePanel-navigation
    template(
      v-for="panel in filteredPanels"
    )
      el-tooltip(
        :content="$t(panel.tooltip, { ns: 'side-panel' })"
        placement="left"
        :show-after="200"
      )
        .SidePanel-navigation-button(
          @click="togglePanel(panel.name)"
          :class="{ active: isPanelOpen && activePanel === panel.name }"
        ).bg-button-white
          ui-icon(
            :name="panel.icon"
            color="currentColor"
            :size="32"
          )
  .SidePanel-content(
    :class="{ active: isPanelOpen }"
    :style="{ width: `${(isPanelOpen ? width : -10 )}px`}"
  )
    .SidePanel-content-panel(
      :style="{ width: `${width}px`,  }"
      v-for="panel in filteredPanels"
      :class="{ active: activePanel === panel.name }"
    )
      component(
        v-if="!isCompare || panel.compareAble"
        :is="panel.component"
        :map-container="mapContainer"
        :is-compare="isCompare"
        :options="panel.options"
        :active="activePanel === panel.name"
        @close="isPanelOpen = false"
      )

  //.SidePanel-shuffle(
  //  @mousedown="panelResizeStart"
  //  v-show="isPanelOpen"
  //)
  //  .SidePanel-shuffle-icon
</template>

<script lang="ts">
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { TaskTypeEnum } from '@/constants/enums/TaskTypeEnum';
import {
  computed, defineComponent, PropType, ref, watch,
} from 'vue';
import PermissionsList from '@/modules/permissions/PermissionsList';
import { SidePanelNameEnum } from '@/constants/enums/SidePanelNameEnum';
import SidePanelItems from '@/assets/data/SidePanelItems';

export default defineComponent({
  name: 'SidePanel',
  components: { UiIcon },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const activePanel = ref<SidePanelNameEnum>(SidePanelNameEnum.FIELDS);
    const width = ref(300);
    const isPanelOpen = ref(true);

    const filteredPanels = computed(() => SidePanelItems.filter((v) => {
      if (v.permission) {
        return PermissionsList.hasPermission(v.permission.target, v.permission.action);
      }
      return !(props.isCompare && !v.compareAble);
    }));

    // region Side panel shuffle
    const panelResize = {
      startX: 0,
      startWidth: width.value,
    };

    const isPanelResizeActive = ref(false);
    const mouseMoveListener = (evt: MouseEvent) => {
      if (isPanelResizeActive.value) {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        const moved = (evt.clientX - panelResize.startX) * (props.isCompare ? -1 : 1);
        width.value = Math.max(Math.min(moved + panelResize.startWidth, 600), 280);
      }
    };

    const mouseUpListener = () => {
      isPanelResizeActive.value = false;
      window.removeEventListener('mousemove', mouseMoveListener);
      window.removeEventListener('mouseup', mouseUpListener);
    };

    const panelResizeStart = (evt: MouseEvent) => {
      panelResize.startX = evt.clientX;
      panelResize.startWidth = width.value;
      isPanelResizeActive.value = true;
      window.addEventListener('mousemove', mouseMoveListener);
      window.addEventListener('mouseup', mouseUpListener);
    };
    // endregion

    const togglePanel = (name: SidePanelNameEnum) => {
      if (activePanel.value === name) {
        isPanelOpen.value = !isPanelOpen.value;
      } else {
        activePanel.value = name;
        isPanelOpen.value = true;
      }
    };

    watch(isPanelResizeActive, (a) => {
      console.log(a);
      if (a) {
        document.body.style.userSelect = 'none';
      } else {
        document.body.style.userSelect = '';
      }
    });
    return {
      activePanel,
      isPanelOpen,
      TaskTypeEnum,
      width,
      panelResizeStart,
      filteredPanels,
      togglePanel,
    };
  },
});
</script>

<style lang="scss" scoped>
.SidePanel {
  position: relative;
  width: 42px;

  @media screen and (max-width: $bp-m) {
    height: calc(100vh - 50px);
  }
  $this: &;
  display: flex;
  flex-direction: row;
  z-index: 3;
  &.--reverse {
    flex-direction: row-reverse;
    #{$this}-content {
      left: auto;
      right: 52px;
    }
    #{$this}-shuffle {
      right: auto;
      left: 4px;
    }
  }
  &-shuffle {
    height: 320px;
    width: 4px;
    z-index: 10;
    top: calc(50% - 150px);
    right: 4px;
    background: var(--color-white-600);
    position: relative;
    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    cursor: ew-resize;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
    &:hover {
      background: var(--color-white-500);
    }
    &-icon {
      width: 2px;
      background: var(--color-white-700);
      height: 10vh;
    }
  }
  &-content {
    position: absolute;
    left: 52px;
    top: 0;
    z-index: 1;
    background: rgba(255, 255, 255, .8);
    width: 100%;
    height: calc(100vh - 190px);
    border-radius: 10px;
    padding: 0;
    backdrop-filter: blur(23px);
    background-blend-mode: overlay;
    visibility: hidden;
    opacity: 0;
    transition: var(--transition-default);
    box-shadow: 0 0 15px rgba(0, 0, 0, .25);
    &.active {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
    &-panel {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.25s ease;
      height: 100%;
      &.active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &-navigation {
    width: 42px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
    &-button {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-black-300);
      cursor: pointer;
      transition: var(--transition-default);
      pointer-events: all;
      &.active {
        background: var(--color-white-500);
        color: var(--color-primary-500);
      }
      &:hover:not(.disabled):not(.active) {
        color: var(--color-black-400);
        background: rgba(255, 255, 255, 0.5);
      }
      &.disabled {
        cursor: default;
        color: var(--color-black-100);
        background: var(--color-white-600);
      }
    }
  }
}
</style>
