<script  lang="ts">
import {
  defineComponent, onMounted, PropType, ref, useSlots, watch,
} from 'vue';
import { MapModel } from '@/models/map/MapModel';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import IndexesInfo from '@/components/shared/IndexesInfo/IndexesInfo.vue';
import { useMonitoring } from '@/composables/useMonitoring';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import LoggerService from '@/services/logger/LoggerService';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { ElNotification } from 'element-plus';
import MyTrackerService from '@/services/myTracker/MyTrackerService';
import EventBus from '@/services/eventBus/EventBus';
import LoadingEvents from '@/services/loading/LoadingEvents';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { formatRuDate } from '@/utils/formatRuDate';
import FieldsList from '@/modules/fields/FieldsList';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';

export default defineComponent({
  name: 'MonitoringCompactBlock',
  props: {
    map: {
      type: Object as PropType<MapModel>,
      required: true,
    },
    filterList: {
      type: Object as PropType<Array<FileIndexKindEnums>>,
      required: true,
    },
  },
  emits: ['clear', 'index', 'nir'],
  components: {
    NotFound,
    IndexesInfo,
  },
  setup(props, { emit }) {
    const {
      indexesKinds,
    } = useMonitoring();
    const { activeField } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const selectedIndex = ref<MonitoringIndexKindType | undefined>();
    const selectedFile = ref<FieldNirModel | undefined>();
    const isShowContrast = ref(false);
    const isShowPicture = ref(false);
    const showSelectFile = ref(false);
    const slots = useSlots();
    const emitIndex = () => {
      if (selectedFile.value && selectedIndex.value) {
        emit('index', selectedIndex.value);
      } else if (isShowPicture.value) {
        emit('index', 'monitoring');
      } else {
        emit('index', undefined);
      }
    };

    const setSelectIndex = (index: MonitoringIndexKindType | undefined) => {
      selectedIndex.value = index;
    };

    const showIndex = () => {
      props.map.removeLayer(MapLayerTypeEnum.MONITORING_INDEX);
      LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).log('Try to show monitoring index');
      if (selectedFile.value && selectedIndex.value) {
        LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).log('NIR file is selected and index kind is selected.');
        const indexFile = activeField.value?.monitoringIndexes.find((index) => index.name === selectedIndex.value?.name && index.sceneId === selectedFile?.value?.scene.id);
        if (indexFile) {
          LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).group('Index file for selected nir and index kind is found.', indexFile);
          if (!props.map.getLayer(indexFile.uuid)) {
            LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).log('Send index file to map for render');
            props.map.render(indexFile);
            emitIndex();
          } else {
            LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).log('Map says is this index file already rendered.');
          }
        } else {
          LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).error('Index file for selected nir and index kind is not found.');
        }
      }
    };

    watch(selectedFile, () => {
      showIndex();
    });
    watch(selectedIndex, () => {
      showIndex();
    });
    watch(isShowContrast, () => showIndex());

    const showPicture = () => {
      LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).log('Toggle NIR file render on map.');
      if (selectedFile.value && isShowPicture.value) {
        LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).group('Send NIR file to map for render', selectedFile.value);
        props.map?.render(selectedFile.value);
        emitIndex();
      } else {
        LoggerService.from(LoggerGroupsEnum.COMPARE_MAP).group('Remove NIR file from map', selectedFile.value);
        props.map?.removeLayer(MapLayerTypeEnum.NIR_FILE);
      }
      emit('nir', selectedFile.value);
    };
    watch(selectedFile, (a, b) => {
      if (a !== b) {
        showPicture();
      }
    });
    watch(isShowPicture, (a, b) => {
      if (a !== b) {
        showPicture();
      }
    });

    const indexMenuItemClicked = async (kind: MonitoringIndexKindType) => {
      if (selectedIndex.value?.name === kind.name) {
        setSelectIndex(undefined);
      } else if (!selectedFile.value) {
        setSelectIndex(undefined);
        ElNotification({
          title: 'Файл индекса не найден',
          message: 'Данный индекс в процессе формирования.',
          type: 'warning',
          duration: 2000,
          position: 'bottom-right',
        });
      } else {
        setSelectIndex(kind);
        MyTrackerService.send('Select index (Indexes selector)', { name: selectedIndex.value?.name || '', label: selectedIndex.value?.label || '' });
      }
      emitIndex();
    };

    EventBus.$on('compare-navigation-active-menu-change', (uuid: string) => {
      if (props.map.uuid === uuid) {
        setTimeout(() => {
          setSelectIndex(undefined);
          isShowPicture.value = false;
          isShowContrast.value = false;
        });
      }
    });

    watch(activeField, (a, b) => {
      if (b !== undefined) {
        setSelectIndex(undefined);
        selectedFile.value = activeField.value?.nirFiles[0];
        isShowContrast.value = false;
        isShowPicture.value = false;
        showSelectFile.value = false;
        activeField.value?.fetchMonitoringIndexes();
        activeField.value?.fetchNirFiles();
        props.map?.removeLayer([MapLayerTypeEnum.NIR_FILE, MapLayerTypeEnum.MONITORING_INDEX]);
        emitIndex();
      }
    });

    onMounted(() => {
      activeField.value?.fetchNirFiles();
      activeField.value?.fetchMonitoringIndexes();
      LoadingEvents.onLoadingEnds((name) => {
        if (name === LoadingNamesEnum.FIELD_NIR_FILES) {
          if (activeField.value && activeField.value.nirFiles.length) {
            selectedFile.value = activeField.value?.nirFiles[0];
          } else {
            selectedFile.value = undefined;
          }
        }
      });
    });

    return {
      activeField,
      indexMenuItemClicked,
      showIndex,
      selectedFile,
      selectedIndex,
      indexesKinds,
      isShowContrast,
      isShowPicture,
      showSelectFile,
      formatRuDate,
      FieldsList,
      setSelectIndex,
      slots,
    };
  },
});
</script>

<template lang="pug">
.MapMonitoringMenu
  template(v-if="selectedFile && activeField")
    .menu-header {{ $t('monitoring-menu-header', { ns: 'monitoring' }) }}
    .menu-block
      .menu-sub-header {{ $t('monitoring-nir-file', { ns: 'monitoring' }) }}
      .MapMonitoringMenu-nir
        .MapMonitoringMenu-nir-image
          el-image(
            :src="selectedFile?.thumbnailUrl"
            :preview-src-list="[selectedFile?.url]"
            :preview-teleported="true"
          )
        .MapMonitoringMenu-nir-props
          div
            .mb-1.fs-12 {{ $t('monitoring-select-nir-file-title', { ns: 'monitoring' }) }}
            el-button(
              @click="showSelectFile = !showSelectFile"
              :class="{ active: showSelectFile }"
            )
              ui-icon(
                name="mdiCalendarMonthOutline"
                color="inherit"
              )
              .pl-5 {{ formatRuDate(selectedFile?.scene.datetime.toString()) || '' }}
          el-checkbox(
            v-model="isShowPicture"
          )
            .mb-3 {{ $t('monitoring-show-image-checkbox', { ns: 'monitoring' }) }}

    .MapMonitoringMenu-indexes

      .MapMonitoringMenu-indexes-group(
        v-for="group in indexesKinds?.groups || []"
        :data-group="group"
      )
        .menu-block
          .menu-sub-header {{ group.label }}

          .MapMonitoringMenu-indexes-items
            el-tooltip(
              v-for="kind in (indexesKinds?.kinds || []).filter((k) => k.groupId === group.id)"
              placement="top"
              :show-after="500"
            )
              template(#content)
                IndexesInfo(
                  :index="kind.name"
                )
              .MapMonitoringMenu-indexes-item(
                :class="{ active: selectedIndex && selectedIndex.name === kind.name }"
                @click="indexMenuItemClicked(kind)"
              ) {{ kind.label }}

      .menu-block
        el-checkbox(
          v-model="isShowContrast"
        )
          .mb-3 {{ $t('monitoring-show-contrast-checkbox', { ns: 'monitoring' }) }}
  template(v-else)
    NotFound(
      :message="$t('monitoring-not-found-message', { ns: 'monitoring' })"
    )
teleport(
  to="body"
)
  .MapMonitoringMenu-indexes-selectFile(
    :class="{ active: showSelectFile}"
  )
    .MapMonitoringMenu-indexes-selectFile-back(
      @click="showSelectFile = false"
    )
    .MapMonitoringMenu-indexes-selectFile-wrapper(
      :class="{ active: showSelectFile}"
    )
      .MapMonitoringMenu-indexes-files
        .MapMonitoringMenu-indexes-file(
          v-for="file in activeField?.nirFiles"
          :class="{ active: selectedFile?.id === file.id }"
          @click="selectedFile = file; showSelectFile = false;"
        )
          img(
            :src="file.thumbnailUrl"
          )
          .MapMonitoringMenu-indexes-file-caption {{ formatRuDate(file.scene.datetime.toString()) }}

</template>

<style  lang="scss">
.MapMonitoringMenu {
  &-nir {
    display: grid;
    grid-template-columns: 90px 1fr;
    column-gap: 15px;
    &-image {
      img {
        border-radius: 10px;
        width: 90px;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, .35);
      }
    }
    &-props {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &-indexes {
    &-group {
      margin-bottom: 0.5rem;
      &-title {
        font-size: 0.9rem;
        font-weight: 500;
        color: var(--color-foreground-gray);
        margin-bottom: 0.25rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      min-width: 180px;
      grid-column-gap: 5px;
      grid-row-gap: 4px;
    }
    &-item {
      padding: 4px 7px;
      min-width: 60px;
      white-space: nowrap;
      cursor: pointer;
      transition: var(--transition-default);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      color: var(--color-black-300);
      &.active {
        background-color: var(--color-dark-accent);
        color: var(--color-dark-foreground);
      }
      &.disabled {
        background-color: rgba(255, 255, 255, 0.15);
        cursor: default;
      }
      &:hover:not(.active):not(.disabled) {
        background-color: rgba(0, 0, 0, 0.1);
        color: var(--color-black-500);
      }
    }

    &-selectFile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 10;
      @include fade();
      &-wrapper {
        margin: 0 auto;
        position: absolute;
        bottom: -96vh;
        left: 0;
        right: 0;
        max-width: 100vw;
        width: 900px;
        max-height: 96vh;
        height: auto;
        border-radius: 20px 20px 0 0;
        background: #FFF;
        transition: var(--transition-default);
        visibility: hidden;
        opacity: 0;
        &.active {
          bottom: 0;
          visibility: visible;
          opacity: 1;
        }
      }
      &-back {
        position: absolute;
        inset: 0 0 0 0;
      }
    }

    &-files {
      position: relative;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 10px;
      margin: 20px 0 0 20px;
      max-height: calc(96vh - 20px);
      padding-right: 20px;
      padding-bottom: 20px;
    }
    &-file {
      flex-grow: 1;
      border: 4px solid transparent;
      border-radius: 10px;
      cursor: pointer;
      max-width: 200px;
      min-width: 150px;
      transition: var(--transition-default);
      background: var(--color-white-600);
      text-align: center;
      &-caption {
        text-align: center;
        line-height: 1.5rem;
      }
      img {
        object-fit: fill;
        width: 134px;
        height: 134px;
        margin: 12px 8px 8px 8px;
        vertical-align: middle;
        border-radius: 5px;
        transition: var(--transition-default);
      }
      &:hover {
        border-color: var(--color-info-500);
        img {
          width: 150px;
          height: 150px;
          margin: 4px 0 0 0;
        }
      }
      &.active {
        border-color: var(--color-primary-500);
        img {
          width: 150px;
          height: 150px;
          margin: 4px 0 0 0;
        }
      }
    }
    &-button {
      border: 1px solid rgba(0, 0, 0, 0.15);
      height: 2rem;
      border-radius: 1rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-black-300);
      cursor: pointer;
      &:hover {
        color: var(--color-black-500);
        background-color: rgba(0, 0, 0, 0.1);
      }
      &.active {
        background-color: var(--color-dark-accent);
        color: var(--color-dark-foreground);
      }
    }
  }
}
</style>
